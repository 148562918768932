import styled from "styled-components";
import Card from "../../../common/Card";
import { DocumentCategory } from "neword-core";

interface Props {
  title: string;
  icon: JSX.Element;
  selected?: boolean;
  onClick: () => void;
  vertical?: boolean;
}

const CardStyled = styled(Card)<Props>`
width: ${({ vertical }) => (vertical ? "100%" : "13%")};
  cursor: pointer;
  padding: 15px 20px;
  display: flex;
  border-radius: 8px;
  border: ${(props) =>
    props.selected
      ? "1px solid white"
      : "1px solid white"};
  align-items: center;


  background: ${(props) =>
    props.selected
      ? "var(--purple-opacity)"
      : "var(--light-bg)"};

      color: ${(props) => (props.selected ? "var(--primary-purple)" : "black")};
  &:hover {
    border: 1px solid var(--title-color, #e6e6e6);
  }
`;

const IconWrapper = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 13px;
  height: 25px;
  width: 25px;
`;

const TextWrapper = styled.div`
  padding-right: 1.5em;
  display: flex;
  flex-direction: column;
`;

const BoxTitle = styled.span`
  font-size: 14px;
  width: 100%;
  letter-spacing: -0px;
  font-weight:600; 
`;

const CardFilterItem: React.FC<Props> = (props) => {
  return (
    <CardStyled {...props}>
      <IconWrapper {...props}>{props.icon}</IconWrapper>

      <TextWrapper>
        <BoxTitle>{props.title}</BoxTitle>
      </TextWrapper>
    </CardStyled>
  );
};

export default CardFilterItem;
