import { AttachmentMedia } from "neword-core";

export default (value: AttachmentMedia[]) => {
  let errorText = "";

  // Separate videos and images
  const videos = value.filter((item) => item.metadata.type === "VIDEO");
  const images = value.filter((item) => item.metadata.type === "IMAGE");

  // Validate combined upload
  if (videos.length > 1) {
    errorText = "מותר לבחור וידאו אחד בלבד";
  }
  if (images.length > 5) {
    errorText = "מותר לבחור עד חמש תמונות בלבד";
  }
  if (videos.length > 0 && images.length > 0) {
    errorText = "לא ניתן לבחור גם תמונה וגם וידאו";
  }

  // Validate Videos
  const videoErrors = videos
    .map((video) => {
      const { aspectRatio, resolution, frameRate, duration, size } =
        video.metadata as {
          type: "VIDEO";
          aspectRatio: string;
          resolution: string;
          frameRate: number;
          duration: number;
          size: number;
        };

      // Aspect Ratio: 9:16 to 16:9
      // const [widthRatio, heightRatio] = aspectRatio.split(":").map(Number);
      // const ratio = widthRatio / heightRatio;
      // if (ratio < 9 / 16 || ratio > 16 / 9) {
      //   return "יחס רוחב-גובה של הווידאו חייב להיות בין 9:16 ל-16:9";
      // }

      // // Resolution: Minimum 540x960 pixels
      // const [width, height] = resolution
      //   .split("x")
      //   .map((dim) => parseInt(dim.trim(), 10));
      // if (isNaN(width) || isNaN(height) || width < 540 || height < 960) {
      //   return "הרזולוציה של הווידאו חייבת להיות לפחות 540x960 פיקסלים";
      // }

      // // Frame Rate: Up to 30 FPS
      // if (frameRate > 30) {
      //   return "קצב הפריימים של הווידאו לא יכול לעלות על 30 FPS";
      // }

      // // Duration: 5 seconds to 3 minutes
      // if (duration < 5 || duration > 180) {
      //   return "אורך הווידאו חייב להיות בין 5 שניות ל-3 דקות";
      // }

      // // File Size: Maximum 2GB
      // if (size > 2 * 1024 * 1024 * 1024) {
      //   return "גודל הקובץ של הווידאו לא יכול לעלות על 2GB";
      // }

      return null; // No error
    })
    .filter((error) => error !== null);

  // Validate Images
  const imageErrors = images
    .map((image) => {
      const { aspectRatio, resolution, size } = image.metadata;

      // Aspect Ratio: 9:16 (portrait), 1:1 (square), or 16:9 (landscape)
      // const [widthRatio, heightRatio] = aspectRatio.split(":").map(Number);
      // const ratio = widthRatio / heightRatio;
      // if (!(ratio === 9 / 16 || ratio === 1 / 1 || ratio === 16 / 9)) {
      //   return "יחס רוחב-גובה של התמונה חייב להיות 9:16 (פורטרט), 1:1 (ריבוע), או 16:9 (נוף)";
      // }

      // // Resolution: Exactly 1080x1920 pixels
      // // const [width, height] = resolution
      // //   .split("x")
      // //   .map((dim) => parseInt(dim.trim(), 10));
      // // if (isNaN(width) || isNaN(height) || width !== 1080 || height !== 1920) {
      // //   return "הרזולוציה של התמונה חייבת להיות בדיוק 1080x1920 פיקסלים";
      // // }
      // if (!image.url.match(/\.(jpg|jpeg|png)$/i)) {
      //   return "פורמט התמונה חייב להיות JPG, JPEG או PNG";
      // }
      // // File Size: Maximum 10MB
      // if (size > 10 * 1024 * 1024) {
      //   return "גודל הקובץ של התמונה לא יכול לעלות על 10MB";
      // }

      return null; // No error
    })
    .filter((error) => error !== null);

  // Combine all errors
  const allErrors = [...videoErrors, ...imageErrors];
  if (allErrors.length > 0) {
    errorText = allErrors.join(". ");
  }

  return errorText;
};
