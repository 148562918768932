import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../../../assets/Icons/InstagramIcon.svg";
import Card from "../../common/Card";
import { FieldValues, Path, useFormContext, useWatch } from "react-hook-form";
import { useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { websiteState } from "../../../state/websitesState";
import InstagramPreview from "../../../assets/images/instagramPreview.png";
import { Galleria } from "primereact/galleria";
import { AttachmentMedia } from "neword-core";
import { ReactComponent as Edit } from "../../../assets/Icons/EditImage.svg";
import { ReactComponent as Remove } from "../../../assets/Icons/Times.svg";
import MediaEditor from "../MediaEditor";
import { Interface } from "readline";



const Wrapper = styled(Card)`
  position: relative;
  padding: 10px;
  width: 100%;
`;

const IconsWrapper = styled.div`
  flex-direction: row-reverse;
  margin-top: 10px;
  opacity: 0.5;
  svg {
    width: 55px;
  }
`;
const ToolWrapper = styled.div`
  gap: 10px;
  cursor: pointer;
  span {
    font-size: 12px;
    color: white;
  }
  display: flex;
  height: 40px;
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  background-color: black;
  border-radius: 6px;
  transition: background-color 0.2s;
  z-index: 99999;
  svg {
    fill: white;
    width: 20px;
    height: 20px;
  }

  path {
    fill: white;
  }

  &:hover {
    background-color: #2c2c2c;
  }
`;

const Tools = styled.div`
  gap: 10px;
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AcountText = styled.div`
  font-weight: 700;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  font-family: "Open Sans", "open sans Hebrew";
  letter-spacing: -0.2px;
`;

const ContentWrapper = styled.div`
  font-size: 12.21px;
  display: flex;
`;

const InstagramPreviewWrapper = styled.img`
  border-radius: 6px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover; /* Ensures the image fills the container */
`;

const SyledGalleria = styled(Galleria)`
  position: relative;
  margin-bottom: 10px;

  .p-galleria-item {
    background-color: black;
    border-radius: 8px;
  }

  .p-galleria-item-next {
    right: auto !important;
    left: 0 !important;
    transform: rotate(180deg);
    z-index: 9999999;
  }

  .p-galleria-item-prev {
    left: auto !important;
    right: 0 !important;
    transform: rotate(180deg);
    z-index: 9999999;
  }

  .p-galleria-indicator button {
    width: 0.5rem !important;
    height: 0.5rem !important;
  }

  .p-galleria-indicators {
    padding: 0 !important;
  }

  .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background-color: var(--primary-purple);
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    aspect-ratio: 1 / 1;
    border-radius: 8px;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 12px;
  bottom: 19px;
  z-index: 99;
  border-radius: 4px;
  margin-right: auto;
  background: #ffffffac;
  width: 25px;
  height: 15px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  svg {
    width: 15px;
    height: 15px;
  }
`;

const PostImage = styled.img`
  aspect-ratio: 1 / 1;
  border-radius: 8px;
  object-fit: cover;
`;

const ImageWrapper = styled.div`
  position: relative;
`;

function IgPreview<FormDataType extends FieldValues>({
  name,
  reel = false, // Default is false
}: {
  name: Path<FormDataType>;
  reel?: boolean;
}) {
  const methods = useFormContext();
  const { websiteId } = useParams();
  const website = useRecoilValue(websiteState(websiteId as string));
  const values = useWatch({ control: methods.control, name: name });
  const [showMediaEditorModal, setMediaEditorModal] = useState(false);

  const imageUrl = useMemo(() => {
    return values?.imageUrls || InstagramPreview; // Default to empty string if no image is available
  }, [values]);

  return (
    <Wrapper className="col-4">
      <TopSection></TopSection>
      <IconWrapper>
        <Logo />
      </IconWrapper>
      <ImageWrapper>
        <SyledGalleria
          value={
            imageUrl.length > 0
              ? imageUrl
              : [{ url: InstagramPreview, metadata: { type: "IMAGE" } }]
          }
          showThumbnails={false}
          showIndicators
          showItemNavigators
          item={(item: AttachmentMedia) => {
            if (!item) return;

            return (
              <>
                <Tools>
                  <ToolWrapper
                    onClick={() =>
                      methods.setValue(
                        name + ".imageUrls",
                        values.imageUrls.filter(
                          (m: AttachmentMedia) => m !== item
                        )
                      )
                    }
                  >
                    <Remove />
                    <span>הסר</span>
                  </ToolWrapper>
                  <ToolWrapper onClick={() => setMediaEditorModal(true)}>
                    <Edit />
                    <span>עריכת תמונה/וידאו</span>
                  </ToolWrapper>
                </Tools>

                {item.metadata.type === "VIDEO" ? (
                  <video src={item.url} controls style={{ aspectRatio: reel ? "9/16" : "1/1" }} />
                ) : (
                  <PostImage src={item.url} />
                )}
                {showMediaEditorModal && (
                  <MediaEditor
                    url={item.url}
                    onProcess={(attachment: AttachmentMedia) =>
                      methods.setValue(
                        name + ".imageUrls",
                        values.imageUrls.map((m: AttachmentMedia) =>
                          m === item ? attachment : m
                        )
                      )
                    }
                    onClose={() => setMediaEditorModal(false)}
                  />
                )}
              </>
            );
          }}
        />
      </ImageWrapper>
      <AcountText className="mt-3 flex row-revers">{website?.name}</AcountText>
      <ContentWrapper className="mt-1 pb-4">
        {values && values.message}
      </ContentWrapper>
    </Wrapper>
  );
}

export default IgPreview;
