import React from "react";
import SetupSelect from "../../../common/form/SetupSelect";
import styled from "styled-components";
import FormStyle from "../../../common/form/FormStyle";
import { CreateWebsiteRequestData } from "../../../../core/services/requests/createWebsite/createWebsiteRequestData";

const FormStyleStyled = styled(FormStyle)`
  margin-top: 60px;
  width: 600px;
`;

const ChooseSetup: React.FC = () => {
  return (
    <FormStyleStyled>
      <SetupSelect<CreateWebsiteRequestData> fieldName="companyType" />
    </FormStyleStyled>
  );
};

export default ChooseSetup;
