import React, { useCallback, useEffect, useMemo, useState } from "react";
import WelcomeBackCard from "../../components/website/overview/WelcomeBackCard";
import IconDataBox from "../../components/website/overview/IconDataBox";
import { ReactComponent as PopularIcon } from "../../assets/Icons/overviewFilterIcons/popular_icon.svg";
import { ReactComponent as CampaignIcon } from "../../assets/Icons/overviewFilterIcons/Campaign.svg";
import { ReactComponent as BlogIcon } from "../../assets/Icons/overviewFilterIcons/blog_icon.svg";
import { ReactComponent as AdsIcon } from "../../assets/Icons/overviewFilterIcons/ads_icon.svg";
import { ReactComponent as WebsiteIcon } from "../../assets/Icons/overviewFilterIcons/website_icon.svg";
import { ReactComponent as MarketingIcon } from "../../assets/Icons/overviewFilterIcons/marketing_icon.svg";
import { ReactComponent as SocialIcon } from "../../assets/Icons/overviewFilterIcons/social_icon.svg";
import { useParams } from "react-router";
import { useRecoilState, useRecoilValue } from "recoil";
import { websiteState } from "../../state/websitesState";
import LastGeneratedArticles from "../../components/website/overview/LastGeneratedArticles";
import WebsiteTabsNav from "../../components/website/WebsiteTabsNav";
import ConnectCmsModal from "../../components/modals/ConnectCmsModal";
import { useSearchParams } from "react-router-dom";
import { publishIntegrationState } from "../../state/publishIntegrationState";
import Card from "../../components/common/Card";
import styled from "styled-components";
import { featuresState } from "../../state/featuresState";
import CardFilterItem from "../../components/website/overview/cardsFilter/CardFilterItem";
import ContentCard from "../../components/website/overview/contentCards/ContentCard";
import InstagramCaptionImg from "../../assets/images/contentCardsImages/instagram_caption_card_img.png";
import {
  ContentCardType,
  platformTypeText,
} from "../../components/website/overview/contentCards/cardTypes";
import {
  DocumentCategory,
  DocumentEntityType,
  DocumentTypeToCategory,
} from "neword-core";
import DocBoxes from "../../components/document/components/DocBoxes";
import documentService from "../../core/services/document.service";
import useNavigator from "../../hooks/useNavigator";
import documentsDictionary from "../../components/document/documentsDictionary";
import UpgradePackage from "../../components/modals/UpgradePackage";

const CardStyle = styled(Card)`
  cursor: pointer;

  background: white;
  display: flex;
  align-items: center;
  gap: 20px;
  color: var(--primary-purple);
  font-weight: bold;
  box-shadow: -10px 10px 20px -5px rgba(0, 0, 0, 0.1);

  svg {
    fill: var(--primary-purple);
    width: 25px;
    height: 25px;
  }
`;

const Title = styled.div`
  color: var(--Main-TitleColor, #0a2540);
  leading-trim: both;
  text-edge: cap;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 1.5rem */
  letter-spacing: -0.045rem;
  margin: 10px 0;
`;

const TypeTitle = styled.div`
  color: var(--Main-TitleColor, #0a2540);
  leading-trim: both;
  text-edge: cap;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 1.5rem */
  letter-spacing: -0.045rem;
  margin: 10px 0;
`;

const DownContainer = styled.div`
gap: 10px;
  background: white;
  border-radius: 8px;
  padding: 10px;
  margin-top: 10px !important;
`;

const SingleWebsite: React.FC = () => {
  const { websiteId } = useParams();
  const navigate = useNavigator();
  const [selectedCategory, setSelectedCategory] = useState<
    DocumentCategory | "all" | "myCustomDocs"
  >("all");
  const website = useRecoilValue(websiteState(websiteId as string));
  const publishIntegration = useRecoilValue(
    publishIntegrationState(websiteId as string)
  );
  const [showConnectCmsModal, setShowConnectCmsModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const connectCms = searchParams.get("connectCms");
    if (connectCms) {
      setShowConnectCmsModal(true);
    }
  }, [searchParams]);

  const createDoc = useCallback(
    async (type: DocumentEntityType) => {
      if (!documentsDictionary[type]) return;
      const docId = await documentService.createDocument(
        type,
        websiteId as string
      );
      navigate(`/document/${docId}`);
    },
    [websiteId]
  );

  const docBoxesFiltered = useMemo(() => {
    // 2) If you have a custom array of doc types you want to show, define it here:
    const myCustomDocTypes: DocumentEntityType[] = [
      DocumentEntityType.GENERIC_CAMPAIGN,
      DocumentEntityType.INSTAGRAM_CAPTION,
      DocumentEntityType.INSTAGRAM_SPONSORED_AD,
      DocumentEntityType.FACEBOOK_POST_CAPTION,
      DocumentEntityType.FACEBOOK_SPONSORED_AD,
      DocumentEntityType.LINKEDIN_POST_CAPTION,
      DocumentEntityType.LINKEDIN_SPONSORED_AD,
      DocumentEntityType.TWITTER_TWIT,
      DocumentEntityType.SMS_CONTENT,
      // etc...
    ];

    return DocBoxes.filter((doc) => {
      // If there's a search term, we always filter by title match first:
      if (searchTerm) {
        // Simple "includes" check. Feel free to refine as needed:
        if (!doc.title.toLowerCase().includes(searchTerm.toLowerCase())) {
          return false;
        }
      }

      // Then handle the selectedCategory
      switch (selectedCategory) {

        case "all":
          // 3) Show only docs whose type is in `myCustomDocTypes`
          return myCustomDocTypes.includes(doc.type);
        default:
          // For normal categories (DocumentCategory.*):
          return DocumentTypeToCategory[doc.type] === selectedCategory;
      }
    }).sort();
  }, [searchTerm, selectedCategory]);

  return (
    <>
      {website && (
        <>
          <div className="grid">
            <div className="col-12">
              <WelcomeBackCard
                setSearchTerm={(term) => {
                  setSearchTerm(term);
                  if (selectedCategory) {
                    setSelectedCategory("all");
                  }
                }}
                isCmsConnected={!!publishIntegration}
              ></WelcomeBackCard>
            </div>

            {/* <div className="col-12">
              <KeywordsTable />
            </div> */}

            {/* <div className="col-12">
              <LastGeneratedArticles />
            </div> */}
          </div>

          <DownContainer className="grid m-0">
              <CardFilterItem
                onClick={() => setSelectedCategory("all")}
                title={"המומלצים"}
                icon={<PopularIcon />}
                selected={selectedCategory === "all"}
              />
              <CardFilterItem
                onClick={() => setSelectedCategory(DocumentCategory.CAMPAIGN)}
                title={"קמפיינים"}
                icon={<CampaignIcon />}
                selected={selectedCategory === DocumentCategory.CAMPAIGN}
              />
               <CardFilterItem
                onClick={() =>
                  setSelectedCategory(DocumentCategory.SOCIAL_MEDIA)
                }
                title={"סושיאל"}
                icon={<SocialIcon />}
                selected={selectedCategory === DocumentCategory.SOCIAL_MEDIA}
              />
              <CardFilterItem
                onClick={() => setSelectedCategory(DocumentCategory.PAID)}
                title={"פרסום ממומן"}
                icon={<AdsIcon />}
                selected={selectedCategory === DocumentCategory.PAID}
              />
              <CardFilterItem
                onClick={() => setSelectedCategory(DocumentCategory.MAILING)}
                title={"דיוור"}
                icon={<MarketingIcon />}
                selected={selectedCategory === DocumentCategory.MAILING}
              />
              <CardFilterItem
                onClick={() => setSelectedCategory(DocumentCategory.BLOG)}
                title={"בלוג"}
                icon={<BlogIcon />}
                selected={selectedCategory === DocumentCategory.BLOG}
              />
              
              <CardFilterItem
                onClick={() => setSelectedCategory(DocumentCategory.WEB)}
                title={"אתרים"}
                icon={<WebsiteIcon />}
                selected={selectedCategory === DocumentCategory.WEB}
              />
              
             
            <div className="col-4"></div>

            {/* <div className="col-12">
              <Title>במיוחד בשבילך</Title>
            </div> */}
            <div className="grid w-full m-0">
              {docBoxesFiltered.map((doc, index) => (
                <>
                  {/* {doc.platformType !==
                    docBoxesFiltered[index - 1]?.platformType && (
                    <div className="col-12">
                      <TypeTitle>
                        {platformTypeText[doc.platformType]}
                      </TypeTitle>
                    </div>
                  )} */}
                  <div
                    onClick={() => createDoc(doc.type)}
                    key={index}
                    className="col-2"
                  >
                    <ContentCard
                      title={doc.title}
                      description={doc.description}
                      image={doc.image}
                      type={doc.platformType}
                      highlightTitleTerm={searchTerm}
                    />
                  </div>
                </>
              ))}
            </div>
          </DownContainer>
          {/* <UpgradePackage onHide={() => {}} /> */}
        </>
      )}
      {showConnectCmsModal && (
        <ConnectCmsModal onClose={() => setShowConnectCmsModal(false)} />
      )}
    </>
  );
};

export default SingleWebsite;
