import React, { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { postsSummariesState } from "../state/postState";
import { useParams } from "react-router";
import styled from "styled-components";
import { ContentCardIcons } from "../components/website/overview/contentCards/iconMappings";
import { convertPostToEvent } from "../components/post/convertPostToEvent";
import Card from "../components/common/Card";
import { InputTextarea } from "primereact/inputtextarea";
import { Avatar } from "primereact/avatar";
import { userState } from "../state/userState";
import { InputText } from "primereact/inputtext";
import Button from "../components/common/form/Button";
import { Galleria } from "primereact/galleria";
import CalendarMenu from "../components/calendar/CalendarMenu";
import { AttachmentMedia, PostEntityType } from "neword-core";
import postService from "../core/services/post.service";

const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 80%;
  max-width: 1000px;
  gap: 20px;
`;

const FeedWrapper = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    background: var(--border-color);
    right: 25%;
    z-index: -1;
  }
`;

const PostCard = styled(Card)`
  display: flex;
  flex-direction: row;
  padding: 20px;
  gap: 20px;
  flex: 1;
`;

const PostContentWrapper = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const TimeWrapper = styled.div`
  font-size: 0.875em;
  font-weight: 500;
  color: #606770;
`;

const PlatformsWrapper = styled.div`
  display: flex;
  gap: 8px;
  .icon {
    width: 20px;
    height: 20px;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  margin-top: 10px;
  img {
    width: 100%;
    max-height: 500px;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
`;

const PostText = styled.div`
  font-size: 1em;
  color: #1c1e21;
  margin-top: 15px;
  text-align: start;
  line-height: 1.5;
`;

const CommentSection = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const CommentInputWrapper = styled(Card)``;

const CommentInputCollapsed = styled(InputText)`
  width: 100%;
  border-radius: 8px;
  border: none;
  padding: 10px;
  font-size: 1em;
`;

const CommentInputExpanded = styled(InputTextarea)`
  width: 100%;
  border-radius: 8px;
  border: 1px solid #ddd;
  padding: 10px;
  margin-top: 10px;
  font-size: 1em;
`;

const PostButton = styled(Button)`
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 12px;
  margin-right: auto;
`;

const AvatarStyled = styled(Avatar)`
  background-color: var(--yellow);
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 60px;
  gap: 20px;
  flex: 1;
`;

const SingleCommentWrapper = styled(Card)`
  display: flex;
  flex-direction: start;
  padding: 15px;
  margin-top: 20px;
  flex-direction: column;
  gap: 10px;
`;

const CommentUser = styled.div`
  font-size: 1em;
  font-weight: 500;
`;

const CommentDate = styled.div`
  font-size: 0.875em;
  color: #606770;
`;

const Comment = styled.div`
  font-size: 1em;
  color: #1c1e21;
  text-align: start;
  line-height: 1.5;
`;

const PostImage = styled.img`
  /* position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; */
  width: 60px;
  height: 60px;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-radius: 8px;
`;

const GalleriaStyled = styled(Galleria)`
  ul {
    line-height: 0;
  }
  .p-galleria-indicator button {
    width: 0.5rem !important;
    height: 0.1rem !important;
  }
  .p-galleria-indicators {
    padding-bottom: 10px !important;
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    z-index: 9999;
  }

  .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background-color: var(--primary-purple);
  }
`;

const FeedPage: React.FC = () => {
  const { websiteId } = useParams();
  const [posts, setPosts] = useRecoilState(
    postsSummariesState(websiteId as string)
  );

  const [showModal, setShowModal] = useState(false); // Modal visibility state

  const toggleModal = () => setShowModal((prev) => !prev); // Function to toggle modal visibility
  const [comment, setComment] = useState("");
  const [filterPostType, setFilterPostType] = useState<PostEntityType[]>([]);
  const events = posts
    .filter(
      (post) =>
        filterPostType.length === 0 || filterPostType.includes(post.type)
    )
    .map((post) => convertPostToEvent(post))
    .reverse();
  const [expandedCommentIndex, setExpandedCommentIndex] = useState<
    number | null
  >(null);

  const handleBlur = (index: number) => {
    if (expandedCommentIndex === index) {
      setExpandedCommentIndex(null);
    }
  };

  const user = useRecoilValue(userState);

  const addComment = async (content: string, postId: string) => {
    if (content.trim() === "") {
      return;
    }
    const post = posts.find((post) => post.id === postId);

    setPosts(
      posts.map((p) => {
        if (p.id === postId) {
          return {
            ...p,
            comments: [
              {
                content,
                user: user,
                createdAt: new Date(),
                updatedAt: new Date(),
                id: Math.random().toString(),
              },
              ...(p.comments ?? []),
            ],
          };
        }
        return p;
      })
    );
    await postService.addComment(postId, content, websiteId as string);
    setComment("");
  };

  const isVideoUrl = (url: string) => {
    return /\.(mp4|mov|avi|wmv|mkv)$/i.test(url);
  };

  return (
    <>
      <CalendarMenu
        setFilterPostType={setFilterPostType}
        filterPostType={filterPostType}
        toggleModal={toggleModal} // Pass toggleModal to child
      />
      <PageWrapper>
        <ContentWrapper>
          <FeedWrapper>
            {events.map((event, index) => (
              <SectionWrapper key={index}>
                <PostCard>
                  <PostContentWrapper>
                    <TopWrapper>
                      <TimeWrapper>
                        {event.date.toLocaleDateString()}{" "}
                        {event.date.toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: false,
                        })}
                      </TimeWrapper>{" "}
                      <PlatformsWrapper>
                        {event.platforms.map((p) => p)}
                      </PlatformsWrapper>
                    </TopWrapper>

                    <ImageWrapper>
                      {/* If you only want to check the *first* URL: */}
                      {event.imageUrls && event.imageUrls.length > 0 ? (
                        isVideoUrl(event.imageUrls[0].url) ? (
                          <video
                            controls
                            width="100%"
                            height="auto"
                            style={{ borderRadius: "8px" }}
                          >
                            <source
                              src={event.imageUrls[0].url}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <GalleriaStyled
                            value={event.imageUrls}
                            numVisible={1}
                            showThumbnails={false}
                            showIndicators
                            item={(item: AttachmentMedia) => {
                              // 1) Log out the entire metadata to the console
                              console.log("Item metadata:", item.metadata);
                              console.log("Item metadata:", item.url);

                              // 2) Check for video or image
                              if (item.metadata.type === "VIDEO") {
                                return (
                                  <video
                                    src={item.url}
                                    autoPlay
                                    loop
                                    muted
                                    style={{
                                      width: "100%",
                                      height: "auto",
                                      aspectRatio: "1 / 1",
                                      objectFit: "cover",
                                      // your styles
                                    }}
                                  />
                                );
                              } else {
                                return (
                                  <>
                                    <PostImage className="fuk" src={item.url} />
                                    {/* <small>{item.url}</small> */}
                                  </>
                                );
                              }
                            }}
                          />
                        )
                      ) : null}
                    </ImageWrapper>

                    <PostText>{event.event}</PostText>
                  </PostContentWrapper>
                </PostCard>
                <CommentSection>
                  <CommentInputWrapper>
                    <div className="flex align-items-center">
                      <AvatarStyled
                        label={user?.email.at(0)?.toLocaleUpperCase()}
                        shape="circle"
                        className="ml-2"
                      />
                      <CommentInputCollapsed
                        placeholder="רשום תגובה..."
                        onFocus={() => setExpandedCommentIndex(index)}
                        onChange={(e) => setComment(e.target.value)}
                      />
                    </div>
                    {expandedCommentIndex === index ? (
                      <>
                        <PostButton
                          onClick={() => addComment(comment, event.id)}
                          bgColor="purple"
                          primary
                        >
                          הוסף תגובה
                        </PostButton>
                      </>
                    ) : (
                      <></>
                    )}
                    <>
                      {(event.comments ?? []).map((comment, commentIndex) => (
                        <>
                          <SingleCommentWrapper>
                            <div className="flex align-items-center gap-10">
                              <AvatarStyled
                                label={user.email.at(0)?.toLocaleUpperCase()}
                                shape="circle"
                                className=""
                              />
                              <CommentUser>
                                {comment.user.firstName}
                              </CommentUser>
                              <CommentDate>
                                {new Date(
                                  comment.createdAt
                                ).toLocaleDateString()}
                              </CommentDate>
                            </div>
                            <Comment>{comment.content}</Comment>
                          </SingleCommentWrapper>
                          <small className="mb-3 mt-3">
                            * התגובות המוצגות כאן הן פידבק ממשתמשי המערכת ולא
                            תגובות פומביות על הפוסט.{" "}
                          </small>
                        </>
                      ))}
                    </>
                  </CommentInputWrapper>
                </CommentSection>
              </SectionWrapper>
            ))}
          </FeedWrapper>
        </ContentWrapper>
      </PageWrapper>
    </>
  );
};

export default FeedPage;
