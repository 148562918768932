export enum ContentCardType {
  INSTAGRAM = "INSTAGRAM",
  FACEBOOK = "FACEBOOK",
  TWITTER = "TWITTER",
  LINKEDIN = "LINKEDIN",
  THREADS = "THREADS",
  EMAIL = "EMAIL",
  SMS = "SMS",
  WEB = "WEB",
  GOOGLE = "GOOGLE",
  CAMPAIGN = "CAMPAIGN",
}

export const platformTypeText: { [key in ContentCardType]: string } = {
  [ContentCardType.INSTAGRAM]: "אינסטגרם",
  [ContentCardType.FACEBOOK]: "פייסבוק",
  [ContentCardType.TWITTER]: "טוויטר (x)",
  [ContentCardType.LINKEDIN]: "לינקדין",
  [ContentCardType.THREADS]: "ת׳רדס",
  [ContentCardType.EMAIL]: "אימייל",
  [ContentCardType.CAMPAIGN]: "קמפיינים",
  [ContentCardType.SMS]: "סמס",
  [ContentCardType.WEB]: "אתר אינטרנט",
  [ContentCardType.GOOGLE]: "גוגל",
};
