import {
  AttachmentMedia,
  DocumentEntity,
  DocumentEntityType,
  PostEntityType,
} from "neword-core";
import { PostData } from "neword-core/dist/cjs/entities/post/postData";

export const convertDocToPostParams = (
  doc: DocumentEntity,
  blockIndex: number,
  AttachmentsMedias: AttachmentMedia[]
): PostData => {
  const imageUrls: AttachmentMedia[] = doc.inputParams.imageUrl
    ? AttachmentsMedias.filter(
        (attachment) => attachment.url === doc.inputParams.imageUrl
      )
    : [];

  switch (doc.type) {
    case DocumentEntityType.FACEBOOK_POST_CAPTION:
      return {
        type: PostEntityType.FACEBOOK_POST,
        message: doc.output[blockIndex].postCaption,
        published: false,
        imageUrls: imageUrls,
      };

    case DocumentEntityType.FACEBOOK_POST_IDEA:
      return {
        type: PostEntityType.FACEBOOK_POST,
        message: doc.output[blockIndex].postIdea,
        published: false,
        imageUrls: [],
      };
    case DocumentEntityType.FACEBOOK_PAGE_ABOUT:
      return {
        type: PostEntityType.FACEBOOK_POST,
        message: doc.output[blockIndex].pageAbout,
        published: false,
        imageUrls: [],
      };
    case DocumentEntityType.FACEBOOK_SPONSORED_AD:
      return {
        type: PostEntityType.FACEBOOK_POST,
        message: doc.output[blockIndex].captionText,
        published: false,
        imageUrls: [],
      };

    case DocumentEntityType.INSTAGRAM_CAPTION:
      return {
        type: PostEntityType.INSTAGRAM_POST,
        media_type: "IMAGE",
        imageUrls: imageUrls,
        media_ids: [],
        message: doc.output[blockIndex].captionText,
      };
    case DocumentEntityType.INSTAGRAM_POST_IDEA:
      return {
        type: PostEntityType.INSTAGRAM_POST,
        media_type: "IMAGE",
        imageUrls: imageUrls,
        media_ids: [],
        message: doc.output[blockIndex].ideaDescription,
      };
    case DocumentEntityType.INSTAGRAM_SPONSORED_AD:
      return {
        type: PostEntityType.INSTAGRAM_POST,
        media_type: "IMAGE",
        imageUrls: imageUrls,
        media_ids: [],
        message: doc.output[blockIndex].primaryText,
      };
    case DocumentEntityType.INSTAGRAM_BIO_DESCRIPTION:
      return {
        type: PostEntityType.INSTAGRAM_POST,
        media_type: "IMAGE",
        imageUrls: imageUrls,
        media_ids: [],
        message: doc.output[blockIndex].bioDescription,
      };
    case DocumentEntityType.LINKEDIN_POST_CAPTION:
      return {
        type: PostEntityType.LINKEDIN_POST,
        imageUrls: imageUrls,
        message: doc.output[blockIndex].postCaption,
      };
    case DocumentEntityType.LINKEDIN_PAGE_ABOUT:
      return {
        type: PostEntityType.LINKEDIN_POST,
        imageUrls: imageUrls,
        message: doc.output[blockIndex].pageAbout,
      };
    case DocumentEntityType.LINKEDIN_POST_IDEA:
      return {
        type: PostEntityType.LINKEDIN_POST,
        imageUrls: imageUrls,
        message: doc.output[blockIndex].postIdea,
      };

    case DocumentEntityType.GENERIC_CAMPAIGN:
      return convertDocToPostParams(
        {
          ...doc,
          type: doc.output[blockIndex].type as any,
        },
        blockIndex,
        AttachmentsMedias
      );
    default:
      return {
        type: PostEntityType.FACEBOOK_POST,
        message: "",
        published: false,
        imageUrls: [],
      };
  }
};
