import { FieldValues, Path } from "react-hook-form";
import { AttachmentMedia, PostEntity, PostEntityType } from "neword-core";

import { PinturaEditor, PinturaEditorOverlay } from "@pqina/react-pintura";
import {
  createDefaultImageWriter,
  createDefaultMediaWriter,
  getEditorDefaults,
  setPlugins,
  imageStateToCanvas,
  PinturaEditorDefaultOptions,
  PinturaDefaultImageWriterOptions,
} from "@pqina/pintura";
import {
  plugin_trim_locale_en_gb,
  plugin_trim,
  createDefaultVideoWriter,
  createMediaStreamEncoder,
  PinturaDefaultVideoWriterOptions,
  PinturaMediaStreamEncoderOptions,
} from "@pqina/pintura-video";

import "@pqina/pintura-video/pinturavideo.css";
import "@pqina/pintura/pintura.css";
import { useParams } from "react-router";
import { websiteService } from "../../core/services/website.service";
import { Dialog } from "primereact/dialog";
import { useState } from "react";

setPlugins(plugin_trim);

function MediaEditor({
  url,
  onProcess,
  onClose,
  videoSettings,
  imageSettings,
  imageWriterSettings,
  hideCloseButton,
}: {
  url: string;
  onProcess: (media: AttachmentMedia) => void;
  onClose: () => void;
  videoSettings?: Partial<PinturaMediaStreamEncoderOptions>;
  imageSettings?: PinturaEditorDefaultOptions;
  imageWriterSettings?: PinturaDefaultImageWriterOptions;
  hideCloseButton?: boolean;
}) {
  const { websiteId } = useParams();
  const [disabled, setDisabled] = useState(false);

  return (
    <Dialog
      style={{
        width: "60vw",
        margin: "0",
        boxShadow: "none",
        height: "90vh",
        borderLeft: "solid 1px var(--border-color)",
        borderRadius: "0px",
        maxHeight: "100%",
        padding: "0",
      }}
      closable={!hideCloseButton}
      visible
      onHide={onClose}
      modal
    >
      <PinturaEditor
        // Add the video plugin

        {...{
          ...getEditorDefaults({
            ...imageSettings,
            locale: {
              // Add the Trim plugin locale
              ...plugin_trim_locale_en_gb,
            },
            imageWriter: createDefaultMediaWriter(
              // Generic Media Writer options, passed to image and video writer
              { ...imageWriterSettings },
              [
                createDefaultImageWriter({
                  quality: 0.9,
                  mimeType: "image/jpeg",
                }),

                // For handling videos
                createDefaultVideoWriter({
                  // Video writer instructions here
                  // ...

                  // Encoder to use
                  encoder: createMediaStreamEncoder({
                    imageStateToCanvas,

                    ...videoSettings,
                  }),
                }),
              ]
            ),
          }),
        }}
        src={url}
        disabled={disabled}
        onProcess={(res) => {
          setDisabled(true);
          const file = res.dest; // The processed file from Pintura
          const reader = new FileReader();

          reader.onload = async () => {
            const base64String = reader.result?.toString().split(",")[1]; // Extract the Base64 part
            if (base64String) {
              const attachmentUrl = await websiteService.uploadAttachment(
                websiteId as string,
                "edited" + url,
                base64String
              );
              console.log(attachmentUrl);
              onClose();
              onProcess(attachmentUrl);
              setDisabled(false);
            } // Store the Base64 string in state, if needed
          };

          reader.onerror = (error) => {
            console.error("Error converting file to Base64:", error);
          };

          reader.readAsDataURL(file); // Read the file as a Data URL (Base64 encoded)
        }}
      />
    </Dialog>
  );
}

export default MediaEditor;
