import { Dialog } from "primereact/dialog";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { audienceState } from "../../../state/audienceState";
import { FormProvider, useController, useForm } from "react-hook-form";
import { CreateWebsiteRequestData } from "../../../core/services/requests/createWebsite/createWebsiteRequestData";
import { InputText } from "primereact/inputtext";
import FormStyle from "./FormStyle";
import { ReactComponent as RefreshIcon } from "../../../assets/Icons/refresh.svg";
import RangeSlider from "./RangeSlider";
import Avatar, { genConfig } from "react-nice-avatar";
import { Dropdown } from "primereact/dropdown";
import { GenderType } from "../../../core/types/genderType";
import SelectKeywordsInput from "../../keywords/SelectKeywordsInput";
import Button from "./Button";
import writerService from "../../../core/services/writer.service";
import { WebsiteTone } from "../../../core/entities/tone";
import { InputTextarea } from "primereact/inputtextarea";
import { toneState } from "../../../state/toneState";

const Title = styled.h2`
  color: var(--Main-TitleColor, #0a2540);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 1rem */
  letter-spacing: -0.03rem;
  margin-top: 10px;
`;

const Subtitle = styled.h3`
  margin-top: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 0.875rem */
  letter-spacing: -0.02625rem;
`;

const DialogStyled = styled(Dialog)`
  max-width: 70%;
`;

interface Props {
  onHide: () => void;
}

const CreateToneModal: React.FC<Props> = ({ onHide }) => {
  const { websiteId } = useParams();
  const [tones, setTones] = useRecoilState(toneState(websiteId as string));
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm<
    Omit<WebsiteTone, "websiteId" | "id" | "companyId" | "summerizedToneText">
  >({
    defaultValues: {},
  });

  const onSubmit = async (
    data: Omit<
      WebsiteTone,
      "websiteId" | "id" | "companyId" | "summerizedToneText"
    >
  ) => {
    setIsLoading(true);
    const tone = await writerService.createTone(data, websiteId as string);
    setTones([...tones, tone]);
    onHide();
  };

  return (
    <DialogStyled
      header={
        <>
          <Title>צור סגנון כתיבה חדש</Title>
          <Subtitle>צור סגנון כתיבה מותאם לעסק שלך</Subtitle>
        </>
      }
      visible
      onHide={onHide}
      modal
      footer={
        <>
          <Button
            data-pr-position="top"
            primary
            disabled={!methods.formState.isValid}
            onClick={methods.handleSubmit(onSubmit)}
            loading={isLoading}
          >
            צור סגנון
          </Button>
        </>
      }
    >
      <FormProvider {...methods}>
        <FormStyle>
          <div className="grid">
            <div className="col-6">
              <label htmlFor="username">שם הסגנון</label>
              <InputText
                {...methods.register("name")}
                placeholder="איך סגנון הכתיבה שלכם נקרא?"
              ></InputText>
            </div>

            <div className="col-12">
              <label htmlFor="username"></label>
              <InputTextarea
                {...methods.register("toneText")}
                placeholder="הכנס טקסט בטון הרצוי"
              />
            </div>
          </div>
        </FormStyle>
      </FormProvider>
    </DialogStyled>
  );
};

export default CreateToneModal;
