import React from 'react';
import PageTitle from '../../components/common/PageTitle';
import styled from 'styled-components';
import { ReactComponent as EditIcon } from "../../assets/Icons/Edit.svg";
import { ReactComponent as PlusIcon } from "../../assets/Icons/PlusBorder.svg";
import Badge from '../../components/common/Badge';
import { Avatar } from 'primereact/avatar';

const AudienceContainer = styled.div``;

const IconWrapper = styled.div`
  position: absolute;
  bottom: 12px;
  left: 17px;
  opacity: 0;
  transition-duration: 0.1s;
`;

const AudienceCard = styled.div`
background-color: #fff;
  display: flex;
  gap: 20px;
  flex-direction: row;
  align-items: flex-start;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  position: relative;
  transition-duration: 0.1s;
  cursor: pointer;

  &.add-audience {
    border: 1px dashed #ddd;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      width: 20px;
      height: 20px;
    }
  }

  &:hover {
    border-color: var(--title-color);

    ${IconWrapper} {
      opacity: 1;
    }
  }
`;

const AudienceName = styled.span`
  display: flex;
  gap: 10px;
  flex-direction: column;
  margin-top: 10px;
  font-weight: bold;
  span {
    font-weight: 500;
  }
`;

const AvatarWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
`;

const Leads: React.FC = () => {
    return (
       <>
             <PageTitle
        title="קבוצות לידים"
        subtitle="כאן תוכלו לראות את קבוצות הלידים"
      />

<AudienceContainer>
        <div className="grid">
        <div className="col-4" >
                <AudienceCard>
                  <AvatarWrapper>
                    <Avatar
                      style={{ width: "2.5rem", height: "2.5rem" }}
                    />
                  </AvatarWrapper>

                  <AudienceName>
                    <div className="flex">
                      <div>
                        לידים חמים מפחיד
                      </div>
                     
                    </div>

                    <div className="flex align-items-center mt-3">
                    <div>
                        מספר נמענים: <span>32</span>
                      </div>
                      
                    </div>
                  </AudienceName>
                  <IconWrapper>
                    <EditIcon />
                  </IconWrapper>
                </AudienceCard>
              </div>
          <div className="col-4">
            <AudienceCard
              className="add-audience"
            >
              <PlusIcon className="icon" />
              צור קבוצה חדשה
            </AudienceCard>
          </div>
        </div>
      </AudienceContainer>
       </>
    );
};

export default Leads;