import { DocumentToneType } from 'neword-core';

export const tonePromptFactory: Record<DocumentToneType, string> = {
  MARKETING: `
  - שפה אישית וקרובה: הטון ידידותי, כאילו הכותב מדבר עם חברים. 
  - פנייה בגוף ראשון ושני, שמייצרת תחושת אינטימיות ושיח ישיר.
  - שימוש בשפה מדוברת וזורמת, כולל ביטויים יום-יומיים וסלנג במידה.
  - שילוב אלמנטים של סטוריטלינג, תוך שיתוף חוויות אישיות לחיבור רגשי עם הקהל.
  - הקפידו על כתיבה שמיועדת לקהל יעד ברבים (למשל "אתם", "שלכם").
  `,

  SOLDABLE: `
  - טון מחקרי ומבוסס נתונים: הציגו עובדות, מחקרים וסטטיסטיקות ממקורות מהימנים.
  - הדגישו הצגה ברורה ואובייקטיבית של נתונים (ניתן לשלב גרפים, טבלאות ודוגמאות מוחשיות).
  - שמרו על שפה מדוברת ונגישה, אך עם הסברים מפורטים ומדויקים.
  - הקפידו על כתיבה שמיועדת לקהל יעד ברבים (למשל "אתם", "שלכם").
  `,

  FRIENDLLY: `
  - כתבו מסר ידידותי, שמחזק תחושת אמון ורעות עם הקהל.
  - שמרו על שפה מדוברת וקלה להבנה, כאילו אתם משוחחים עם חברים.
  - הקפידו על כתיבה שמיועדת לקהל יעד ברבים (למשל "אתם", "שלכם").
  `,

  INSPIRED: `
  - צורו מסר מעורר השראה, שמעודד את הקהל לפעולה או לשינוי חשיבה.
  - השתמשו בשפה נלהבת ומעצימה, עם מילים חיוביות שמניעות קדימה.
  - חזקו תחושת מסוגלות אישית ועודדו את הקוראים לפעול.
  - הקפידו על שפה מדוברת ופנייה לקהל יעד ברבים (למשל "אתם", "שלכם").
  `,

  INSTRUCTIONAL: `
  - גישה מעשית: ספקו טיפים וכלים ליישום מיידי או הדרכה ברורה למשימה מסוימת.
  - כתבו משפטים תמציתיים וידידותיים, בשפה מובנת לכולם.
  - השתמשו בסגנון מדריך ("כך תעשו..."), והוסיפו דוגמאות או שלבים לביצוע.
  - הקפידו על שפה מדוברת ופנייה לקהל יעד ברבים (למשל "אתם", "שלכם").
  `,

  CONVINCING: `
  - התחילו בטיעון משכנע, שמוביל את הקהל להסכים עם נקודת מבט או לבצע פעולה.
  - הטון בטוח ומגובה בדוגמאות חיות או נתונים אמינים.
  - ניתן לשלב סיפורים אישיים לחיבור רגשי, לצד לוגיקה ברורה לשכנוע.
  - הקפידו על שפה מדוברת ופנייה לקהל יעד ברבים (למשל "אתם", "שלכם").
  `,

  HOMORISTIC: `
  - טון משעשע וקליל: כאילו אתם מדברים על נושא רציני בגישה הומוריסטית.
  - שילוב משחקי מילים, סלנג עברי ודימויים מוגזמים בכוונה, ליצירת קריצה וחיוך.
  - אפשר להוסיף הומור עצמי או רמיזות שובבות כדי לשבור את הקרח.
  - השתמשו במטאפורות וקצת ממים (memes) בצורה מדודה – המסר נשאר העיקר.
  - הקפידו על שפה מדוברת ופנייה לקהל יעד ברבים (למשל "אתם", "שלכם").
  `,

  TRENDY: `
  - ניצול שפה עכשווית: השתמשו במילות מפתח אופנתיות, טרנדים ברשת ובאזזוורדס.
  - צרו תחושת אקטואליות והתחברות לעולם העכשווי (לדוגמה, אזכור אירועים רלוונטיים או רפרנסים פופולריים).
  - שמרו על שפה מדוברת ועדכנית, וכתבו כך שהקהל ירגיש בעניינים.
  - הקפידו על כתיבה שמיועדת לקהל יעד ברבים (למשל "אתם", "שלכם").
  `,

  DRAMATIC: `
  - כתיבה בסגנון דרמטי: הדגישו רגשות עוצמתיים, תיאורים מוחשיים ומתח מוגבר.
  - השתמשו במשפטי קריאה ובתרחישים שמעצימים את החוויה או המתח.
  - ניתן לשלב מעט תיאטרליות ו"הגזמה אומנותית" כדי למשוך תשומת לב.
  - הקפידו על שפה מדוברת ופנייה לקהל יעד ברבים (למשל "אתם", "שלכם").
  `,

  FORMAL: `
  - סגנון רשמי ומכובד: שמרו על טון מקצועי, מכבד וברור.
  - המנעו מסלנג, אך המשיכו לכתוב במשפטים הנגישים לכל קורא (מבלי להישמע מרוחק מדי).
  - אם רלוונטי, הקפידו לציין מקורות וסימוכין ולשמור על סדר לוגי והיררכיה ברורה בטקסט.
  - הקפידו על כתיבה שמיועדת לקהל יעד ברבים (למשל "אתם", "שלכם").
  `,
  REGULAR: `
  - כתיבה ניטרלית וברורה שמביאה את המידע בצורה פשוטה וישירה.
  - שפה מדוברת ונגישה, ללא סלנג או אלמנטים בולטים.
  `,
  PROVOCATIVE: `
  - כתיבה שמטרתה לעורר תגובות רגשיות חזקות או דיון בקהל.
  - שימוש במילים פרובוקטיביות ובגישה ישירה, לפעמים אפילו נוקבת.
  - מתאים לנושאים שרוצים להבליט, לשבור מוסכמות או לעורר מודעות.
  `,
};
