import { Dialog } from "primereact/dialog";
import styled from "styled-components";
import { ReactComponent as AddIcon } from "../../../assets/Icons/PlusBorder.svg";
import { useRef, useState } from "react";
import { useParams } from "react-router";
import { websiteService } from "../../../core/services/website.service";
import { useRecoilState, useRecoilValue } from "recoil";
import { attachmentsState } from "../../../state/attachmentsState";
import { websiteState } from "../../../state/websitesState";
import { AttachmentMedia } from "neword-core";
import { toast } from "react-toastify";

const UploadContainer = styled.div`
  height: 140px;
  width: 140px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed var(--border-color);
  flex-direction: column;
  cursor: pointer;
  position: relative;
  svg {
    width: 40px;
    height: 40px;
    fill: var(--border-color);
  }
  span {
    font-weight: bold;
    color: var(--border-color);
  }
  &:hover {
    border: 2px dashed var(--primary-color);
    svg {
      fill: var(--primary-color);
    }
    span {
      color: var(--primary-color);
    }
  }
`;

const ImageBox = styled.img`
  cursor: pointer;
  height: 140px;
  width: 140px;
  object-fit: cover;
  border-radius: 12px;
  border: 1px solid rgb(230, 230, 230);
  &:hover {
    border: 1px solid var(--primary-color);
  }
`;

const VideoBox = styled.video`
  cursor: pointer;
  height: 140px;
  width: 140px;
  object-fit: cover;
  border-radius: 8px;
  border: 1px solid rgb(230, 230, 230);
  &:hover {
    border: 1px solid var(--primary-color);
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Grid = styled.div`
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;
  max-height: 95%;
  overflow-y: auto;
  justify-content: start;
`;

const HiddenInput = styled.input`
  display: none;
`;

interface Props {
  onHide: () => void;
  onSelectAttachment: (attachmentUrl: AttachmentMedia) => void;
  accept: string;
  hideVideos?: boolean;
}

const Title = styled.h1`
  color: #0a2540;
  font-size: 2.5rem;
  font-weight: 700;
`;

const SubTitle = styled.h2`
  color: #425466;
  font-weight: 300;
`;

const WrapperX = styled.div`
  margin-top: 40px;
`;

const AttachmentsModal: React.FC<Props> = ({
  onHide,
  onSelectAttachment,
  accept,
  hideVideos,
}) => {
  const { websiteId } = useParams();
  const website = useRecoilValue(websiteState(websiteId as string));
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [existingAttachments, setAttachments] = useRecoilState(
    attachmentsState(websiteId as string)
  );
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setLoading(true);
      const t = toast.loading("הקובץ עולה... אנא לא לרענן את העמוד");
      try {
        const attachment = await websiteService.uploadAttachmentInChunks(
          file,
          websiteId
        );
        setAttachments([attachment, ...existingAttachments]);
        toast.success("הקובץ הועלה בהצלחה");
      } catch (err) {
        console.error(err);
        toast.error("אירעה שגיאה בעת העלאת הקובץ");
      } finally {
        toast.dismiss(t);
        setLoading(false);
      }
    }
  };

  return (
    <Dialog
      position={"left"}
      style={{
        height: "100vh",
        margin: "0",
        boxShadow: "none",

        borderLeft: "solid 1px var(--border-color)",
        borderRadius: "0px",
        maxHeight: "100%",
        padding: "0",
      }}
      header={
        <WrapperX>
          <Title>התמונות והסרטונים של {website?.name}</Title>
          <SubTitle>בחר או העלה קובץ חדש</SubTitle>
        </WrapperX>
      }
      visible
      onHide={onHide}
      modal
    >
      <br />
      <Grid>
        {loading ? (
          <Wrapper>
            <UploadContainer>
              <span>טוען...</span>
            </UploadContainer>
          </Wrapper>
        ) : (
          <Wrapper>
            <UploadContainer onClick={handleClick}>
              <AddIcon />
            </UploadContainer>
            <HiddenInput
              type="file"
              accept={accept}
              ref={fileInputRef}
              onChange={handleFileChange}
            />
          </Wrapper>
        )}

        {(!hideVideos
          ? existingAttachments
          : existingAttachments.filter(
              (media) => media.metadata.type !== "VIDEO"
            )
        ).map((media, index) => (
          <Wrapper onClick={() => onSelectAttachment(media)} key={index}>
            {media.metadata.type === "VIDEO" ? (
              <VideoBox src={media.url} controls />
            ) : (
              <ImageBox src={media.url} alt="attachment" />
            )}
          </Wrapper>
        ))}
      </Grid>
    </Dialog>
  );
};

export default AttachmentsModal;
