import { ReactComponent as IgIcon } from "../../../assets/Icons/InstagramIcon.svg";
import { ReactComponent as Facebook } from "../../../assets/Icons/facebook/FacebookLogo.svg";
import { ReactComponent as Linkedin } from "../../../assets/Icons/linkedin/LinkedInLogo.svg";
import { ReactComponent as TiktokLogo } from "../../../assets/Icons/TiktokLogo.svg";
import { ReactComponent as ReelsIcon } from "../../../assets/Icons/ReelsIcon.svg";
import { ReactComponent as Twitter } from "../../../assets/Icons/XLogo.svg";
import { ReactComponent as EMailLogo } from "../../../assets/Icons/EmailLogo.svg";
import { PostEntityType } from "neword-core";

const PlatformPostTypes = [
  {
    platform: "פוסט לאינסטגרם",
    content: "צרו פוסט, קרוסלה לאינסטגרם",
    icon: IgIcon,
    type: PostEntityType.INSTAGRAM_POST,
  },
  {
    platform: "פוסט לפייסבוק",
    content: "צרו פוסט, קרוסלה, וידאו לפייסבוק",
    icon: Facebook,
    type: PostEntityType.FACEBOOK_POST,
  },
  {
    platform: "פוסט ללינקדאין",
    content: "צרו פוסט, קרוסלה ללינקדאין",
    icon: Linkedin,
    type: PostEntityType.LINKEDIN_POST,
  },
  {
    platform: "ריל לאינסטגרם",
    content: "צרו ריל לאינסטגרם",
    icon: ReelsIcon,
    type: PostEntityType.INSTAGRAM_REEL,
  },
  {
    platform: "ריל לפייסבוק",
    content: "צרו ריל לפייסבוק",
    icon: Facebook,
    type: PostEntityType.FACEBOOK_REEL,
  },
  {
    platform: "סרטון לטיקטוק",
    content: "צרו סרטון לטיקטוק",
    icon: TiktokLogo,
    type: PostEntityType.TIKTOK_POST,
  },
  {
    platform: "ציוץ לX (טוויטר)",
    content: "צרו ציוץ לטוויטר",
    icon: Twitter,
    type: PostEntityType.TWITTER_TWIT,
  },
  {
    platform: "אימייל",
    content: "צרו אימייל מותאם אישית ללקוחות שלכם",
    icon: EMailLogo,
    type: PostEntityType.EMAIL,
  },
];

export default PlatformPostTypes;
