import { useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import { CreateWebsiteRequestData } from "../../../core/services/requests/createWebsite/createWebsiteRequestData";
import FormStyle from "../../common/form/FormStyle";
import styled from "styled-components";
import Button from "../../common/form/Button";
import Link from "../../common/Link";
import { ReactComponent as CloseIcon } from "../../../assets/Icons/Close.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import Preloader from "../../common/Preloader";
import { websiteService } from "../../../core/services/website.service";
import { useRecoilState, useRecoilValue } from "recoil";
import { websitesStateSelector } from "../../../state/websitesState";
import { WriterTemplate } from "../../../core/types/writerTemplates";
import { GoalType } from "../../../core/types/goalType";
import { ToneType } from "../../../core/types/toneType";
import MinimalTopMenu from "./MinimalTopMenu";
import useMultiStepForm from "../../../hooks/useMultiStepForm";
import { Language } from "../../../core/types/language";
import { userState } from "../../../state/userState";
import bgCircle from "../../../assets/images/bgcircle.png";
import { GenderType } from "../../../core/types/genderType";
import { v4 as uuidv4 } from "uuid";
import IntegrationWarningModal from "../../modals/IntegrationWarningModal";
import { useCreateWebsiteSteps } from "./useCreateWebsiteSteps";

const Container = styled.div`
  height: calc(100vh - 55px);
  background: url(${bgCircle}) no-repeat;
  background-size: 100% 100%;
`;

const CenteredForm = styled(FormStyle)`
  margin-top: 60px;
  padding: 0px 200px;
  display: flex;
  flex-direction: column;
  place-content: center;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  align-items: center;
`;

const InputsContainer = styled.div`
  margin: 20px 0;
`;

const ExitButton = styled.div`
  position: absolute;
  top: 95px;
  right: 20px;

  & svg {
    width: 30px;
    height: 30px;
  }
`;

const SubTitle = styled.h2`
  margin-top: 20px;
  color: #425466;
  text-align: right;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 1.25rem */
  letter-spacing: -0.0375rem;
  text-align: center;
`;

const Title = styled.h1`
  color: #0a2540;
  font-size: 2.5rem;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 2.5rem */
  letter-spacing: -0.075rem;
`;

const StepDot = styled.div<{ $isActive: boolean; $isCompleted: boolean }>`
  width: 40px;
  height: 10px;
  border-left: 1px solid var(--primary-purple);
  background-color: ${(props) =>
    props.$isActive
      ? "var(--primary-purple)" // Active step
      : props.$isCompleted
        ? "var(--purple-opacity)" // Completed steps with light opacity purple
        : "var(--light-bg)"}; // Inactive steps
  transition: background-color 0.3s;
`;

const StepIndicator = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--light-bg);
  border: 1px solid var(--primary-purple);
  border-radius: 10px;

  ${StepDot}:first-child {
    border-radius: 0px 10px 10px 0px;
  }

  ${StepDot}:last-child {
    border-left: none;
    border-radius: 10px 0px 0px 10px;
  }
`;

const CreateWebsiteForm: React.FC = () => {
  const navigate = useNavigate();
  const [websites, setWebsites] = useRecoilState(websitesStateSelector);
  const [searchParams] = useSearchParams();

  const websiteId = uuidv4();
  const createWebsiteSteps = useCreateWebsiteSteps();

  const {
    methods,
    component,
    showBackButton,
    onClickNextButton,
    activeStep,
    isLoading,
    navigatePrevStep,
    error,
    isFormValid,
  } = useMultiStepForm<CreateWebsiteRequestData>(createWebsiteSteps, onSubmit, {
    website: {
      url: searchParams.get("websiteUrl") || "",
      id: websiteId,
    },
    writer: {
      templates: Object.values(WriterTemplate),
      goal: GoalType.CONVERSION,
      tone: ToneType.CONVERSATIONAL,
      language: Language.HEBREW,
    },
    audience: {
      painPoints: [],
      gender: GenderType.BOTH,
    },
  });
  const [integrationWarningModal, setIntegrationWarningModal] = useState(false);

  async function onSubmit(skipIntegrations: boolean = false) {
    const values = methods.getValues();

    if (!values.appIntegrationsCounter && !skipIntegrations) {
      setIntegrationWarningModal(true);
      return;
    }

    try {
      const website = await websiteService.createWebsiteWithWorker(values);

      const connectCms = searchParams.get("connectCms");

      if (connectCms) {
        return navigate(`/websites/${website.id}?${searchParams.toString()}`);
      }
      setWebsites([...websites, website]);
      navigate(`/websites/${website.id}/`);
    } catch (err) {}
  }

  const shouldShowExitButton = useMemo(
    () => !searchParams.get("hideExit"),
    [searchParams]
  );

  const user = useRecoilValue(userState);

  return (
    <>
      <MinimalTopMenu title="" showAvater />
      <Container>
        <div className="grid h-full m-0">
          <div className="col-12">
            {shouldShowExitButton && (
              <Link path="/" className="flex align-items-center">
                <ExitButton>
                  <CloseIcon />
                </ExitButton>
              </Link>
            )}

            <FormProvider {...methods}>
              <CenteredForm
                onKeyDown={(e) => {
                  if (e.key === "Enter") e.preventDefault();
                }}
              >
                <Title>
                  {createWebsiteSteps[activeStep].title ? (
                    createWebsiteSteps[activeStep].title
                  ) : (
                    <>היי {user.firstName} 👋 מה מתאר אותך הכי טוב?</>
                  )}
                </Title>
                <SubTitle>
                  {createWebsiteSteps[activeStep].subtitle ? (
                    createWebsiteSteps[activeStep].subtitle
                  ) : (
                    <>ככה נוכל להתאים את המערכת בדיוק בשבילכם!</>
                  )}
                </SubTitle>

                <InputsContainer>{component}</InputsContainer>
                <div className="flex">
                  {showBackButton && (
                    <Button
                      className="ml-4"
                      borderColor="title"
                      primary={false}
                      arrowPlacement="left"
                      onClick={navigatePrevStep}
                    >
                      חזור
                    </Button>
                  )}
                  <Button
                    bgColor="purple"
                    primary
                    arrowPlacement="right"
                    disabled={!isFormValid}
                    onClick={onClickNextButton}
                    data-pr-tooltip={error}
                    data-pr-position="top"
                    name="create-website-button"
                    loading={isLoading}
                  >
                    {createWebsiteSteps[activeStep].nextButtonText}
                  </Button>
                </div>
                <StepIndicator>
                  {createWebsiteSteps.map((_, index) => (
                    <StepDot
                      key={index}
                      $isActive={index === activeStep}
                      $isCompleted={index < activeStep}
                    />
                  ))}
                </StepIndicator>
              </CenteredForm>
            </FormProvider>
            {isLoading && <Preloader></Preloader>}
          </div>
        </div>

        {integrationWarningModal && (
          <IntegrationWarningModal
            onHide={() => setIntegrationWarningModal(false)}
            onSubmit={() => onSubmit(true)}
          />
        )}
      </Container>
    </>
  );
};

export default CreateWebsiteForm;
