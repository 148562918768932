export enum PostEntityType {
  INSTAGRAM_POST = "INSTAGRAM_POST",
  INSTAGRAM_REEL = "INSTAGRAM_REEL",
  FACEBOOK_POST = "FACEBOOK_POST",
  FACEBOOK_REEL = "FACEBOOK_REEL",

  LINKEDIN_POST = "LINKEDIN_POST",
  TWITTER_TWIT = "TWITTER_TWIT",
  THREADS_TWIT = "THREADS_TWIT",
  TIKTOK_POST = "TIKTOK_POST",
  EMAIL = "EMAIL",
  SMS = "SMS",
  ARTICLE = "ARTICLE",
  WEBSITE = "WEBSITE",
}

export type PostSocialType =
  | PostEntityType.INSTAGRAM_POST
  | PostEntityType.INSTAGRAM_REEL
  | PostEntityType.FACEBOOK_POST
  | PostEntityType.FACEBOOK_REEL
  | PostEntityType.LINKEDIN_POST
  | PostEntityType.TWITTER_TWIT
  | PostEntityType.THREADS_TWIT
  | PostEntityType.TIKTOK_POST;
