import React, { useState } from "react";
import styled, { keyframes, css } from "styled-components";
import { ReactComponent as TrashIcon } from "../../../assets/Icons/Trash.svg";
import { ReactComponent as DragIcon } from "../../../assets/Icons/Drag.svg";
import { Theme } from "../../../core/theme/theme";
import { ReactComponent as AddIcon } from "../../../assets/Icons/Plus.svg";
import { useDrag, useDrop } from "react-dnd";
import { Controller, FieldValues, Path, useFormContext } from "react-hook-form";
import AttachmentsModal from "./AttachmentsModal";
import { AttachmentMedia } from "neword-core";

// Utility to check if the background is a video

const highlightAnimation = keyframes`
  0% { border-color: ${Theme.colors.purple}; }
  50% { border-color: transparent; }
  100% { border-color: ${Theme.colors.purple}; }
`;

const UploadImage = styled.div<{
  backgroundImage?: string;
  uploadButton?: boolean;
  isOver?: boolean;
}>`
  margin-bottom: ${(props) => (props.uploadButton ? "20px" : "0px")};
  cursor: pointer;
  position: relative;
  height: 100px;
  width: 100px;
  border-radius: 8px;
  gap: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${(props) =>
    props.backgroundImage
      ? "none"
      : props.isOver
        ? `2px dashed ${Theme.colors.purple}`
        : "1px dashed var(--border-color)"};
  flex-direction: column;
  transition:
    transform 0.2s ease-in-out,
    border-color 0.3s ease;

  ${(props) =>
    props.isOver &&
    css`
      animation: ${highlightAnimation} 1s infinite;
    `}

  svg {
    width: 10px;
    height: 10px;
    fill: var(--title-color);
  }

  span {
    font-size: 12px;
    font-weight: bold;
    color: var(--title-color);
  }

  &:hover {
    svg {
      fill: ${Theme.colors.purple};
    }
    span {
      color: ${Theme.colors.purple};
    }
    transform: scale(1.05);
  }

  &.p-invalid {
    border-color: red;
  }

  :hover .trash {
    display: block;
  }
`;

const TrashIconStyled = styled(TrashIcon)`
  width: 10px;
  height: 10px;
  cursor: pointer;
  fill: red !important;
  position: absolute;
  border-radius: 30px;
  z-index: 10;
`;

const TrashIconWrapper = styled.div`
  position: absolute;
  top: 5.5px;
  right: 5.5px;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: var(--light-bg);
  transition-duration: 0.05s;
  z-index: 10;

  :hover {
    background: red;

    ${TrashIconStyled} {
      fill: white !important;
    }
  }
`;

const Counter = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
  background: ${Theme.colors.title};
  color: white;
  border-radius: 4px;
  padding: 2px 6px;
  font-size: 10px;
  font-weight: bold;
`;

const DragWrapper = styled.div`
  cursor: pointer;
  svg {
    width: 10px;
    height: 10px;
  }
`;

const DraggableImage: React.FC<{
  image: AttachmentMedia;
  index: number;
  moveImage: (dragIndex: number, hoverIndex: number) => void;
  onRemoveImage: (index: number) => void;
}> = ({ image, index, moveImage, onRemoveImage }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [{ isOver }, drop] = useDrop({
    accept: "image",
    hover: (item: { index: number }) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      moveImage(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const [{ isDragging }, drag] = useDrag({
    type: "image",
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div className="flex flex-column align-items-center" ref={ref}>
      <UploadImage style={{ opacity: isDragging ? 0.5 : 1 }} isOver={isOver}>
        {image.metadata.type === "VIDEO" ? (
          <video
            src={image.url}
            style={{
              height: "100%",
              width: "100%",
              borderRadius: "8px",
              objectFit: "cover",
            }}
            muted
            autoPlay
            loop
          />
        ) : (
          <div
            style={{
              height: "100%",
              width: "100%",
              borderRadius: "8px",
              backgroundImage: `url(${image.url})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
        )}
        <TrashIconWrapper
          onClick={(e) => {
            e.stopPropagation();
            onRemoveImage(index);
          }}
        >
          <TrashIconStyled className="trash" />
        </TrashIconWrapper>
        <Counter>{index + 1}</Counter>
      </UploadImage>
      <DragWrapper>
        <DragIcon />
      </DragWrapper>
    </div>
  );
};

function MultyAttachmentInput<FormDataType extends FieldValues>({
  name,
  validate,
  videoOnly,
  onAddMedia,
}: {
  name: Path<FormDataType>;
  validate?: (value: AttachmentMedia[]) => boolean | string;
  videoOnly?: boolean;
  onAddMedia?: (media: AttachmentMedia) => void;
}) {
  const { control, trigger } = useFormContext();
  const [showAttachmentsModal, setShowAttachmentsModal] = useState(false);

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={{ validate: validate }}
        render={({ field: { onChange, value } }) => {
          const onAddImage = (media: AttachmentMedia) => {
            value ? onChange([...value, media]) : onChange([media]);
          };

          const onRemoveImage = (index: number) => {
            const updatedImages = value.filter(
              (_: string, i: number) => i !== index
            );

            onChange(updatedImages);
            trigger(name);
          };

          const moveImage = (dragIndex: number, hoverIndex: number) => {
            const updatedImages = [...value];
            const [movedImage] = updatedImages.splice(dragIndex, 1);
            updatedImages.splice(hoverIndex, 0, movedImage);
            onChange(updatedImages);
          };

          return (
            <>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {value &&
                  value.map((image: AttachmentMedia, index: number) => (
                    <DraggableImage
                      key={index}
                      image={image}
                      index={index}
                      moveImage={moveImage}
                      onRemoveImage={onRemoveImage}
                    />
                  ))}
                <UploadImage
                  uploadButton={true}
                  onClick={() => {
                    setShowAttachmentsModal(true);
                  }}
                >
                  <AddIcon />
                  <span>הוספת מדיה</span>
                </UploadImage>
              </div>

              {showAttachmentsModal && (
                <AttachmentsModal
                  onSelectAttachment={(media: AttachmentMedia) => {
                    onAddImage(media);
                    onAddMedia && onAddMedia(media);
                    setShowAttachmentsModal(false);
                    trigger(name);
                  }}
                  accept={
                    videoOnly
                      ? ".mp4,.mov"
                      : ".jpg,.jpeg,.png,.heic,.heif,.webp,.avif,.mp4,.mov"
                  }
                  onHide={() => setShowAttachmentsModal(false)}
                />
              )}
            </>
          );
        }}
      />
    </>
  );
}

export default MultyAttachmentInput;
