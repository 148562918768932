import { useRecoilValue } from "recoil";
import { allUsersState } from "../../state/userState";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import styled from "styled-components";
import Button from "../../components/common/form/Button";
import Link from "../../components/common/Link";
import AppConfig from "../../config/appConfig";
import { useCallback, useEffect, useState } from "react";
import userService from "../../core/services/user.service";
import InputStyle from "../../components/common/form/InputStyle";
import { InputText } from "primereact/inputtext";
import { User } from "../../core/entities/user";
import Card from "../../components/common/Card";
import { FormProvider, useForm } from "react-hook-form";
import Trend from "neword-core/dist/cjs/entities/trend/trendEntity";
import TextArea from "antd/es/input/TextArea";
import { toast } from "react-toastify";
import RadioButton from "../../components/common/form/RadioButton";
import RadioGroup from "../../components/common/form/RadioGroup";
import { PlatformType } from "neword-core/dist/cjs/types/platformType";

const DataGridStyled = styled(DataGrid)`
  background-color: white;
  border-radius: 13px;
  border: none !important;
`;

const Wrapper = styled.div`
  direction: ltr;
`;

const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const SuperAdminAllUsersPage: React.FC = () => {
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState<User[]>([]);

  const methods = useForm<Trend>();

  useEffect(() => {
    userService.getAllUsers(search).then((d) => setUsers(d));
  }, [search]);

  const generateSilentLoginLink = useCallback(async (id: string) => {
    const link = await userService.silentLoginLink(id);
    document.location.href = link;
  }, []);

  const onSubmitTrend = async () => {
    const values = methods.getValues();
    const trendToast = toast.loading("Creating Trend");
    try {
      await userService.createTrend(values);
      toast.success("Trend created");
    } catch (err) {
      toast.error("Error creating trend");
    } finally {
      toast.dismiss(trendToast);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "email",
      headerName: "Email",
      width: 200,
    },
    {
      field: "role",
      headerName: "Role",
      width: 200,
    },
    {
      field: "id",
      headerName: "Login as",
      renderCell: (params: any) => (
        <Link onClick={() => generateSilentLoginLink(params.id)}>Login</Link>
      ),
    },
  ];
  return (
    <Wrapper>
      <Card>
        <h1>Create Trend</h1>
        <FormProvider {...methods}>
          <FormStyled>
            <InputText
              {...methods.register("trendLink")}
              placeholder="Trend Link"
            />

            <InputText
              {...methods.register("soundLink")}
              placeholder="Sound Link"
            />

            <TextArea
              {...methods.register("description")}
              placeholder="Description"
            />

            <RadioGroup<Trend, Trend["platform"]>
              options={[
                { render: "TikTok", value: PlatformType.TIKTOK },
                { render: "Instagram", value: PlatformType.INSTAGRAM },
                { render: "Twitter", value: PlatformType.TWITTER },
                { render: "Facebook", value: PlatformType.FACEBOOK },
              ]}
              fieldName="platform"
            />

            <Button onClick={methods.handleSubmit(onSubmitTrend)}>
              Create Trend
            </Button>
          </FormStyled>
        </FormProvider>
      </Card>
      <br />
      <Card>
        <h1>Search Accounts</h1>
        <InputText
          placeholder="kaki@gmail.com"
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />
        <DataGridStyled
          sortModel={[
            {
              field: "email",
              sort: "asc",
            },
          ]}
          columns={columns}
          rows={users || []}
          scrollbarSize={10}
        />
      </Card>
    </Wrapper>
  );
};

export default SuperAdminAllUsersPage;

//https://www.tiktok.com/@efratelmalich/video/7454895764410567952?embed_source=121374463%2C121468991%2C121439635%2C121433650%2C121404359%2C121477481%2C121351166%2C121487028%2C73226838%2C121331973%2C120811592%2C120810756%2C121494655%3Bnull%3Bembed_card_play&refer=embed&referer_url=localhost%3A3000%2Fwebsites%2F3ae17fcb-1ca8-48c4-8fab-a994387f7f1b%2Fdashboard&referer_video_id=7443873804893359381
//https://www.tiktok.com/music/som-original-7416850013140617989
