import { Dialog } from "primereact/dialog";
import React from "react";
import ModalHeader from "./ModalHeader";
import styled from "styled-components";
import PlatformPostType from "../calendar/PlatformPostType";
import postType from "./types/postTypesMapping";
import PublishPostModal from "../post/PublishPostModal";
import { PostEntityType } from "neword-core";

interface Props {
  onHide: () => void;
  date?: Date;
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 70vh;
  overflow-y: auto;
  padding: 16px;
`;

const AddManualContent: React.FC<Props> = ({ onHide, date }) => {
  const [showPublishPostModal, setShowPublishPostModal] =
    React.useState<PostEntityType>();

  return (
    <Dialog
      header={<ModalHeader OnClose={onHide} />}
      closable={false}
      modal={true}
      visible
      position={"center"}
      style={{
        width: "70vw",
        margin: "0",
        boxShadow: "none",
        borderRight: "solid 1px var(--border-color)",
        borderRadius: "0px",
        maxHeight: "100%",
      }}
      onHide={onHide}
      draggable={false}
      resizable={false}
    >
      <ContentContainer>
        <h1>איזה סוג פוסט תרצו לפרסם</h1>
        <div className="grid">
          {postType.map((post, index) => (
            <div className="col-4">
              <PlatformPostType
                key={index}
                platform={post.platform}
                content={post.content}
                icon={post.icon}
                onClick={() => setShowPublishPostModal(post.type)}
              />
            </div>
          ))}
        </div>
      </ContentContainer>

      {showPublishPostModal && (
        <PublishPostModal
          postEntityType={showPublishPostModal}
          scheduleDate={date}
          onHide={() => onHide()}
        />
      )}
    </Dialog>
  );
};

export default AddManualContent;
