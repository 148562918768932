import { useFormContext, useWatch } from "react-hook-form";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import AudienceSelect from "../../common/form/AudienceSelect";
import { DocumentEntity, DocumentEntityType } from "neword-core";
import DocumentTone from "../components/DocumentTone";
import BlogPostOpinionDescription from "./formComponentes/BlogPostOpinionDescription";
import { AccordionProps } from "./types/AccordionProps";
import GenericDescriptionInput from "./formComponentes/GenericDescriptionInput";
import DocChipInput from "./formComponentes/DocChipInput";

export const searchAdAccordions: AccordionProps[] = [
  {
    title: "תיאור כללי לפרסום הממומן",
    content: (
      <div className="w-full step-2-wt">
        <GenericDescriptionInput<
          DocumentEntity & { type: DocumentEntityType.SEARCH_AD }
        >
          name="inputParams.productOrService"
          label="מוצר או שירות אותו תרצו לפרסם?"
          placeholder="איזה מוצר או שירות אתה מפרסם, ומהן התכונות או היתרונות העיקריים שלו?"
        />
        <DocChipInput<DocumentEntity & { type: DocumentEntityType.SEARCH_AD }>
          name="inputParams.keywords"
          label="מילות מפתח"
          placeholder="יש ללחוץ Enter על מנת להכניס את מילת מפתח"
        />
        <GenericDescriptionInput<
          DocumentEntity & { type: DocumentEntityType.SEARCH_AD }
        >
          name="inputParams.cta"
          label="קריאה לפעולה CTA ?"
          placeholder="לדוגמה ״הרשמה לניוזלטר שלנו״ או ״קניית  המוצר שלנו https://neword/product.ai”"
          inputStyle={{ height: 60 }}
        />
      </div>
    ),
    fieldNames: [
      "inputParams.cartItems",
      "inputParams.supportContact",
      "inputParams.incentives",
    ],
  },

  {
    title: "קהל יעד",
    content: (
      <AudienceSelect<
        DocumentEntity & { type: DocumentEntityType.SEARCH_AD }
      > fieldName="inputParams.audienceId" />
    ),
    fieldNames: ["inputParams.audienceId"],
  },
  {
    title: "סגנון כתיבה",
    content: (
      <>
        <DocumentTone<DocumentEntity & { type: DocumentEntityType.SEARCH_AD }>
          fieldName="inputParams.tone"
          threeColumn={true}
        />
      </>
    ),
    fieldNames: ["inputParams.tone"],
  },
];
