const ClosedPage = () => {
  window.close();
  return (
    <div>
      <h1>Sorry, this page is closed</h1>
    </div>
  );
};

export default ClosedPage;
