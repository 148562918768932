import React from "react";
import { AppIntegrationApplicationType } from "../../core/types/appIntegrationApplicationType";
import { ReactComponent as FacebookLogo } from "../../assets/Icons/facebook/FacebookLogo.svg";
import { ReactComponent as LinkedInLogo } from "../../assets/Icons/linkedin/LinkedInLogo.svg";
import { ReactComponent as XLogo } from "../../assets/Icons/XLogo.svg";
import { ReactComponent as InstagramLogo } from "../../assets/Icons/InstagramIcon.svg";
import { ReactComponent as TiktokLogo } from "../../assets/Icons/TiktokLogo.svg";
import { SocialIntegrationType } from "./types/socialIntegrationType";

import { ReactComponent as GoogleAnaliticsLogo } from "../assets/Icons/ColoredGoogle.svg";

import AnalyticsLogo from "../../assets/images/integrations/googleAnalytics-logo.png";

export const socialIntegrations: Partial<
  Record<AppIntegrationApplicationType, SocialIntegrationType>
> = {
  [AppIntegrationApplicationType.FACEBOOK]: {
    Icon: FacebookLogo,
    label: "פייסבוק",
    text: "חבר את חשבון הפייסבוק שלך כדי לאפשר פרסום פוסטים וניתוח נתונים ברשתות החברתיות.",
  },
  [AppIntegrationApplicationType.LINKEDIN]: {
    Icon: LinkedInLogo,
    label: "לינקדאין",
    text: "חבר את חשבון הלינקדאין שלך לצורך שיתוף תכנים ורישות מקצועי.",
  },
  [AppIntegrationApplicationType.X]: {
    Icon: XLogo,
    label: "טוויטר",
    text: "חבר את חשבון הטוויטר שלך כדי לתזמן ציוצים ולעקוב אחרי ביצועים.",
  },
  [AppIntegrationApplicationType.INSTAGRAM]: {
    Icon: InstagramLogo,
    label: "אינסטגרם",
    text: "חבר את חשבון האינסטגרם שלך לניהול פוסטים ותובנות בצורה יעילה.",
  },

  [AppIntegrationApplicationType.TIKTOK]: {
    Icon: TiktokLogo,
    label: "טיקטוק",
    text: "",
  },
};
