import { z } from "zod";

export const ZDocumentToneType = z.enum([
  "MARKETING",
  "SOLDABLE",
  "FRIENDLLY",
  "INSPIRED",
  "INSTRUCTIONAL",
  "CONVINCING",
  "HOMORISTIC",
  "TRENDY",
  "DRAMATIC",
  "FORMAL",
  "REGULAR",
  "PROVOCATIVE",
]);
export type DocumentToneType = z.infer<typeof ZDocumentToneType>;
