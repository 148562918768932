import { useFormContext, useWatch } from "react-hook-form";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import AudienceSelect from "../../common/form/AudienceSelect";
import { DocumentEntity, DocumentEntityType } from "neword-core";
import DocumentTone from "../components/DocumentTone";
import GenericDescriptionInput from "./formComponentes/GenericDescriptionInput";

const SmallLabel = styled.label`
  font-family: "Assistant";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 13.2px */
  letter-spacing: -0.36px;
`;

export const deafultAccordions = [
  {
    title: "תיאור התוכן",
    content: (
      <GenericDescriptionInput<DocumentEntity & { type: DocumentEntityType }>
        name="inputParams.description"
        label="תיאור התוכן"
        placeholder={`תתארו כאן במפורט מה תרצו שיהיה בתוכן שלכם!
    ככל שתתארו יותר טוב נוכל להבין ולהציע תוכן מתאים יותר`}
      />
    ),
  },
  {
    title: "קהל יעד",
    content: (
      <AudienceSelect<
        DocumentEntity & { type: DocumentEntityType.BLOG_POST_TIPS }
      > fieldName="inputParams.audienceId" />
    ),
  },
  {
    title: "סגנון כתיבה",
    content: (
      <>
        <DocumentTone<
          DocumentEntity & { type: DocumentEntityType.BLOG_POST_TIPS }
        >
          fieldName="inputParams.tone"
          threeColumn={true}
        />
      </>
    ),
  },
];
