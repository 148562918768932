import { Dictionary } from "lodash";
import InstagramCaptionBlock from "./blocks/InstagramCaptionBlock";
import FacebookPostCaptionBlock from "./blocks/FacebookPostCaptionBlock";
import { BlockProps } from "./blocks/types/dictionaryProps";
import { ForwardRefExoticComponent, RefAttributes } from "react";
import { RefProps } from "./blocks/types/refProps";
import LinkedInCaptionBlock from "./blocks/LinkedinCaptionBlock";
import InstagramReelIdeaBlock from "./blocks/InstagramReelIdeaBlock";
import InstagramStoryIdeaBlock from "./blocks/InstagramStoryIdeaBlock";
import InstagramPostIdeaBlock from "./blocks/InstagramPostIdeaBlock";
import ThreadsTwitBlock from "./blocks/ThreadsTwitBlock";
import EmailContentBlock from "./blocks/EmailContentBlock";
import SmsContentBlock from "./blocks/SmsContentBlock";
import XTwitBlock from "./blocks/XTwitBlock";
import WebsiteFaqBlock from "./blocks/WebsiteFaqBlock";
import WebsiteAboutBlock from "./blocks/WebsiteAboutBlock";
import WebsiteProductDescriptionBlock from "./blocks/WebsiteProductDescriptionBlock";
import WebsiteTestmonialBlock from "./blocks/WebsiteTestmonialBlock";
import FacebookPageAboutBlock from "./blocks/FacebookPageAboutBlock";
import FacebookPostIdeaBlock from "./blocks/FacebookPostIdeaBlock";
import InstagramBioDescriptionBlock from "./blocks/InstagramBioDescriptionBlock";

import GoogleAdsKeywordsBlock from "./blocks/GoogleAdsKeywordsBlock";
import LinkedinPageAboutBlock from "./blocks/LinkedinPageAboutBlock";
import LinkedInPostIdeaBlock from "./blocks/LinkedinPostIdeaBlock";

import { deafultAccordions } from "./forms/EmptyForm";

import { blogPostOpinionAccordions } from "./forms/BlogPostOpinionForm";
import { AccordionProps } from "./forms/types/AccordionProps";
import { DocumentEntity } from "neword-core";
import ArticleBlock from "./blocks/ArticleBlock";
import { blogPostTipsAccordions } from "./forms/BlogPostTipsForm";
import { blogPostComparisonAccordions } from "./forms/BlogPostComparisonForm";
import { blogPostCaseStudyAccordions } from "./forms/BlogPostCaseStudyForm";
import { cartAbandonmentMailAccordions } from "./forms/CartAbandonmentMailForm";
import { trafficEmailAccordions } from "./forms/TrafficEmailForm";
import { newsLetterEmailAccordions } from "./forms/NewsLetterEmailForm";
import { productUpdateEmailAccordions } from "./forms/ProductUpdateEmailForm";
import { emailAccordions } from "./forms/EmailForm";
import { welcomEmailAccordions } from "./forms/WelcomEmailForm";
import { flashSmsAccordions } from "./forms/FlashSmsForm";
import { notificationSmsAccordions } from "./forms/NotificationSmsForm";
import { smsAccordions } from "./forms/SmsForm";
import PushNotificationBlock from "./blocks/PushNotificationBlock";
import { pushNotificationAccordions } from "./forms/PushNotificationForm";
import { bioDescriptionAccordions } from "./forms/BioDescriptionForm";
import { postAccordions } from "./forms/PostForm";
import InstagramSponsoredAdBlock from "./blocks/InstagramSponsoredAdBlock";
import { instagramSponsoredAdAccordions } from "./forms/InstagramSponsoredAdForm";
import LinkedInSponsoredAdBlock from "./blocks/LinkedInSponsoredAdBlock";
import FacebookSponsoredAdBlock from "./blocks/FacebookSponsoredAdBlock";
import SearchAdBlock from "./blocks/SearchAdBlock";
import { searchAdAccordions } from "./forms/SearchAdForm";
import BlogPostSharedSettings from "./forms/advencedSettings/BlogPostSharedSettings";
import SmsSharedSettings from "./forms/advencedSettings/SmsSharedSettings";
import SocialSharedSettings from "./forms/advencedSettings/SocialSharedSettings";
import BioSharedSettings from "./forms/advencedSettings/BioSharedSettings";
import { MultiDocBlock } from "./blocks/MultiDocBlock";

interface DocumentsDictionaryProps {
  block: ForwardRefExoticComponent<
    Omit<BlockProps, "ref"> & RefAttributes<RefProps>
  >;
  form: AccordionProps[];
  columns?: number;
  disableBaseForm?: boolean;
  AdvencedSettings?: React.FC;
}
const documentsDictionary: Dictionary<DocumentsDictionaryProps> = {
  INSTAGRAM_CAPTION: {
    block: InstagramCaptionBlock,
    form: postAccordions,
    AdvencedSettings: SocialSharedSettings,
  },
  INSTAGRAM_REEL_IDEA: {
    block: InstagramReelIdeaBlock,
    form: deafultAccordions,
    AdvencedSettings: SocialSharedSettings,
  },
  INSTAGRAM_STORY_IDEA: {
    block: InstagramStoryIdeaBlock,
    form: deafultAccordions,
    AdvencedSettings: SocialSharedSettings,
  },
  INSTAGRAM_POST_IDEA: {
    block: InstagramPostIdeaBlock,
    form: deafultAccordions,
    AdvencedSettings: SocialSharedSettings,
  },
  INSTAGRAM_BIO_DESCRIPTION: {
    block: InstagramBioDescriptionBlock,
    form: bioDescriptionAccordions,
    AdvencedSettings: BioSharedSettings,
  },
  FACEBOOK_POST_CAPTION: {
    block: FacebookPostCaptionBlock,
    form: postAccordions,
    AdvencedSettings: SocialSharedSettings,
  },
  FACEBOOK_POST_IDEA: {
    block: FacebookPostIdeaBlock,
    form: deafultAccordions,
    AdvencedSettings: SocialSharedSettings,
  },
  FACEBOOK_PAGE_ABOUT: {
    block: FacebookPageAboutBlock,
    form: bioDescriptionAccordions,
    AdvencedSettings: BioSharedSettings,
  },
  LINKEDIN_POST_CAPTION: {
    block: LinkedInCaptionBlock,
    form: postAccordions,
    AdvencedSettings: SocialSharedSettings,
  },
  LINKEDIN_POST_IDEA: {
    block: LinkedInPostIdeaBlock,
    form: deafultAccordions,
    AdvencedSettings: SocialSharedSettings,
  },
  LINKEDIN_PAGE_ABOUT: {
    block: LinkedinPageAboutBlock,
    form: bioDescriptionAccordions,
    AdvencedSettings: BioSharedSettings,
  },
  THREADS_TWIT: {
    block: ThreadsTwitBlock,
    form: deafultAccordions,
    columns: 2,
    AdvencedSettings: SocialSharedSettings,
  },
  EMAIL_CONTENT: {
    block: EmailContentBlock,
    form: emailAccordions,
    columns: 2,
  },
  CART_ABANDONMENT_EMAIL: {
    block: EmailContentBlock,
    form: cartAbandonmentMailAccordions,
    columns: 2,
  },
  TRAFFIC_EMAIL: {
    block: EmailContentBlock,
    form: trafficEmailAccordions,
    columns: 2,
  },
  NEWSLETTER_EMAIL: {
    block: EmailContentBlock,
    form: newsLetterEmailAccordions,
    columns: 2,
  },
  PRODUCT_UPDATE_EMAIL: {
    block: EmailContentBlock,
    form: productUpdateEmailAccordions,
    columns: 2,
  },
  WELCOME_EMAIL: {
    block: EmailContentBlock,
    form: welcomEmailAccordions,
    columns: 2,
  },
  PUSH_NOTIFICATION: {
    block: PushNotificationBlock,
    form: pushNotificationAccordions,
  },
  SMS_CONTENT: {
    block: SmsContentBlock,
    form: smsAccordions,
    AdvencedSettings: SmsSharedSettings,
  },
  FLASH_SALE_SMS: {
    block: SmsContentBlock,
    form: flashSmsAccordions,
    AdvencedSettings: SmsSharedSettings,
  },
  NOTIFICATION_SMS: {
    block: SmsContentBlock,
    form: notificationSmsAccordions,
    AdvencedSettings: SmsSharedSettings,
  },

  TWITTER_TWIT: {
    block: XTwitBlock,
    form: deafultAccordions,
    columns: 2,
  },
  WEBSITE_FAQ: {
    block: WebsiteFaqBlock,
    form: deafultAccordions,
    columns: 2,
  },
  WEBSITE_ABOUT: {
    block: WebsiteAboutBlock,
    form: deafultAccordions,
    columns: 2,
  },
  WEBSITE_PRODUCT_DESCRIPTION: {
    block: WebsiteProductDescriptionBlock,
    form: deafultAccordions,
  },
  WEBSITE_TESTEMONIAL: {
    block: WebsiteTestmonialBlock,
    form: deafultAccordions,
  },
  GOOGLE_ADS_KEYWORDS: {
    block: GoogleAdsKeywordsBlock,
    form: deafultAccordions,
    columns: 2,
  },
  ARTICLE: {
    block: ArticleBlock,
    form: deafultAccordions,
    columns: 1,
    disableBaseForm: true,
    AdvencedSettings: BlogPostSharedSettings,
  },
  BLOG_POST_OPINION: {
    block: ArticleBlock,
    form: blogPostOpinionAccordions,
    columns: 1,
    AdvencedSettings: BlogPostSharedSettings,
  },
  BLOG_POST_TIPS: {
    block: ArticleBlock,
    form: blogPostTipsAccordions,
    columns: 1,
    AdvencedSettings: BlogPostSharedSettings,
  },
  BLOG_POST_GUIDE: {
    block: ArticleBlock,
    form: blogPostTipsAccordions,
    columns: 1,
    AdvencedSettings: BlogPostSharedSettings,
  },
  BLOG_POST_COMPARISON: {
    block: ArticleBlock,
    form: blogPostComparisonAccordions,
    columns: 1,
    AdvencedSettings: BlogPostSharedSettings,
  },
  BLOG_POST_CASE_STUDY: {
    block: ArticleBlock,
    form: blogPostCaseStudyAccordions,
    columns: 1,
    AdvencedSettings: BlogPostSharedSettings,
  },
  INSTAGRAM_SPONSORED_AD: {
    block: InstagramSponsoredAdBlock,
    form: instagramSponsoredAdAccordions,
  },
  LINKEDIN_SPONSORED_AD: {
    block: LinkedInSponsoredAdBlock,
    form: instagramSponsoredAdAccordions,
  },
  FACEBOOK_SPONSORED_AD: {
    block: FacebookSponsoredAdBlock,
    form: instagramSponsoredAdAccordions,
  },
  SEARCH_AD: {
    block: SearchAdBlock,
    form: searchAdAccordions,
    columns: 2,
  },
  GENERIC_CAMPAIGN: {
    block: MultiDocBlock,
    form: postAccordions,
    columns: 3,
    AdvencedSettings: SocialSharedSettings,
  },
};

export default documentsDictionary;
