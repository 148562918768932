import styled, { keyframes, css } from "styled-components";
import { ReactComponent as SpeaklesIcon } from "../../assets/Icons/Sparkles.svg";
import { Color } from "../../core/theme/types/color";
import { Theme } from "../../core/theme/theme";


interface Props {
  children: JSX.Element | string;
  icon?: JSX.Element;
  bgColor: Color;
  textColor?: Color;
  large?: boolean;
  onClick?: () => void;
  clickable?: boolean;
  hover?: boolean;
  round?: boolean;
  className?: string;
  /** NEW **/
  colorful?: boolean;
}


const BadgeStyle = styled.div<{
  bgColor: Color;
  textColor?: Color;
  large?: boolean;
  hover?: boolean;
  clickable?: boolean;
  round?: boolean;
  colorful?: boolean; // add here too
}>`
  display: flex;
  padding: ${(props) => (props.large ? "0.4rem 1rem;" : "0.25rem 0.75rem")};
  justify-content: center;
  align-items: center;
  gap: 0.3125rem;
  border-radius: ${(props) => (props.round ? "1.25rem" : "8px")};
  font-size: ${(props) => (props.large ? "0.80rem;" : "0.625rem")};
  ${(props) => (props.clickable ? "cursor:pointer" : "cursor:auto")};
  font-style: normal;
  color: ${(props) =>
    props.textColor ? Theme.colors[props.textColor] : "white"};
  font-weight: 400;
  letter-spacing: -0.01875rem;
  text-transform: capitalize;
  position: relative;
  border: solid 1px transparent;
  /** Fallback background (if NOT colorful) **/
  background: ${(props) => !props.colorful && Theme.colors[props.bgColor]};

  &:hover {
    border-color: ${(props) => (props.hover ? "var(--primary-purple);" : "transparent")};
  }

  /** Neon-like background if colorful is true **/
  ${(props) =>
    props.colorful &&
    css`
      background: linear-gradient(
        45deg,
        rgb(255, 0, 106),
        rgb(0, 183, 255),
rgb(76, 0, 255),
rgb(255, 0, 106),
rgb(0, 183, 255),
rgb(255, 230, 0)
      );
      background-size: 600% 600%;
      animation: ${neonAnimation} 12s ease infinite;
    `}

  svg {
    width: 10px;
    height: 10px;
    fill: ${(props) =>
      props.textColor ? Theme.colors[props.textColor] : "white"};
  }

  &:hover .kw-overly {
    opacity: 1;
  }

  &:hover .kw-overly svg path {
    opacity: 1;
  }
`;

const neonAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;


const Badge: React.FC<Props> = (props) => {
  const { icon, children } = props;
  return (
    <BadgeStyle {...props}>
      {icon || null}
      {children}
    </BadgeStyle>
  );
};

export default Badge;
