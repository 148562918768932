import { InputText } from "primereact/inputtext";
import { ReactComponent as GoogleIcon } from "../assets/Icons/ColoredGoogle.svg";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { userState } from "../state/userState";
import styled from "styled-components";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { LoginRequestData } from "../core/services/requests/login/loginRequestData";
import { Message } from "primereact/message";
import RegexValidations from "../core/validation/regexValidations";
import FormStyle from "../components/common/form/FormStyle";
import Button from "../components/common/form/Button";
import { ReactComponent as NewordTextLogo } from "../assets/Icons/NewordWhite.svg";
import { SignupRequestData } from "../core/services/requests/signup/signupRequestData";
import companyService from "../core/services/company.service";
import Link from "../components/common/Link";
import { Checkbox } from "primereact/checkbox";
import AppConfig from "../config/appConfig";
import ErrorMessage from "../components/common/ErrorMessage";
import AuthHeader from "../components/auth/AuthHeader";
import chillGuy from "../assets/images/chillguy.png";
import Testimonials from "../assets/images/testimonials.png";

const LoginPageWrapper = styled.div`
  margin-bottom: -200px !important;
  margin: auto;
  margin-top: 50px;
  border: solid 1px var(--primary-purple);
  width: 80%;
  background: white;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  border-radius: 12px;

  @media only screen and (max-width: 1000px) {
    .form-mobile {
      width: 80%;
    }

    label {
      font-size: 16px;
    }

    input {
      font-size: 22px;
      height: 100px;
      border-radius: 24px;
    }

    .google-button-mobile {
      height: 100px;
      border-radius: 24px;
      font-size: 18px;
    }

    .or-text-mobile {
      font-size: 20px;
      margin: 50px 0;
    }

    .gw-button {
      margin-top: 60px;
      margin-bottom: 60px;
      font-size: 24px;
      padding: 24px 26px;

      svg {
        width: 20px;
        height: 20px;
        margin-left: 20px;
      }
    }
    .small-text-mobile {
      font-size: 22px;
      a {
        font-size: 22px;
      }
    }

    .mobile-header {
      margin-bottom: 60px;

      svg {
        width: 300px;
        height: 60px;
      }
      h1 {
        width: 100%;
        font-size: 5rem;
      }
    }
  }
`;

const Title = styled.h1`
  color: var(--main-title-color, #0a2540);
  text-align: center;
  font-family: Inter;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 3rem */
  letter-spacing: -0.12rem;
  text-transform: capitalize;
  margin: 60px 0;
  width: 37.625rem;
  & span {
    color: var(--main-purple, #a960ee);
  }
`;

const ErrorMessageStyled = styled(Message)`
  display: block;
  width: fit-content;
  margin-bottom: 20px;
`;

const CenteredForm = styled(FormStyle)`
  h1 {
    font-weight: 800;
    font-size: 2rem;
    letter-spacing: -0.6px;
    color: var(--primary-purple);
  }
  h3 {
    font-weight: 500;
    color: var(--primary-purple);
  }
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 100%;
  padding: 60px;
  padding-bottom: 40px !important;
  justify-content: center;
  align-items: center;
`;

const ColoredLink = styled(Link)`
  color: var(--main-purple, #a960ee);
  font-family: Lato;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 0.75rem */
  letter-spacing: -0.0225rem;
  text-transform: capitalize;
`;

const RegularText = styled.div`
  color: var(--main-text-lightcolor, #9aa8b6);

  font-family: Lato;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 0.75rem */
  letter-spacing: -0.0225rem;
  text-transform: capitalize;
`;

const GoogleButton = styled(Link)`
  border-radius: 6px;
  border: 1px solid #1d58e3;
  display: flex;
  height: 3.125rem;
  width: 100%;
  padding: 1.25rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
  margin-bottom: 30px;
  color: #1d58e3;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 0.75rem */
  letter-spacing: -0.0225rem;
  text-transform: capitalize;
  cursor: pointer;
  &:hover {
    border-color: var(--title-color);
  }
  & svg {
    width: 16px;
  }
`;

const StyledCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
`;

const OrText = styled.div`
  color: var(--main-text-color, #425466);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 0.75rem */
  letter-spacing: -0.0225rem;
  text-transform: capitalize;
  margin: 20px 0;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 50%;
    height: 1px;
    background: var(--main-text-color, #425466);
    transform: translate(-50%, -50%);
    left: 140%;
    top: 50%;
    z-index: 0;
  }

  &:after {
    content: "";
    position: absolute;
    width: 50%;
    height: 1px;
    background: var(--main-text-color, #425466);
    transform: translate(-50%, -50%);
    left: -40%;
    top: 50%;
    z-index: 0;
  }
`;

const Bg = styled.div`
  h4 {
    color: white;
  }
  background: var(--primary-purple);
  height: 100%;
  padding: 40px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  .purple {
    /* color: var(--primary-purple); */
    color: #ffcb57;
  }

  span {
    color: white;
    font-size: 2.2rem;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 1.5rem */
    letter-spacing: -0.045rem;
    text-transform: capitalize;
  }
`;

const TestimonialBlock = styled.div`
  background: white;
  border-radius: 10px;
  padding: 40px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  h4 {
    font-size: 2.094rem;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 2.094rem */
    letter-spacing: -0.10469rem;
    color: var(--primary-purple);
  }

  svg path {
    fill: var(--primary-purple);
  }

  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 147%; /* 1.15431rem */
    letter-spacing: -0.02356rem;
  }
`;

const ImgWrapper = styled.div`
  margin-top: 100px;
  margin-bottom: -300px;
`;

const BackgroundWrapper = styled.div`
  background: var(--primary-purple);
`;

const TextWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 100px;
  color: white;

  h1 {
    font-size: 3.3rem;
    line-height: 100%;
  }

  .logoname {
    color: var(--Main-GraphicElements, #ffcb57);
    font-family: Poppins;
    font-style: normal;
    font-weight: 800;
    line-height: 110%;
    font-size: 3.3rem;
  }
  span {
    margin-top: 20px;
    font-size: 1.5rem;
    line-height: 100%;
  }
`;

const TopMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
  background-color: var(--primary-purple);

  svg {
    width: 100px;
  }
`;

const Signup: React.FC = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    control,
  } = useForm<SignupRequestData>();
  const setUserState = useSetRecoilState(userState);
  const [isLoading, setIsLoading] = useState(false);
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();
  const onSubmit: SubmitHandler<SignupRequestData> = async (data) => {
    try {
      setIsLoading(true);
      // const captcha = (document as any).grecaptcha as any;
      // const token = await captcha.enterprise.execute(
      //   "6Lf6qDUpAAAAAOqUoyZcgH4wkZJHtZqSlfhBpEsM",
      //   { action: "LOGIN" }
      // );

      const res = await companyService.signup({
        ...data,
      });

      console.log(res);
      setUserState(res);
      const redirectPath = queryParams.get("redirect") || "/";
      navigate(redirectPath);
    } catch (err) {
      console.log(err);
      setError("root", {});
    } finally {
      setIsLoading(false);
    }
  };

  const onClickGoogleButton = () => {
    const redirectPath = queryParams.get("redirect") || "/";
    document.location.href = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${
      AppConfig.serverUrl
    }/auth%2Fgoogle%2Fcallback&client_id=89013921262-762mm9l2lfq3dfv4rf185srjgq8ulihg.apps.googleusercontent.com&access_type=offline&response_type=code&prompt=select_account&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&state=${encodeURIComponent(
      redirectPath
    )}`;
  };

  return (
    <>
      <TopMenu>
        <NewordTextLogo />
        <Link path="/login">
          <Button>כניסה</Button>
        </Link>
      </TopMenu>
      <BackgroundWrapper className="flex">
        <div className="col-6">
          <TextWrapper>
            <img className="mb-4" src={Testimonials} width={150} alt="" />
            <h1>
              היי, אנחנו <span className="logoname">Neword.ai</span>
              <br /> מערכת שיווק מבוססת בינה מלאכותית
            </h1>
            <span>
              מערכת לשיווק באמצעות בינה מלאכותית, יצירת תוכן, סמסים שיווקיים,
              מאמרים מקדמים לאתר, אנליזות, קמפיינים ועוד..
            </span>
            <ImgWrapper>
              <img src={chillGuy} width={650} alt="" />
            </ImgWrapper>
          </TextWrapper>
        </div>
        <div className="col-6">
          <LoginPageWrapper>
            <div className="mobile-header"></div>
            <CenteredForm className="form-mobile" autoComplete="off">
              <h1>מתחילים כאן</h1>
              <h3 className="mb-5">ללא התחייבות וללא צורך בפרטי אשראי</h3>
              <GoogleButton
                onClick={onClickGoogleButton}
                className="google-button-mobile"
              >
                <GoogleIcon />
                הצטרפות מהירה עם גוגל
              </GoogleButton>
              <OrText className="or-text-mobile">
                או להירשם באמצעות דואר אלקטרוני
              </OrText>
              <div className="w-full mb-5">
                <div className="flex">
                  <div className="mb-5 ml-5 w-full">
                    <label>שם פרטי</label>

                    <InputText
                      {...register("firstName", {
                        required: true,
                        maxLength: 20,
                      })}
                      type="text"
                      placeholder="יהודה"
                      className={errors.firstName ? "p-invalid" : ""}
                    />
                  </div>
                  <div className="mb-5 w-full">
                    <label>שם משפחה</label>

                    <InputText
                      {...register("lastName", {
                        required: true,
                        maxLength: 20,
                      })}
                      placeholder="כהן"
                      className={errors.lastName ? "p-invalid" : ""}
                    />
                  </div>
                </div>

                <label>דואר אלקטרוני</label>
                <InputText
                  autoComplete="off"
                  {...register("email", {
                    required: true,
                    maxLength: 40,
                    pattern: {
                      value: RegexValidations.email,
                      message: "כתובת אימייל לא חוקית",
                    },
                  })}
                  type="text"
                  placeholder="Yehuda@Cohen.com"
                  className={errors.email ? "p-invalid" : ""}
                />
                <ErrorMessage>{errors.email?.message}</ErrorMessage>
              </div>
              <div className="w-full mb-5">
                <label>סיסמה</label>

                <InputText
                  autoComplete="off"
                  {...register("password", {
                    required: true,
                    maxLength: 20,
                    pattern: {
                      value: RegexValidations.password,
                      message:
                        "הסיסמה לא מספיק חזקה - אנא השתמש ב-8 תווים לפחות, שילוב של אותיות (אותיות גדולות וקטנות), מספרים וסמלים",
                    },
                  })}
                  type="password"
                  placeholder="J12345678w!"
                  className={errors.password ? "p-invalid" : ""}
                />
                <ErrorMessage>{errors.password?.message}</ErrorMessage>
              </div>
              <StyledCheckboxWrapper>
                <Controller
                  control={control}
                  name="signedForNewsletter"
                  render={({ field }) => (
                    <Checkbox checked={field.value} onChange={field.onChange} />
                  )}
                />
                <label>אני מסכים להצטרף לרשימת התפוצה של Neword</label>
              </StyledCheckboxWrapper>

              <Button
                onClick={handleSubmit(onSubmit)}
                loading={isLoading}
                primary
                arrowPlacement="right"
              >
                צור חשבון{" "}
              </Button>
              <small>בלחיצה על כפתור ההרשמה, אני מאשר את תנאי השימוש, מדיניות הפרטיות והתקנון.</small>
              <div className="flex gap-10 mt-4">
                <a href="https://he.neword.ai/privacy-policy-2/" target="_blank">privacy policy</a>
                <a href="https://he.neword.ai/terms-of-use-agreement/" target="_blank">terms of use</a>
                    
      </div>

              {/* <RegularText className="mb-4 mt-4 small-text-mobile">
                כבר יש לך חשבון? <ColoredLink path="/login">התחבר</ColoredLink>
              </RegularText> */}
              {/* <RegularText className="mb-4 text-center small-text-mobile">
                על ידי לחיצה על "צור חשבון" או "המשך עם גוגל", אתה מסכים{" "}
                <ColoredLink
                  path="https://ghostwrites.ai/legals/terms-of-use-agreement/"
                  differentTab
                >
                  לתנאי שימוש{" "}
                </ColoredLink>
                . של Neword{" "}
                <ColoredLink
                  differentTab
                  path="https://ghostwrites.ai/legals/privacy-policy/"
                >
                  {" "}
                  קרא את מדיניות הפרטיות שלנו{" "}
                </ColoredLink>
                .
              </RegularText> */}
            </CenteredForm>
          </LoginPageWrapper>
        </div>
      </BackgroundWrapper>

      
      
    </>
  );
};

export default Signup;
