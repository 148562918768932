import styled from "styled-components";
import { ReactComponent as BrandIcon } from "../../../assets/Icons/Brand.svg";
import { ReactComponent as AgencyIcon } from "../../../assets/Icons/Agency.svg";
import RadioGroup from "../../common/form/RadioGroup";
import { FieldValues, Path } from "react-hook-form";
import { CompanyType } from "../../../core/entities/companyType";

interface Props<T> {
  threeColumn?: boolean;
  fieldName: Path<T>;
}

const InsideRadio = styled.div`
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  position: relative;

  &:hover {
    .brandicon {
      transform: scale(1.1);
      transform: rotate(10deg);
    }

    .agencyicon {
      transform: rotate(10deg);
      transform: scale(1.1);
    }
  }
  .infoicon {
    width: 14px;
    height: 14px;
    right: -27px;
    top: 0;
    path {
      fill: #bdc7ca !important;
    }
  }
`;

const RadioWrapper = styled.div`
  position: relative;
  width: 100%;
  .singleradio {
    background: white;
  }

  span {
    color: var(--title-color);
  }

  .brandicon {
    width: 30px;
    height: 30px;
    transition-duration: 0.1s;
  }

  .agencyicon {
    height: 30px;
    width: 100%;
    transition-duration: 0.1s;
  }
`;

function SetupSelect<FormDataType extends FieldValues>(
  props: Props<FormDataType>
) {
  return (
    <RadioWrapper>
      <RadioGroup<FormDataType, CompanyType>
        ColumnNumber={"2"}
        fieldName={props.fieldName}
        options={[
          {
            value: CompanyType.BRAND,
            render: (
              <>
                <InsideRadio className="insideradio">
                  <BrandIcon className="brandicon" />
                  <span>מותג בודד</span>
                  <small>
                    אני מנהל את העסק שלי ורוצה למקסם את הפעילות השיווקית שלי
                    בעצמי.
                  </small>
                </InsideRadio>
              </>
            ),
          },
          {
            value: CompanyType.AGENCY,
            render: (
              <InsideRadio className="insideradio">
                <AgencyIcon className="agencyicon" />
                <span>מנהל/ת סושיאל</span>
                <small>
                  אני מנהל/ת מספר לקוחות במקביל ומתאימה את המערכת לצרכים של כל
                  אחד מהם.
                </small>
              </InsideRadio>
            ),
          },
        ]}
      ></RadioGroup>
    </RadioWrapper>
  );
}

export default SetupSelect;
