import styled from "styled-components";
import { ReactComponent as AddIcon } from "../../../assets/Icons/Plus.svg";
import { ReactComponent as AddImage } from "../../../assets/Icons/AddImage.svg";
import AttachmentsModal from "./AttachmentsModal";
import { useState } from "react";
import { FieldValues, Path, useController } from "react-hook-form";
import { ReactComponent as TrashIcon } from "../../../assets/Icons/Trash.svg";
import { Theme } from "../../../core/theme/theme";
import { isVideo } from "../../../common/utils/isVideo";

interface Props {
  bigImage?: boolean;
}

const UploadImage = styled.div<{ backgroundImage: string }>`
  position: relative;
  height: ${(props) => (props.backgroundImage ? "100px" : "auto")};
  width: ${(props) => (props.backgroundImage ? "100px" : "auto")};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-size: cover;
  background-position: center;
  transition-duration: 0.05s;

  svg {
    width: 10px;
    height: 10px;
    fill: var(--title-color);
  }
  span {
    font-size: 12px;
    font-weight: bold;
    color: var(--title-color);
  }
  &:hover {
    svg {
      fill: ${Theme.colors.purple};
    }
    span {
      color: ${Theme.colors.purple};
    }
  }
  &.p-invalid {
    border-color: red;
  }
`;

const TrashIconStyled = styled(TrashIcon)`
  width: 20px;
  height: 20px;
  cursor: pointer;
  fill: white;
  position: absolute;
  z-index: 10;
`;

const TrashIconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.84);
  cursor: pointer;
  z-index: 10;
  transition-duration: 0.2s;

  svg {
    width: 30px;
    height: 30px;
    fill: white;
  }
`;

const BigImageUpload = styled.div<{ hasImage: boolean }>`
  position: relative;
  height: 150px;
  width: 150px;
  border: ${(props) => (props.hasImage ? "none" : `1px dashed ${Theme.colors.text}`)};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${Theme.colors.bg};
  overflow: hidden;
  transition: all 0.2s;

  &:hover {
    border-color: ${(props) => (props.hasImage ? "none" : Theme.colors.purple)};
    background-color: ${Theme.colors.bg};
  }

  .add {
    width: 30px;
    height: 30px;
    fill: ${Theme.colors.text};
  }

  &:hover img {
    opacity: 0.5;
  }

  &:hover .add {
    fill: ${Theme.colors.purple};
  }

  &:hover ${TrashIconWrapper} {
    display: flex;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    transition: opacity 0.2s;
  }
`;


function AttachmentInput<FormDataType extends FieldValues>({
  name,
  validate,
  bigImage = false,
}: {
  name: Path<FormDataType>;
  validate?: () => boolean | string;
  bigImage?: boolean;
}) {
  const [showAttachmentsModal, setShowAttachmentsModal] = useState(false);

  const field = useController({
    name,
    rules: {
      validate: validate,
    },
  });

  return (
    <div className="flex">
      {bigImage ? (
        <BigImageUpload
        hasImage={!!field.field.value}
        onClick={() => setShowAttachmentsModal(true)}
      >
        {!field.field.value && <AddImage className="add" />}
        {field.field.value && (
          <>
            <img src={field.field.value} alt="Attachment Preview" />
            <TrashIconWrapper
              onClick={(e) => {
                e.stopPropagation(); // Prevent triggering the upload modal
                field.field.onChange(""); // Clear the image
              }}
            >
              <TrashIconStyled />
            </TrashIconWrapper>
          </>
        )}
      </BigImageUpload>
      
      ) : (
        <UploadImage
          backgroundImage={field.field.value || ""}
          onClick={() => setShowAttachmentsModal(true)}
          className={field.fieldState.error ? "p-invalid" : ""}
        >
          {!field.field.value && (
            <>
              <AddIcon />
              <span>הוספת תמונה</span>
            </>
          )}

          {field.field.value && isVideo(field.field.value) ? (
            <video
              src={field.field.value}
              controls
              style={{ width: "100px", height: "100px", borderRadius: "8px" }}
            />
          ) : field.field.value ? (
            <img
              src={field.field.value}
              alt="Attachment Preview"
              style={{ width: "100px", height: "100px", borderRadius: "8px" }}
            />
          ) : null}

          {field.field.value && (
            <TrashIconWrapper
              onClick={(e) => {
                e.stopPropagation(); // Prevent the modal from opening
                field.field.onChange("");
              }}
            >
              <TrashIconStyled className="trash" />
            </TrashIconWrapper>
          )}
        </UploadImage>
      )}
      {showAttachmentsModal && (
        <AttachmentsModal
          onSelectAttachment={(img) => {
            field.field.onChange(img.url);
            setShowAttachmentsModal(false);
          }}
          hideVideos
          accept=".png,.jpeg,.jpg,.webp"
          onHide={() => setShowAttachmentsModal(false)}
        />
      )}
    </div>
  );
}

export default AttachmentInput;
