import { AttachmentMedia } from "neword-core";

export default (value: AttachmentMedia[]) => {
  const videos = value.filter((item) => item.metadata.type === "VIDEO");
  const images = value.filter((item) => item.metadata.type === "IMAGE");
  let errorText = "";

  // Basic validation for video and images
  if (videos.length > 1) {
    errorText = "מותר להעלות וידאו אחד בלבד";
  }
  if (images.length > 50) {
    errorText = "מותר להעלות עד 50 תמונות בלבד";
  }
  if (videos.length > 0 && images.length > 0) {
    errorText = "לא ניתן להעלות גם תמונה וגם וידאו";
  }

  // Validation for Facebook video metadata
  if (videos.length === 1) {
    const video = videos[0].metadata;

    // Narrow type to the video-specific type
    if (video.type === "VIDEO") {
      // Aspect Ratio: Validate supported Facebook aspect ratios
      // const allowedAspectRatios = ["16:9", "4:5", "1:1", "9:16"];
      // if (!allowedAspectRatios.includes(video.aspectRatio)) {
      //   errorText =
      //     "היחס רוחב-גובה של הווידאו חייב להיות 16:9, 4:5, 1:1 או 9:16";
      // }

      // // Resolution: Minimum resolution validation
      // const [width, height] = video.resolution
      //   .split("x")
      //   .map((dim) => parseInt(dim.trim(), 10));

      // if (isNaN(width) || isNaN(height) || width < 600 || height < 315) {
      //   errorText = "הרזולוציה של הווידאו חייבת להיות לפחות 600x315 פיקסלים";
      // }

      // // Frame Rate: Maximum 30 FPS
      // if (video.frameRate > 30) {
      //   errorText = "קצב הפריימים של הווידאו לא יכול להיות גבוה מ-30 FPS";
      // }

      // Duration: Between 1 second and 240 minutes
      if (video.duration < 1 || video.duration > 14400) {
        errorText = "אורך הווידאו חייב להיות בין שנייה אחת ל-240 דקות";
      }
    }
  }

  return errorText;
};
