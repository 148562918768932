import { ServiceBase } from "./service.base";
import { Language } from "../types/language";
import { TriggerWorkerRequestData } from "./requests/worker/triggerWorkerRequestData";

class GeneratorService extends ServiceBase {
  prefix = "/generator";

  generateSuggestedKeywords(workerId: string) {
    return this.get<string[]>(`/${workerId}/keywords`);
  }

  generateKeywordsByWebsiteUrl(description: string, language: Language) {
    return this.get<string[]>(
      `/keywords?description=${description}&language=${language}`
    );
  }

  generateTopics(
    data: Omit<
      TriggerWorkerRequestData,
      "title" | "publishAsDraft" | "disablePublish"
    >
  ) {
    return this.post<string[]>("/topics", data);
  }

  async streamGptRes(prompt: string, content: string, evenId: string) {
    const res = await this.fetch(`/regenerate/`, {
      method: "POST",
      body: JSON.stringify({
        content,
        prompt,
      }),
    });

    const decoder = new TextDecoder();
    const reader = res?.body?.getReader() as any;

    while (true) {
      const { value, done } = await reader.read();
      const decodedChunk = decoder.decode(value, { stream: true });

      const event = new CustomEvent(`stream-${evenId}`, {
        detail: decodedChunk,
      });

      document.dispatchEvent(event);

      if (done) {
        const event = new CustomEvent(`end-stream-${evenId}`);
        document.dispatchEvent(event);
        break;
      }
    }
  }

  generateImage(prompt: string) {
    return this.post<string>("/generate-image", { prompt });
  }
}
const generatorService = new GeneratorService();
export default generatorService;
