import styled from "styled-components";
import Card from "../../../common/Card";
import { ContentCardIcons } from "./iconMappings";
import { ContentCardType } from "./cardTypes";
import { useMemo } from "react";
import { Theme } from "../../../../core/theme/theme";

type iconColor = "green" | "purple";

interface Props {
  title: string;
  image: string;
  description: string;
  type: ContentCardType; // Add 'type' property with ContentCardType type
  highlightTitleTerm?: string;
  horizontal?: boolean;
}

const CardStyled = styled(Card)<Props>`
  position: relative;
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--input-border-color, #e6e6e6);
  flex-direction: ${({ horizontal }) => (horizontal ? "row" : "column")};
  padding: 12px;
  cursor: pointer;
  min-height: ${({ horizontal }) => (horizontal ? "0" : "280px")};;

  &:hover {
    border: 1px solid var(--title-color, #e6e6e6);
  }
`;

const ImageWrapper = styled.div<Props>`
  width: ${({ horizontal }) => (horizontal ? "40%" : "100%")};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 13px;
  margin-bottom: ${({ horizontal }) => (horizontal ? "0" : "12px")};
  margin-left: ${({ horizontal }) => (horizontal ? "12px" : "0")};

  img {
    width: 100%;
    object-fit: cover;
  }
`;

const TextWrapper = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: ${({ horizontal }) => (horizontal ? "60%" : "100%")};
`;


const BoxTitle = styled.span<Props>`
  color: var(--title-color, #0a2540);

  leading-trim: both;
  text-edge: cap;
  font-size: ${({ horizontal }) => (horizontal ? "14px" : "16px")};
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 1rem */
  letter-spacing: -0.03rem;
`;

const BoxDesc = styled.span<Props>`
  color: var(--title-color, #0a2540);
  text-align: right;
  leading-trim: both;
  text-edge: cap;
  font-size: ${({ horizontal }) => (horizontal ? "12px" : "14px")};;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 0.875rem */
  letter-spacing: -0.02625rem;
`;

const IconWrapper = styled.div`
  background-color: white;
  border-radius: 6px;
  padding: 6px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: end;
  justify-content: end;
  position: absolute;
  top: 20px;
  right: 20px;

  svg {
    height: 15px;
    width: 100%;
  }
`;

const ContentCard: React.FC<Props> = (props) => {
  const title = useMemo(
    () =>
      props.highlightTitleTerm
        ? props.title.replace(
            new RegExp(props.highlightTitleTerm, "gi"),
            (match) =>
              `<span style='background-color:${Theme.colors.purpleOpacity};'>${match}</span>`
          )
        : props.title,
    [props.title, props.highlightTitleTerm]
  );

  const titleHtml = useMemo(
    () => ({
      __html: title,
    }),
    [title]
  );

  const IconComponent = ContentCardIcons[props.type]?.icon;
  return (
    <>
      <CardStyled {...props}>
          <ImageWrapper {...props}>
            <img src={props.image} alt="icon" />
          </ImageWrapper>

          <TextWrapper {...props}>
            <BoxTitle {...props} dangerouslySetInnerHTML={titleHtml}></BoxTitle>
            <BoxDesc {...props}>{props.description}</BoxDesc>
          </TextWrapper>
        <IconWrapper>
          {IconComponent && <IconComponent />}
        </IconWrapper>
      </CardStyled>
    </>
  );
};

export default ContentCard;
