import React from "react";
import { AppIntegrationApplicationType } from "../core/types/appIntegrationApplicationType";
import { ReactComponent as FacebookLogo } from "../assets/Icons/facebook/FacebookLogo.svg";
import { ReactComponent as LinkedInLogo } from "../assets/Icons/linkedin/LinkedInLogo.svg";
import { ReactComponent as XLogo } from "../assets/Icons/XLogo.svg";
import { ReactComponent as InstagramLogo } from "../assets/Icons/InstagramIcon.svg";
import { ReactComponent as GmailLogo } from "../assets/Icons/Gmail.svg";
import { ReactComponent as TiktokLogo } from "../assets/Icons/TiktokLogo.svg";

import { ReactComponent as GoogleAnaliticsLogo } from "../assets/Icons/ColoredGoogle.svg";

import AnalyticsLogo from "../../assets/images/integrations/googleAnalytics-logo.png";

interface IntegrationFactoryType {
  icon: React.ComponentType<any>;
  label: string;
  text: string;
}
export const integrationFactory: Partial<
  Record<AppIntegrationApplicationType, IntegrationFactoryType>
> = {
  [AppIntegrationApplicationType.EMAIL]: {
    icon: GmailLogo,
    label: "מייל",
    text: "קבל התראות בכל פעם שנוצר מאמר    .",
  },
  // [AppIntegrationApplicationType.FACEBOOK]: {
  //   icon: FacebookLogo,
  //   label: "פייסבוק",
  //   text: "חבר את חשבון הפייסבוק שלך כדי לאפשר פרסום פוסטים וניתוח נתונים ברשתות החברתיות.",
  // },
  // [AppIntegrationApplicationType.LINKEDIN]: {
  //   icon: LinkedInLogo,
  //   label: "לינקדאין",
  //   text: "חבר את חשבון הלינקדאין שלך לצורך שיתוף תכנים ורישות מקצועי.",
  // },
  // [AppIntegrationApplicationType.X]: {
  //   icon: XLogo,
  //   label: "טוויטר",
  //   text: "חבר את חשבון הטוויטר שלך כדי לתזמן ציוצים ולעקוב אחרי ביצועים.",
  // },
  // [AppIntegrationApplicationType.INSTAGRAM]: {
  //   icon: InstagramLogo,
  //   label: "אינסטגרם",
  //   text: "חבר את חשבון האינסטגרם שלך לניהול פוסטים ותובנות בצורה יעילה.",
  // },
  [AppIntegrationApplicationType.GOOGLEANALYTICS]: {
    icon: GoogleAnaliticsLogo,
    label: "Google Analytics (4)",
    text: "קבל מידע עסקי על המאמרים שפורסמו ",
  },
  // [AppIntegrationApplicationType.TIKTOK]: {
  //   icon: TiktokLogo,
  //   label: "טיקטוק",
  //   text: "",
  // },
};
