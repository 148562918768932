import React, { useState } from "react";
import { InstagramEmbed, TikTokEmbed } from "react-social-media-embed";
import styled from "styled-components";
import { ReactComponent as InstagramIcon } from "../../assets/Icons/InstagramIcon.svg";
import { ReactComponent as TiktokIcon } from "../../assets/Icons/TiktokLogo.svg";
import { ReactComponent as CalendarIcon } from "../../assets/Icons/WebsiteIcons/Schedule.svg";
import { ReactComponent as ContentIcon } from "../../assets/Icons/WebsiteIcons/Content.svg";
import { ReactComponent as LightBulb } from "../../assets/Icons/Lightbulb.svg";
import { ReactComponent as WandIcon } from "../../assets/Icons/Wand.svg";
import { ReactComponent as SupportIcon } from "../../assets/Icons/Support.svg";
import { ReactComponent as TrendIcon } from "../../assets/Icons/Trend.svg";
import { ReactComponent as SoundIcon } from "../../assets/Icons/Sound.svg";
import { ReactComponent as WritingIcon } from "../../assets/Icons/Edit.svg";
import Card from "../../components/common/Card";
import { useRecoilValue } from "recoil";
import { userState } from "../../state/userState";
import welcomeBG from "../../assets/images/welcomeCardBg.png";
import { websitesStateSelector, websiteState } from "../../state/websitesState";
import Button from "../../components/common/form/Button";
import CardTitle from "../../components/common/CardTitle";
import Badge from "../../components/common/Badge";
import Link from "../../components/common/Link";
import { audienceState } from "../../state/audienceState";
import { useParams } from "react-router";
import { Calendar } from "primereact/calendar";
import { Nullable } from "primereact/ts-helpers";
import EditableTextArea from "../../components/document/components/EditableTextArea";
import { Divider } from "primereact/divider";
import TodoList from "../../components/TodoList/TodoList";
import { dashboardState } from "../../state/dashboardState";
import { PlatformType } from "neword-core/dist/cjs/types/platformType";
import Trend from "neword-core/dist/cjs/entities/trend/trendEntity";

const FilterWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
`;
const IconWrapper = styled.div<{ active?: boolean }>`
  border-radius: 8px;
  padding: 14px 18px;
  cursor: pointer;
  color: ${(props) => (props.active ? "var(--primary-purple)" : "black")};
  gap: 10px;
  background-color: ${(props) => (props.active ? "white" : "white")};
  display: flex;
  svg {
    margin-bottom: -5px;
    path {
      fill: ${(props) =>
        props.active ? "var(--primary-purple)" : "black"} !important;
    }
  }
  &:hover {
    background-color: ${(props) =>
      props.active ? "white" : "var(--light-bg)"};
  }
`;

const ICWapper = styled(Button)<{ support?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  svg {
    fill: ${(props) => (props.support ? "var(--primary-purple)" : "black")};
  }

  color: ${(props) => (props.support ? "var(--primary-purple)" : "black")};

  &:hover {
    background-color: var(--light-bg);
  }

  border-radius: 8px;
  padding: 14px;
`;

const QuickAcess = styled(Card)`
  cursor: pointer;
  border: solid 1px var(--border-color);
  padding: 10px 25px;
`;

const CardStyle = styled.div`
  /* background-blend-mode: soft-light; */
  padding: 2rem 3rem;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  color: black;

  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  /* text-align: center; */

  h1 {
    font-family: "Assistant";
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.045rem;
    text-transform: capitalize;
    font-weight: bold;
  }

  h2 {
    position: relative;
    font-family: "Assistant";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 1rem */
    letter-spacing: -0.02rem;
    text-transform: capitalize;
  }
`;

const LogoWrapper = styled.div`
  margin-left: 20px;
  img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }
`;

const InstagramTextWrapper = styled.div`
  display: flex;
  padding: 20px;
  align-items: center;
  gap: 20px;
  border-radius: 13.051px;
  background: var(--Card-Background, #f6f9fc);

  svg {
    width: 30px;
    height: 30px;
  }
`;
const PurpelText = styled.div`
  color: #6300c1;
  text-align: right;
  font-family: "Noto Sans Hebrew";
  font-size: 12.211px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 15.875px */
  letter-spacing: -0.366px;
`;

const CustomEmbed: React.FC<{ trend: Trend }> = ({ trend }) => {
  const renderEmbed = () => {
    if (!trend.trendLink) {
      return <p>Loading...</p>; // Show loading until the link is available
    }

    switch (trend.platform) {
      case PlatformType.TIKTOK:
        return <TikTokEmbed url={trend.trendLink} width={325} />;
      case PlatformType.INSTAGRAM:
        return <InstagramEmbed url={trend.trendLink} width={328} />;
      default:
        return <p>Unsupported platform</p>;
    }
  };

  return (
    <div className={"col-6"}>
      <Card className="bg-white">
        <div className="flex justify-content-center mb-3">
          <Link href={trend.soundLink}>
            <ICWapper icon={<SoundIcon />} className="flex">
              סאונד
            </ICWapper>
          </Link>
          <ICWapper icon={<TrendIcon />} className="flex">
            הסבר על הטרנד
          </ICWapper>
        </div>
        {renderEmbed()}
      </Card>
    </div>
  );
};

const Dashboard: React.FC = () => {
  const websitesSelector = useRecoilValue(websitesStateSelector);
  const { websiteId } = useParams(); // Get websiteId from the URL
  const website = useRecoilValue(websiteState(websiteId as string));
  const trends = useRecoilValue(dashboardState(websiteId as string));

  const [date, setDate] = useState<Nullable<Date>>(null);
  const audiences = useRecoilValue(audienceState(websiteId as string)); // Fetch audience data

  const [selectedFilter, setSelectedFilter] = React.useState<PlatformType>(
    PlatformType.INSTAGRAM
  );

  return (
    <>
      <div className="grid">
        <div className="col-8">
          <CardStyle>
            <div className="flex gap-10">
              <LogoWrapper>
                <img src={welcomeBG} alt="" />
              </LogoWrapper>
              <div>
                <h1>{website ? website.name || website.domain : "שלום לך!"}</h1>
                <h2>
                  אנחנו כאן לכל דבר, בתקווה לימים שקטים ובטוחים יותר במהרה.
                </h2>
              </div>
            </div>
          </CardStyle>
        </div>
        <div className="col-4"></div>
      </div>

      <div className="grid">
        <div className="col-12">
          <QuickAcess className="flex gap-10 align-items-center justify-content-between">
            <div className="flex gap-10">
              <Link path="/calendar">
                <ICWapper icon={<WandIcon />} arrowPlacement="right">
                  <span>צור תוכן עם AI</span>
                </ICWapper>
              </Link>
              <Link path="/calendar">
                <ICWapper icon={<CalendarIcon />} arrowPlacement="right">
                  <span>ארגונית תוכן</span>
                </ICWapper>
              </Link>
              <Link path="/">
                <ICWapper icon={<ContentIcon />} arrowPlacement="right">
                  <span>תבניות תוכן</span>
                </ICWapper>
              </Link>
            </div>
            <Link path="/">
              <ICWapper icon={<SupportIcon />} support>
                <span>תמיכה טכנית</span>
              </ICWapper>
            </Link>
          </QuickAcess>
        </div>
        <div className="col-12">
          <div className="grid">
            <div className="col-6">
              <Card>
                <FilterWrapper>
                  <div className="flex">
                    <IconWrapper
                      onClick={() => setSelectedFilter(PlatformType.INSTAGRAM)}
                      active={selectedFilter === PlatformType.INSTAGRAM}
                    >
                      <InstagramIcon />
                      טרנדים באינסטגרם
                    </IconWrapper>
                    <IconWrapper
                      onClick={() => setSelectedFilter(PlatformType.TIKTOK)}
                      active={selectedFilter === PlatformType.TIKTOK}
                    >
                      <TiktokIcon />
                      טרנדים בטיקטוק
                    </IconWrapper>
                  </div>
                  <ICWapper arrowPlacement="right">
                    <span>כל הטרנדים</span>
                  </ICWapper>
                </FilterWrapper>

                <div className="grid">
                  <>
                    {trends
                      .filter((t) => t.platform === selectedFilter)
                      .map((trend) => {
                        console.log(trend);
                        return <CustomEmbed trend={trend} />;
                      })}
                  </>
                </div>
              </Card>
            </div>

            <div className="col-6">
              <div className="grid">
                <div className="col-6">
                  <Card>
                    <CardTitle title="רעיונות לתוכן"></CardTitle>

                    <div className="flex gap-10 flex-column">
                      <InstagramTextWrapper className="">
                        <LightBulb />
                        <div className="w-full">
                          <PurpelText>רעיון לפוסט באינסטגרם:</PurpelText>
                          <span>5 טיפים ליצירת תוכן מנצח!</span>
                        </div>
                        <div className="flex"></div>
                      </InstagramTextWrapper>
                      <InstagramTextWrapper className="">
                        <LightBulb />
                        <div className="w-full">
                          <PurpelText>רעיון לסרטון טיקטוק:</PurpelText>
                          <span>לפני ואחרי של לקוח </span>
                        </div>
                        <div className="flex"></div>
                      </InstagramTextWrapper>
                    </div>
                  </Card>
                </div>
                <div className="col-6">
                  <Card>
                    <CardTitle title="מה מתוכנן לעלות היום?"></CardTitle>
                    <Card className="p-3">
                      <div className="flex align-items-center gap-10">
                        <img
                          src={welcomeBG}
                          className="ml-3 "
                          alt=""
                          width={50}
                          height={50}
                        />
                        <div className="flex flex-column ml-3">
                          <Badge bgColor="success">אושר</Badge>
                          <strong>17:30</strong>
                        </div>
                        <span>
                          {`חברים, מזל טוב! אתם יודעים איך זה כשמגיע רגע של השראה ואז הכל נתקע? לא עוד! עם הפלטפורמה של Anyword, כל אחד יכול להפוך לרוק סטאר של הכתיבה. למה להתאמץ כשאפשר פשוט לשבת וליהנות מהקסם? 😊`.substring(
                            0,
                            60
                          ) + "..."}
                        </span>
                      </div>
                    </Card>
                  </Card>
                </div>
              </div>
              <div className="col-6">
                <Card>
                  <CardTitle title="המשימות שלי"></CardTitle>
                  <TodoList></TodoList>
                </Card>
              </div>
            </div>
          </div>
        </div>

        <div className="col-6"></div>
      </div>
    </>
  );
};

export default Dashboard;
