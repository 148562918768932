import { Avatar } from "primereact/avatar";
import { AvatarGroup } from "primereact/avatargroup"; //Optional for grouping
import { useRecoilValue } from "recoil";

import styled from "styled-components";
import React, { useMemo, useRef, useState, useEffect } from "react";
//import { useRouter } from 'next/router';
import { Menu } from "primereact/menu";
import { useNavigate, useParams } from "react-router";
import { Button } from "primereact/button";

import { ReactComponent as GlobeIcon } from "../../../assets/Icons/Globe.svg";
import { ReactComponent as HelpIcon } from "../../../assets/Icons/Help.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/Icons/Download.svg";
import { ReactComponent as NewordTextLogo } from "../../../assets/Logo/ColoredIcon.svg";

import { ReactComponent as Edit } from "../../../assets/Icons/Edit.svg";
import { Tooltip } from "primereact/tooltip";
import { Divider } from "primereact/divider";
import GWButton from "../../common/form/Button";

import userService from "../../../core/services/user.service";
import { announcementState } from "../../../state/announcementState";
import useNavigator from "../../../hooks/useNavigator";
import Announcement from "../../common/Announcement";
import UpgradePackage from "../../modals/UpgradePackage";
import IconButton from "../../common/IconButton";
import { userState } from "../../../state/userState";
import { PackageType } from "../../../core/types/packageType";
import { documentState } from "../../../state/documentState";
import DocBoxes from "./DocBoxes";
import { Controller, useFormContext } from "react-hook-form";
import { DocumentEntity } from "neword-core";

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-inline: 10px;
  align-items: center;
  background: white;
  border-radius: 0px;
  min-height: 55px;
  border-bottom: 1px solid #e2e8f0;
`;

const AvatarStyled = styled(Avatar)`
  background-color: var(--yellow);
`;

const IconStyle = styled.i`
  font-size: 12px;
  transition-duration: 0.1s;
  padding-right: 8px;
`;

const ButtonAvatarStyled = styled(Button)`
  font-size: 14px;
  padding: 0;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  color: black !important;

  &:hover {
    ${IconStyle} {
      margin-top: 7px !important;
    }
  }
`;

const EmailText = styled.span`
  font-size: 14px;
  letter-spacing: -0.3px;
`;

const RoleText = styled.span`
  margin-top: 2px;
  font-size: 10px;
  letter-spacing: -0.3px;
`;

const LogoWrapper = styled.div`
  margin-left:20px;
  svg{
    width: 20px;
    height: 20px;
    }
`;

const PurpelText = styled.a`
  color: var(--main-hebrew, #6300c1);
  font-family: "Noto Sans Hebrew";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: -0.549px;
`;

const NameInput = styled.input`
  border: none;
  background: transparent;
  outline: none;
  color: var(--Main-TitleColor, #0a2540);
  font-family: "Noto Sans Hebrew";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: -0.549px;
`;
const NameSpan = styled.span`
  color: var(--Main-TitleColor, #0a2540);
  font-family: "Noto Sans Hebrew";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: -0.549px;
`;

const HiddenSpan = styled.span`
  visibility: hidden;
  position: absolute;
  white-space: pre;
  font-size: 16px; /* Match the TextStyle font size */
  font-weight: 500; /* Match the TextStyle font weight */
`;

const ButtonStyled = styled(GWButton)`
  padding-right: 0px;
  padding-left: 8px;
`;
const EditStyled = styled(Edit)`
  cursor: pointer;
`;
const DocumentTopMenu: React.FC = () => {
  const { documentId } = useParams<{ documentId: string }>();
  const documentEntity = useRecoilValue(documentState(documentId as string));
  const user = useRecoilValue(userState);

  const { control } = useFormContext<DocumentEntity>();
  const [isNameEditing, setIsNameEditing] = useState(false);
  const [inputWidth, setInputWidth] = useState<string>("auto");
  const navigate = useNavigator();
  const menuLeft = useRef<Menu>(null);
  const [upgradePackageModal, setUpgradePackageModal] = useState(false);
  const nameRef = useRef<HTMLSpanElement>(null);
  const hiddenSpanRef = useRef<HTMLSpanElement>(null);

  const isTrial = useMemo(
    () => user.company.Subscription.type === PackageType.TRIAL,
    [user.company.Subscription.type]
  );

  let items = [
    {
      template: () => (
        <button
          onClick={(e) => navigate("/")}
          className="w-full p-link flex align-items-center p-3"
        >
          <div className="flex flex-column">
            <EmailText>{user?.email}</EmailText>
            <RoleText>{user?.role}</RoleText>
          </div>
          <AvatarStyled
            label={user?.email.at(0)?.toLocaleUpperCase()}
            shape="circle"
            className="mr-2"
          />
        </button>
      ),
    },
    { separator: true },
    {
      label: "הגדרות משתמש",
      command: () => {
        navigate(`/user-preferences/account-settings`);
      },
    },
    {
      label: "מסלולים וחיובים",
      command: () => {
        navigate(`/user-preferences/subscription-manage`);
      },
    },
    {
      template: () => <Divider className="m-0" />,
    },
    {
      label: "התנתק",
      command: async () => {
        await userService.logout();
        document.location.href = "/login";
      },
    },
  ];

  const announcementStateValue = useRecoilValue(announcementState);

  const handleBlur = () => {
    setIsNameEditing(false);
  };
  const handleEditClick = () => {
    if (nameRef.current) {
      setInputWidth(`${nameRef.current.offsetWidth}px`);
    }
    setIsNameEditing(true);
  };

  useEffect(() => {
    if (hiddenSpanRef.current) {
      setInputWidth(`${hiddenSpanRef.current.offsetWidth + 5}px`);
    }
  }, [documentEntity.name]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    onChange: (...event: any[]) => void
  ) => {
    onChange(e);
    if (hiddenSpanRef.current) {
      hiddenSpanRef.current.textContent = e.target.value;
      setInputWidth(`${hiddenSpanRef.current.offsetWidth + 2}px`);
    }
  };

  return (
    <>
      <TopWrapper>
        <div className="flex align-items-center">
          <ButtonStyled
            arrowPlacement="left"
            onClick={() => navigate("", true, true)}
          />
          <LogoWrapper
            onClick={() => navigate("/")}
            className="flex justify-content-enter"
          >
            <NewordTextLogo />
          </LogoWrapper>
          <PurpelText>
            {DocBoxes.find((doc) => doc.type == documentEntity.type)?.title +
              " |"}
          </PurpelText>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <>
                {isNameEditing ? (
                  <NameInput
                    style={{ width: inputWidth }}
                    value={field.value}
                    onBlur={() => {
                      field.onBlur();
                      handleBlur();
                    }}
                    onChange={(e) => handleInputChange(e, field.onChange)}
                    autoFocus={isNameEditing}
                  />
                ) : (
                  <NameSpan ref={nameRef}>
                    {field.value === "" ? "שם המסמך" : field.value}
                  </NameSpan>
                )}
                <HiddenSpan ref={hiddenSpanRef}>{field.value}</HiddenSpan>
              </>
            )}
          />
          <EditStyled className="mr-1" onClick={handleEditClick} />
        </div>
        <div>
          <div className="flex align-items-center">
            {/* {isTrial ? (
              <GWButton
                onClick={() => setUpgradePackageModal(true)}
                arrowPlacement="right"
                textColor="pink"
              >
                שדרג עכשיו
              </GWButton>
            ) : (
              <></>
            )} */}

            <Tooltip target=".teams-icon" />
            <IconButton
              icon={<HelpIcon />}
              width="17"
              className="teams-icon"
              path="/team"
              data-pr-tooltip="עזרה"
              data-pr-position="bottom"
            />
            
          </div>
        </div>
      </TopWrapper>
      {announcementStateValue && (
        <Announcement>
          <div className="flex align-items-center">
            {announcementStateValue}
          </div>
        </Announcement>
      )}
      {upgradePackageModal && (
        <React.Suspense>
          <UpgradePackage onHide={() => setUpgradePackageModal(false)} />
        </React.Suspense>
      )}
    </>
  );
};

export default DocumentTopMenu;
