import Calendar from "react-calendar";
import styled from "styled-components";
import { Theme } from "../../core/theme/theme";

export const CreateDocument = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  padding: 10px 15px;
  border-radius: 8px;
  border: dashed 1px var(--border-color);
  opacity: 0;
  cursor: pointer;

  gap: 10px;
  svg {
    width: 15px;
    height: 15px;
  }

  &:hover {
    border-color: var(--primary-purple);
    color: var(--primary-purple);
  }
`;

export const CalendarStyled = styled(Calendar)`
  width: 100%;
  border: none;
  font-family: "Assistant";
  border-radius:  0 0 12px 12px;
  background-color: white;
  border-top:none !important;
  border: solid 1px var(--border-color);

  button.react-calendar__tile {
    overflow: visible !important;
  }

  
  .react-calendar__month-view__weekdays__weekday{
    border-bottom: solid 1px var(--border-color) !important;
  }

  .react-calendar__tile {
    min-height: 150px;
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
    font: inherit;
    font-size: 0.833em;
    border: none;
        border-right: solid 1px var(--border-color);
    border-bottom: solid 1px var(--border-color);
    display: flex;
    font-family: "Assistant";
    flex-direction: column;

    &:hover {
      ${CreateDocument} {
        opacity: 1;
      }
    }

    abbr {
      text-decoration: none;
      float: right;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      font-family: "Assistant";
    }
  }

  .react-calendar__navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    
    .react-calendar__navigation__arrow{

    padding: 5px 10px;

    &:hover{
    background-color: var(--light-bg);}
    }
  }

  .react-calendar__navigation__label {
    background: none;
    border: none;
    flex-grow: 0 !important;
    padding: 0 20px;
    pointer-events: none;
    font-weight: bold;
    text-transform: uppercase;
    font-family: "Open Sans", "open sans Hebrew";
  }

  .react-calendar__navigation__arrow {
    background: none;
    border: none;
    font-size: 16px;
    padding: 0 10px;
    font-weight: bold;
    cursor: pointer;
  }

  .react-calendar__month-view__weekdays__weekday {
    /* background: var(--light-bg); */
    display: flex;
    justify-content: center;
    text-decoration: none !important;
    padding: 10px;
    border-bottom: ${Theme.colors.border};
    margin-top: 10px;
    abbr {
      text-decoration: none;
      text-transform: uppercase;
      font-family: "Open Sans", "open sans Hebrew";
      font-size: 12px;
      color: var(--text-color);
    }
  }

  .past-date {
    background-color: var(--light-bg); /* Styling for past dates */
  }

  abbr + div {
    width: 100%;
    height: 100%;
  }
  .react-calendar__tile--now abbr {
    background-color: var(--purple-opacity);
    color: var(--primary-purple);
    font-family: "Assistant";
    border: solid 1px var(--primary-purple);
  }

  .react-calendar__month-view__days{
    overflow-y: scroll;
    height: calc(100vh - 251px);
  }
`;
