import Trend from "neword-core/dist/cjs/entities/trend/trendEntity";
import { ArticleAnalyticsValue } from "../../components/website/analytics/types/articleAnalyticsValue";
import { WebsiteDashboardData } from "../entities/websiteDashboardData";
import { ServiceBase } from "./service.base";

class AnalyticsService extends ServiceBase {
  prefix = "/analytics";

  getWebsiteDashboardData(websiteId: string) {
    return this.get<Trend[]>(`/${websiteId}/dashboard`);
  }

  getReportByPages(websiteId: string, daysAgo: string) {
    return this.post<ArticleAnalyticsValue[]>(`/${websiteId}/report`, {
      daysAgo,
    });
  }
}

const analyticsService = new AnalyticsService();
export default analyticsService;
