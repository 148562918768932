import React from "react";
import styled from "styled-components";

// Update Props to accept a React component for the icon
interface Props {
  platform: string;
  content: string;
  icon: React.ComponentType<any>; // Accept React component as a prop
  onClick: () => void;
}

const PostContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  background-color: #f9f9f9;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const IconContainer = styled.div`
  font-size: 24px; /* Adjust icon size */
  color: var(--primary-color); /* Add custom color if needed */
`;

const PostContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const PlatformPost: React.FC<Props> = ({
  platform,
  content,
  icon: Icon,
  onClick,
}) => {
  return (
    <PostContainer onClick={onClick}>
      <IconContainer>
        <Icon /> {/* Render the icon as a component */}
      </IconContainer>
      <PostContent>
        <strong>{platform}</strong>
        <p>{content}</p>
      </PostContent>
    </PostContainer>
  );
};

export default PlatformPost;
