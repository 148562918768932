import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import FormStyle from "../../../common/form/FormStyle";
import styled from "styled-components";
import { useFormContext } from "react-hook-form";
import { CreateWebsiteRequestData } from "../../../../core/services/requests/createWebsite/createWebsiteRequestData";
import RegexValidations from "../../../../core/validation/regexValidations";
import AttachmentInput from "../../../common/form/AttachmentInput";

const InnerText = styled.div`
  position: absolute;
  top: 0%;
  padding-left: 20px;
  padding-right: 20px;
  color: rgb(10, 37, 64);
  font-size: 12px;
  font-weight: bold;
  height: 50px;
  display: flex;
  align-items: center;

  border-radius: 6px 0 0 6px;
  letter-spacing: -0.1px;
`;

const TextInsideInput = styled.div`
  margin-top: 10px;
  position: relative;
  width: 100%;
  /* width: calc(100% - 171px); */

  & input {
    margin-top: 0;
    padding-left: 65px;
  }
`;

const FormStyleStyled = styled(FormStyle)`
  max-width: 600px;
`;

interface BusinessType {
  label: string;
  value: string;
}

interface BusinessCategory {
  category: string;
  types: BusinessType[];
}

const BusinessDetails: React.FC = () => {
  const [filteredBusinessTypes, setFilteredBusinessTypes] = useState<
    BusinessCategory[]
  >([]);

  const { register, formState } = useFormContext<CreateWebsiteRequestData>();

  const { errors, isLoading } = formState;
  const [isLoadingCrawler, setIsLoading] = useState(false);

  return (
    <>
      <FormStyleStyled>
        <div className="grid">
          <div className="col-12 flex flex-column align-items-center">
            <label htmlFor="username" className="mb-3">
              לוגו העסק
            </label>
            <AttachmentInput<CreateWebsiteRequestData>
              bigImage={true}
              name="website.logoSrc"
            ></AttachmentInput>
          </div>
          <div className="col-12">
            <label htmlFor="username">שם העסק</label>
            <InputText
              {...register("website.name", {
                required: {
                  message: "שם העסק שדה חובה",
                  value: true,
                },
              })}
              placeholder="איך קוראים לעסק של הלקוח שלך?"
            ></InputText>
          </div>
          <div className="col-12">
            <label htmlFor="username">אתר העסק (אופציונלי)</label>
            <TextInsideInput
              dir="ltr"
              className={errors.website?.url ? "p-invalid" : ""}
            >
              <InnerText>https://</InnerText>
              <InputText
                disabled={isLoading}
                {...register("website.url", {
                  setValueAs: (value: string) => {
                    const formatted = value
                      .replace("https://", "")
                      .replace("http://", "");
                    return `https://${formatted}`;
                  },
                  pattern: {
                    value: RegexValidations.url,
                    message: "כתובת האתר אינה חוקית",
                  },
                })}
                placeholder="yourwebsite.com"
                className={
                  "w-full unmask-clarity" +
                  (errors.website?.url ? "p-invalid" : "")
                }
              />
            </TextInsideInput>
            <small className="mt-2">
              כתובת האתר תאפשר לנו להכיר את העסק טוב יותר
            </small>
          </div>
        </div>
      </FormStyleStyled>
    </>
  );
};

export default BusinessDetails;
