import { Divider } from "primereact/divider";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { validateUserAccess } from "../../middleware/validateUserAccess";
import { userState } from "../../state/userState";
import MenuItem from "../menu/MenuItem";
import { MenuItemProps } from "../menu/types/MenuItemProps";
import { useCallback } from "react";
import useNavigator from "../../hooks/useNavigator";
import Badge from "../common/Badge";

interface Props {
  items: MenuItemProps[];
  prefix?: string;
  isSubMenu?: boolean;
  shrink?: boolean;
}

const Wrapper = styled.div<{ isSubMenu?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${(props) => (props.isSubMenu ? "1.2rem" : "10px")};
  background: white;
  z-index: 5;
`;

const Title = styled.div`
  font-size: 12px;
  letter-spacing: -0.01rem;
  font-weight: 700;
`;

const BadgeStyled = styled(Badge)`
  pointer-events: none;
      padding: 3px 10px;
    letter-spacing: 0px;
    font-family: 'lato';
`;

const TabsNav: React.FC<Props> = ({ items, prefix, isSubMenu, shrink }) => {
  const navigate = useNavigator();
  const { pathname } = useLocation();
  const user = useRecoilValue(userState)!!;
  const routePrefix = prefix || "";

  const isRouteActive = useCallback(
    (route: string, parentRoute?: string) => {
      if (parentRoute) {
        return pathname.startsWith(routePrefix + parentRoute);
      }
      return (
        pathname.endsWith(routePrefix + route) ||
        pathname.endsWith(routePrefix + route + "/")
      );
    },
    [pathname, routePrefix]
  );

  return (
    <Wrapper isSubMenu={isSubMenu}>
      {items
        .filter((item) => validateUserAccess(user.role, item.role))
        .map((menuItem, index) => {
          if (menuItem.isTitle) {
            return (
              <>
              {!shrink ? <Title>{menuItem.displayName}</Title> : <Title style={{textAlign:"center"}}>{menuItem.displayName}</Title>}
                
              </>
            );
          } else if (menuItem.isDivider) {
            return <Divider className="m-0" key={index} />;
          } else {
            return (
              <MenuItem
                shrink={shrink}
                isSubMenuItem={isSubMenu}
                key={menuItem.route}
                onClick={() => navigate(routePrefix + menuItem.route, true)}
                isActive={isRouteActive(menuItem.route, menuItem.parentRoute)}
                className=""
                isBold={menuItem.bold}
              >
                <>
                  {menuItem.icon}
                  <span className="">{shrink ? "" : menuItem.displayName}</span>
                </>
              {menuItem.pro && <BadgeStyled colorful bgColor="purple">pro</BadgeStyled>}
              </MenuItem>
            );
          }
        })}
    </Wrapper>
  );
};

export default TabsNav;
