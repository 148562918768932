import React from 'react';
import { Item, Text, DeleteButton } from './styles';

interface TodoItemProps {
  todo: {
    id: number;
    text: string;
    completed: boolean;
  };
  toggleComplete: (id: number) => void;
  deleteTodo: (id: number) => void;
}

const TodoItem: React.FC<TodoItemProps> = ({ todo, toggleComplete, deleteTodo }) => {
  return (
    <Item>
      <Text
        completed={todo.completed}
        onClick={() => toggleComplete(todo.id)}
      >
        {todo.text}
      </Text>
      <DeleteButton onClick={() => deleteTodo(todo.id)}>X</DeleteButton>
    </Item>
  );
};

export default TodoItem;
