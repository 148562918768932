import React, { ReactElement, useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router";
import { DocumentEntityType, DocumentEntity } from "neword-core";
import { documentLoadingState, documentState } from "../../state/documentState";
import { useRecoilState, useRecoilValue } from "recoil";
import Masonry from "@mui/lab/Masonry";

import { useFieldArray, useFormContext } from "react-hook-form";
import { BlockWrapper } from "./components/BlockWrapper";
import documentsDictionary from "./documentsDictionary";
import Card from "../common/Card";
import BlockLoading from "../BlockLoading";

const Wrapper = styled.div`
  padding: 10px;
  background-color: #f6f9fc;
  overflow-y: auto;
  display: flex;
  flex-direction: row-reverse;
  flex: 1;
`;

const BlockSection: React.FC = () => {
  const { documentId } = useParams<{
    documentId: string;
  }>();
  const document = useRecoilValue(documentState(documentId as string));

  const [isLoading, setIsLoading] = useRecoilState(documentLoadingState);

  const {
    control,
    formState: { errors },
  } = useFormContext<DocumentEntity>();

  const { fields, append, remove, update } = useFieldArray<DocumentEntity>({
    control,
    name: "output",
  });

  const onDelet = (blockindex: number) => {
    remove(blockindex);
  };

  const columns = useMemo(
    () => documentsDictionary[document.type].columns,
    [document.type]
  );

  return (
    <Wrapper className="col-8">
      {isLoading ? (
        <BlockLoading columns={columns ?? 3} />
      ) : (
        <Masonry columns={columns || 3}>
          {fields.map((block, index) => (
            <BlockWrapper
              update={update}
              key={block.id}
              index={index}
              onDelet={onDelet}
            />
          ))}
        </Masonry>
      )}
    </Wrapper>
  );
};

export default BlockSection;
