import { Divider } from "primereact/divider";
import { InputTextarea } from "primereact/inputtextarea";
import {
  FieldErrors,
  FieldValues,
  Path,
  useFormContext,
  useWatch,
} from "react-hook-form";
import styled from "styled-components";
import AttachmentInput from "../../common/form/AttachmentInput";
import IgPreview from "../postPreviews/IgPreview";
import { useRef, useEffect, useMemo, useState } from "react";
import CardTitle from "../../common/CardTitle";
import TextEditor from "../../common/publishPostModal/TextEditor";
import LocationDropdown from "../../common/publishPostModal/LocationDropdown";
import MultyAttachmentInput from "../../common/form/MultyAttachmentInput";
import Card from "../../common/Card";
import CircleBg from "../../../assets/images/bgcirclepurple.png";
import { Message } from "primereact/message";

const LabelStyled = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: var(--text-color);
  padding-bottom: 10px;
`;

const ImageWrapper = styled.div`
  background-size: cover;
  position: relative;
  background-color: var(--light-bg);
  margin-left: -1px;
  margin-top: -1px;
  &::before {
    content: "";
    position: absolute;
    top: 0x;
    left: 0px;
    width: 100%;
    height: 100%;
    background-image: url(${CircleBg});
    background-size: cover;
    opacity: 0.11; /* Adjust the opacity as needed */
    z-index: 1;
  }

  & > * {
    position: relative;
    z-index: 2;
  }
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
  }
`;

function InstagramReelPostForm<FormDataType extends FieldValues>({
  name,
  index,
}: {
  name: Path<FormDataType>;
  index?: number;
}) {
  const {
    getValues,
    control,
    formState: { errors },
  } = useFormContext();

  const { posts = [] } = useWatch({ control }); // Ensuring that posts has a default value

  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [imageUrlError, setImageUrlError] = useState("");

  const autoResizeTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    autoResizeTextarea(); // Initial adjustment on mount or when post content changes
  }, [posts]);

  return (
    <div className="grid m-0 h-full">
      <div className="col-7 pl-4 pr-4 pt-4">
          <CardTitle title="פרסום רילס באינסטגרם" />
                  <Divider />

        <div className="post p-2 mb-4">
          <Card>
          <LabelStyled>תוכן רילס</LabelStyled>
          <TextEditor name={name} />
          <Divider />
          <LabelStyled>הוספת מדיה</LabelStyled>
          {/* <AttachmentInput<any> name={`${name}.imageUrl`} /> */}
          <MultyAttachmentInput
            name={`${name}.imageUrls`}
            videoOnly
            validate={(value) => {
              console.log("validate", value);

              const videos = value.filter(
                (item) => item.metadata.type === "VIDEO"
              );
              const images = value.filter(
                (item) => item.metadata.type === "IMAGE"
              );
              let errorText = "";

              if (videos.length !== 1) {
                errorText = "חובה לבחור וידאו אחד ";
              }
              if (images.length > 0) {
                errorText = "ניתן לבחור רק סרטון";
              }

              setImageUrlError(errorText);
              if (errorText === "") {
                return true;
              }

              return errorText;
            }}
          />
                    </Card>
                            <div className="media p-2 mt-0 ">
                              
                              {imageUrlError != "" && <Message severity="warn" text={imageUrlError} />}
                             
                    
                            </div>
          {/* <LabelStyled className="mt-2">הוסף מיקום</LabelStyled>
          <LocationDropdown name={`${name}.location`} /> */}
        </div>
      </div>
      <div className="col-5 p-0">
        <ImageWrapper className="p-4">
          <IgPreview reel={true} name={name} />
        </ImageWrapper>
      </div>
      
    </div>
  );
}

export default InstagramReelPostForm;
