import { Dialog } from "primereact/dialog";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useRecoilState } from "recoil";
import { audienceState } from "../../../state/audienceState";
import { FormProvider, useController, useForm } from "react-hook-form";
import { CreateWebsiteRequestData } from "../../../core/services/requests/createWebsite/createWebsiteRequestData";
import { InputText } from "primereact/inputtext";
import FormStyle from "./FormStyle";
import { ReactComponent as RefreshIcon } from "../../../assets/Icons/refresh.svg";
import RangeSlider from "./RangeSlider";
import Avatar, { genConfig } from "react-nice-avatar";
import { Dropdown } from "primereact/dropdown";
import { GenderType } from "../../../core/types/genderType";
import SelectKeywordsInput from "../../keywords/SelectKeywordsInput";
import Button from "./Button";
import writerService from "../../../core/services/writer.service";
import { Audience } from "../../../core/entities/audience";
import { toneState } from "../../../state/toneState";
import { WebsiteTone } from "../../../core/entities/tone";
import { InputTextarea } from "primereact/inputtextarea";

const Title = styled.h2`
  color: var(--Main-TitleColor, #0a2540);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 1rem */
  letter-spacing: -0.03rem;
  margin-top: 10px;
`;

const Subtitle = styled.h3`
  margin-top: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 0.875rem */
  letter-spacing: -0.02625rem;
`;

const UploadImage = styled.div`
  margin-top: 10px;
  width: 128px;
  height: 148px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed var(--border-color);
  flex-direction: column;
  gap: 10px;
  transition-duration: 0.05s;
  cursor: pointer;

  svg {
    width: 40px;
    height: 40px;
    fill: var(--border-color);
  }

  span {
    font-weight: bold;
    color: var(--border-color);
  }

  &:hover {
    border: 2px dashed var(--primary-color);
    svg {
      fill: var(--primary-color);
    }

    span {
      color: var(--primary-color);
    }
  }
`;

const AvatarHairThick = styled(Avatar)`
  svg:nth-child(2) {
    path {
    }
    left: 0;
  }

  svg:nth-child(4) {
    path {
    }
    left: 0;
  }
`;

const RefreshImage = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 1.25rem */
  letter-spacing: -0.03125rem;
  color: var(--primary-purple);

  svg {
    transition-duration: 0.2s;
  }
  &:hover {
    svg {
      transform: rotate(90deg);
    }
  }
`;

const AvatarContainer = styled.div`
  margin: 10px 0;
  display: flex;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  align-items: center;
  gap: 20px;
`;

const DialogStyled = styled(Dialog)`
  max-width: 70%;
`;

interface Props {
  onHide: () => void;
  toneId: string;
}

const UpdateToneModal: React.FC<Props> = ({ onHide, toneId }) => {
  const { websiteId } = useParams();
  const [tones, setTones] = useRecoilState(toneState(websiteId as string));

  const currentTone = tones.find((tone) => tone.id === toneId);

  const methods = useForm<WebsiteTone>({
    values: currentTone,
  });

  const onSubmit = async (data: WebsiteTone) => {
    await writerService.updateTone(toneId as string, data, websiteId as string);

    setTones([...tones.filter((a) => a.id !== toneId), data]);
    onHide();
  };

  return (
    <DialogStyled
      header={
        <>
          <Title>עדכן סגנון כתיבה</Title>
          <Subtitle>עדכן סגנון כתיבה מותאם לעסק שלך</Subtitle>
        </>
      }
      visible
      onHide={onHide}
      modal
      footer={
        <>
          <Button
            data-pr-position="top"
            primary
            disabled={!methods.formState.isValid}
            onClick={methods.handleSubmit(onSubmit)}
            loading={methods.formState.isLoading}
          >
            עדכן קהל
          </Button>
        </>
      }
    >
      <FormProvider {...methods}>
        <FormStyle>
          <div className="grid">
            <div className="col-6">
              <label htmlFor="username">שם הסגנון</label>
              <InputText
                {...methods.register("name")}
                placeholder="איך סגנון הכתיבה שלכם נקרא?"
              ></InputText>
            </div>

            <div className="col-12">
              <label htmlFor="username"></label>
              <InputTextarea
                {...methods.register("toneText")}
                placeholder="הכנס טקסט בטון הרצוי"
              />
            </div>
          </div>
        </FormStyle>
      </FormProvider>
    </DialogStyled>
  );
};

export default UpdateToneModal;
