import { ReactComponent as WandIcon } from "../../assets/Icons/Wand.svg";
import { ReactComponent as AddBorderIcon } from "../../assets/Icons/PlusBorder.svg";
import React, { useState } from "react";
import PublishPostModal from "../post/PublishPostModal";
import { CreateDocument } from "./calendarStyled";
import CreateDocModal from "../common/form/CreateDocModal";
import Preloader from "../common/Preloader";
import { PostEntityType } from "neword-core";
import AddManualContent from "../modals/AddManualContent";

function EmptyTile({ date }: { date: Date }) {
  const [showPublishPostModal, setShowPublishPostModal] = useState(false);
  const [showCreateDocModal, setShowCreateDocModal] = useState(false);

  return (
    <div className="flex gap-10 flex-column w-full">
      <CreateDocument onClick={() => setShowCreateDocModal(true)}>
        <WandIcon />
        <span>צור תוכן עם AI</span>
      </CreateDocument>
      <CreateDocument onClick={() => setShowPublishPostModal(true)}>
        <AddBorderIcon />
        <span>צור תוכן ידני</span>
      </CreateDocument>
      {showPublishPostModal && (
        <AddManualContent
          date={date}
          onHide={() => setShowPublishPostModal(false)}
        />
      )}

      {showCreateDocModal && (
        <CreateDocModal
          dateParam={date}
          onHide={() => setShowCreateDocModal(false)}
        />
      )}
    </div>
  );
}
export default EmptyTile;
