import React, { useEffect, useState } from "react";
import FormStyle from "../../../common/form/FormStyle";
import { InputTextarea } from "primereact/inputtextarea";
import styled from "styled-components";
import { Controller, useFormContext } from "react-hook-form";
import Button from "../../../common/form/Button";
import RephraseButton from "../../../common/form/RephraseButton";
import { AutoComplete } from "primereact/autocomplete";
import Card from "../../../common/Card";
import Badge from "../../../common/Badge";
import { scanWebsiteState } from "../../../../state/websitesState";
import { useRecoilValue } from "recoil";
import { CreateWebsiteRequestData } from "../../../../core/services/requests/createWebsite/createWebsiteRequestData";
import { Language } from "../../../../core/types/language";

const FormStyleStyled = styled(FormStyle)`
  width: 600px;
  margin-top: 60px;
`;

const BadgeStyled = styled(Badge)`
  position: absolute;
  top: -17px;
  right: 40px;
`;

const CardStyled = styled(Card)`
  position: relative;
`;

const businessCategories: BusinessCategory[] = [
  {
    category: "קמעונאות",
    types: [
      {
        label: "חנות קמעונאית - חנות בגדים, חנות צעצועים.",
        value: "retail_clothing_toys",
      },
      {
        label: "חנות מקוונת - אתר מכירות בגדים אונליין, חנות ספרים דיגיטליים.",
        value: "online_store",
      },
      {
        label: "עסק למכירת רכבים - סוכנות רכבים, ליסינג תפעולי.",
        value: "car_sales",
      },
    ],
  },
  {
    category: "שירותים אישיים",
    types: [
      {
        label: "נותן שירות - מספרה, קוסמטיקאית, יועץ עסקי.",
        value: "service_provider",
      },
      {
        label: "קליניקה רפואית - מרפאת שיניים, קליניקת פיזיותרפיה.",
        value: "medical_clinic",
      },
      {
        label: "עסק לתיקונים ושיפוצים - חשמלאי, אינסטלטור.",
        value: "repairs_renovations",
      },
    ],
  },
  {
    category: "מזון ומשקאות",
    types: [
      {
        label: "מסעדה/בית קפה - מסעדת אוכל מהיר, בית קפה מקומי.",
        value: "restaurant_cafe",
      },
    ],
  },
  {
    category: "לוגיסטיקה והובלה",
    types: [
      {
        label: "עסק לוגיסטיקה והובלה - חברת הובלות, שירותי שליחויות.",
        value: "logistics_business",
      },
    ],
  },
  {
    category: "טכנולוגיה וחדשנות",
    types: [
      {
        label:
          "סטארטאפ טכנולוגי - חברת פיתוח אפליקציות, פלטפורמת ניתוח נתונים.",
        value: "tech_startup",
      },
    ],
  },
  {
    category: "חינוך והדרכה",
    types: [
      {
        label: "מוסד חינוכי - בית ספר פרטי, מכון לימודים.",
        value: "educational_institution",
      },
    ],
  },
  {
    category: "פיננסים וייעוץ",
    types: [
      {
        label: "תאגיד פיננסי - בנק, חברת השקעות.",
        value: "financial_corporation",
      },
      {
        label: "חברה לייעוץ עסקי - יועץ שיווקי, יועץ ארגוני.",
        value: "business_consulting",
      },
    ],
  },
  {
    category: 'בנייה ופיתוח נדל"ן',
    types: [
      {
        label: 'חברת בנייה - קבלן בניין, חברת פיתוח פרויקטים נדל"ניים.',
        value: "construction_company",
      },
    ],
  },
  {
    category: "בידור ואירועים",
    types: [
      {
        label: "חברת בידור ואירועים - חברת הפקות אירועים, סוכנות אומנים.",
        value: "event_company",
      },
    ],
  },
  {
    category: "פרסום ושיווק",
    types: [
      {
        label: "חברת פרסום ושיווק - משרד פרסום, סוכנות דיגיטל.",
        value: "ad_agency",
      },
    ],
  },
  {
    category: "אינפלואנסר",
    types: [{ label: "אינפלואנסר", value: "influencer" }],
  },
];

interface BusinessType {
  label: string;
  value: string;
}

interface BusinessCategory {
  category: string;
  types: BusinessType[];
}

const BusinessExtraDetails: React.FC = () => {
  const methods = useFormContext<CreateWebsiteRequestData>();
  const scanData = useRecoilValue(
    scanWebsiteState(methods.getValues("website.url"))
  );

  useEffect(() => {
    methods.setValue("website.description", scanData?.description ?? "");
    methods.setValue("writer.language", scanData?.language ?? Language.HEBREW);
  }, [scanData?.description, scanData?.language]);

  const [filteredBusinessTypes, setFilteredBusinessTypes] = useState<
    BusinessCategory[]
  >([]);

  const searchBusinessTypes = (event: any) => {
    const query = event.query.toLowerCase();
    const filteredGroups = businessCategories
      .map((category) => {
        const filteredItems = category.types.filter((item) =>
          item.label.toLowerCase().includes(query)
        );
        return { category: category.category, types: filteredItems };
      })
      .filter((category) => category.types.length > 0);

    setFilteredBusinessTypes(filteredGroups);
  };

  return (
    <>
      <FormStyleStyled>
        <div className="grid">
          <CardStyled className="grid w-full m-0 ">
            <BadgeStyled large bgColor="yellow" textColor="title">
              פרטי העסק
            </BadgeStyled>
            <div className="col-12">
              <label htmlFor="username">תיאור העסק</label>
              <Controller
                name="website.description"
                rules={{
                  required: {
                    value: true,
                    message: "תיאור העסק שדה חובה",
                  },
                }}
                render={({ field }) => (
                  <>
                    <InputTextarea
                      value={field.value || ""}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      placeholder="תיאור מפורט על העסק, מה הוא עושה, במה מתמחה"
                    />
                    <RephraseButton
                      id="rephraseButton"
                      onChange={(newText: string) => field.onChange(newText)}
                      content={field.value}
                      prompt={
                        field.value
                          ? "אתה הולך לקבל טקסט המתאר עסק. התפקיד שלך הוא לשכתב בלבד את תיאור העסק הבא בצורה טובה יותר, רחבה ומקיפה יותר"
                          : "בלי לציין שם העסק וקהל יעד, הנחה אותי כיצד לכתוב תיאור מפורט של מה שהעסק שלי עושה. אין צורך בהקדמה, רק תן הדרכה ברורה קצרה מאוד בנקודות ותמציתית."
                      }
                    >
                      <span>
                        {field.value ? "נסח בשבילי מחדש" : "צור בשבילי"}
                      </span>
                    </RephraseButton>
                  </>
                )}
              />
            </div>
            <div className="col-12">
              <label htmlFor="username">תחום עיסוק</label>
              <br />
              <Controller
                name="website.category"
                rules={{ required: "תחום עיסוק שדה חובה" }}
                render={({ field }) => (
                  <AutoComplete
                    className="w-full"
                    id="businessType"
                    value={field.value}
                    field="label"
                    optionGroupLabel="category"
                    optionGroupChildren="types"
                    delay={0}
                    onChange={(e) => field.onChange(e.value)}
                    placeholder="בחר סוג עסק"
                    suggestions={filteredBusinessTypes}
                    completeMethod={searchBusinessTypes}
                  />
                )}
              />
            </div>
          </CardStyled>
        </div>
      </FormStyleStyled>
    </>
  );
};

export default BusinessExtraDetails;
