import { Dialog } from "primereact/dialog";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { audienceState } from "../../../state/audienceState";
import { FormProvider, useController, useForm } from "react-hook-form";
import { CreateWebsiteRequestData } from "../../../core/services/requests/createWebsite/createWebsiteRequestData";
import { InputText } from "primereact/inputtext";
import FormStyle from "../../common/form/FormStyle";
import { ReactComponent as RefreshIcon } from "../../../assets/Icons/refresh.svg";
import RangeSlider from "../../common/form/RangeSlider";
import Avatar, { genConfig } from "react-nice-avatar";
import { Dropdown } from "primereact/dropdown";
import { GenderType } from "../../../core/types/genderType";
import SelectKeywordsInput from "../../keywords/SelectKeywordsInput";
import Button from "./Button";
import writerService from "../../../core/services/writer.service";

const Title = styled.h2`
  color: var(--Main-TitleColor, #0a2540);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 1rem */
  letter-spacing: -0.03rem;
  margin-top: 10px;
`;

const Subtitle = styled.h3`
  margin-top: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 0.875rem */
  letter-spacing: -0.02625rem;
`;

const UploadImage = styled.div`
  margin-top: 10px;
  width: 128px;
  height: 148px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed var(--border-color);
  flex-direction: column;
  gap: 10px;
  transition-duration: 0.05s;
  cursor: pointer;

  svg {
    width: 40px;
    height: 40px;
    fill: var(--border-color);
  }

  span {
    font-weight: bold;
    color: var(--border-color);
  }

  &:hover {
    border: 2px dashed var(--primary-color);
    svg {
      fill: var(--primary-color);
    }

    span {
      color: var(--primary-color);
    }
  }
`;

const AvatarHairThick = styled(Avatar)`
  svg:nth-child(2) {
    path {
    }
    left: 0;
  }

  svg:nth-child(4) {
    path {
    }
    left: 0;
  }
`;

const RefreshImage = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 1.25rem */
  letter-spacing: -0.03125rem;
  color: var(--primary-purple);

  svg {
    transition-duration: 0.2s;
  }
  &:hover {
    svg {
      transform: rotate(90deg);
    }
  }
`;

const AvatarContainer = styled.div`
  margin: 10px 0;
  display: flex;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  align-items: center;
  gap: 20px;
`;

const DialogStyled = styled(Dialog)`
  max-width: 70%;
`;

interface Props {
  onHide: () => void;
}

const CreateAudienceModal: React.FC<Props> = ({ onHide }) => {
  const { websiteId } = useParams();
  const [audiences, setAudiences] = useRecoilState(
    audienceState(websiteId as string)
  );

  const methods = useForm<CreateWebsiteRequestData["audience"]>({
    defaultValues: {
      painPoints: [],
    },
  });

  const handleSliderChange = (value: [number, number]) => {
    methods.setValue("minAge", value[0]);
    methods.setValue("maxAge", value[1]);
  };

  const onSubmit = async (data: CreateWebsiteRequestData["audience"]) => {
    const audience = await writerService.createAudience(
      data,
      websiteId as string
    );
    setAudiences([...audiences, audience]);
    onHide();
  };

  const { field: audienceImageField } = useController({
    control: methods.control,
    name: "imageProps",
  });

  const [refresh, setRefresh] = useState(false);

  const { field } = useController({
    control: methods.control,
    name: "gender",
  });

  const gender = methods.watch("gender");

  useEffect(() => {
    audienceImageField.onChange(
      genConfig({
        sex: gender === GenderType.FEMALE ? "woman" : "man",
      })
    );
  }, [gender, refresh]);

  return (
    <DialogStyled
      header={
        <>
          <Title>צור קהל יעד</Title>
          <Subtitle>צור קהל יעד מותאם לעסק שלך</Subtitle>
        </>
      }
      visible
      onHide={onHide}
      modal
      footer={
        <>
          <Button
            data-pr-position="top"
            primary
            disabled={!methods.formState.isValid}
            onClick={methods.handleSubmit(onSubmit)}
            loading={methods.formState.isLoading}
          >
            צור קהל
          </Button>
        </>
      }
    >
      <FormProvider {...methods}>
        <FormStyle>
          <div className="grid">
            <div className="col-12">
              <AvatarContainer>
                <AvatarHairThick
                  style={{ width: "5rem", height: "5rem" }}
                  {...audienceImageField.value}
                />
                <RefreshImage onClick={() => setRefresh(!refresh)}>
                  <RefreshIcon />
                  <span>רענון תמונה </span>
                </RefreshImage>
              </AvatarContainer>
            </div>
            <div className="col-6">
              <label htmlFor="username">שם הפרסונה</label>
              <InputText
                {...methods.register("name")}
                placeholder="איך הקהל שלכם נקרא?"
              ></InputText>
            </div>
            <div className="col-6">
              <label htmlFor="username">מגדר</label>
              <Dropdown
                onChange={(e) => field.onChange(e.value)}
                value={field.value}
                placeholder="בחרו את מגדר הקהל שלכם"
                options={[
                  { label: "זכר", value: GenderType.MALE },
                  { label: "נקבה", value: GenderType.FEMALE },
                  { label: "הכל", value: GenderType.BOTH },
                ]}
              />
            </div>
            <div className="col-12">
              <label htmlFor="username">טווח גילאים</label>
              <br />
              <div>
                <RangeSlider
                  min={10}
                  max={65}
                  step={5}
                  defaultValue={[25, 45]}
                  onChange={handleSliderChange}
                />
              </div>
            </div>
            <div className="col-12">
              <Title>מה הצורך של קהל היעד?</Title>
              <Subtitle>
                תארו את הצורך של קהל היעד שלכם הקשורים לעסק
                שלכם. הקישו על מקש Enter כדי לשמור את הטקסט.
              </Subtitle>
            </div>
            <div className="col-12">
              <SelectKeywordsInput<
                CreateWebsiteRequestData["audience"]
              > fieldName="painPoints"></SelectKeywordsInput>
            </div>
          </div>
        </FormStyle>
      </FormProvider>
    </DialogStyled>
  );
};

export default CreateAudienceModal;
