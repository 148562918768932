import { Dialog } from "primereact/dialog";
import styled from "styled-components";
import ModalHeader from "./ModalHeader";
import { FormProvider, useForm } from "react-hook-form";
import Button from "../common/form/Button";
import GenericDescriptionInput from "../document/forms/formComponentes/GenericDescriptionInput";
import generatorService from "../../core/services/generator.service";
import { useState } from "react";
import { websiteService } from "../../core/services/website.service";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { attachmentsState } from "../../state/attachmentsState";

const DialogStyled = styled(Dialog)`
  padding: 0;
  .p-dialog-header {
    padding: 0;
  }

  .p-dialog-content {
    padding: 0;
  }
`;

const GenerateMediaModal: React.FC<{ onHide: () => void }> = ({ onHide }) => {
  const methods = useForm();
  const { websiteId } = useParams();
  const [imageUrl, setImageUrl] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const [attachments, setAttachments] = useRecoilState(
    attachmentsState(websiteId as string)
  );

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    const load = toast.loading("יוצר תמונה. התהליך עשוי לקחת עד דקה...");
    try {
      const image = await generatorService.generateImage(data.prompt);
      setImageUrl(image);
    } catch (err) {
    } finally {
      setIsLoading(false);
      toast.dismiss(load);
    }
  };

  const saveImage = async () => {
    const load = toast.loading("שומר תמונה בגלריה");
    if (imageUrl && websiteId) {
      try {
        const newAttachment = await websiteService.saveAttachmentToGallery(
          websiteId,
          imageUrl
        );
        setAttachments([...attachments, newAttachment]);
        toast.success("התמונה נשמרה בהצלחה");
      } catch (err) {
        toast.error("שגיאה בשמירת התמונה");
      } finally {
        toast.dismiss(load);
      }
    }
  };

  return (
    <DialogStyled
      visible
      position={"right"}
      style={{
        height: "100vh",
        margin: "0",
        width: "45vw",
        boxShadow: "none",
        borderLeft: "solid 1px var(--border-color)",
        borderRadius: "0px",
        maxHeight: "100%",
        padding: "0",
      }}
      onHide={onHide}
      header={<ModalHeader OnClose={onHide} right downAbit />}
      modal={true}
      draggable={false}
      closable={false}
    >
      <FormProvider {...methods}>
        <form>
          <GenericDescriptionInput<{ prompt: string }>
            name="prompt"
            label="תיאור התמונה"
            placeholder={`תתארו כאן מה בדיוק אתם רוצים שיהיה בתמונה`}
          />
          <Button
            loading={isLoading}
            primary
            onClick={methods.handleSubmit(onSubmit)}
          >
            צור תמונה
          </Button>

          {imageUrl && <Button onClick={saveImage}>לחץ לשמירה בגלריה</Button>}
        </form>
      </FormProvider>
      {imageUrl && <img src={imageUrl} alt="generated" />}
    </DialogStyled>
  );
};

export default GenerateMediaModal;
