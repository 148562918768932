import { MenuItemProps } from "../../menu/types/MenuItemProps";
import { ReactComponent as DashboardIcon } from "../../../assets/Icons/WebsiteIcons/Dashboard.svg";
import { ReactComponent as ContentIcon } from "../../../assets/Icons/WebsiteIcons/Content.svg";
import { ReactComponent as TrendIcon } from "../../../assets/Icons//Trend.svg";
import { ReactComponent as ArticlesIcon } from "../../../assets/Icons/WebsiteIcons/Blog.svg";
import { ReactComponent as InsightsIcon } from "../../../assets/Icons/WebsiteIcons/Analytics.svg";
import { ReactComponent as KeywordsIcon } from "../../../assets/Icons/WebsiteIcons/Keyword.svg";
import { ReactComponent as WriterSettingsIcon } from "../../../assets/Icons/WebsiteIcons/Writer.svg";
import { ReactComponent as IntegrationIcon } from "../../../assets/Icons/WebsiteIcons/Integration.svg";
import { ReactComponent as ScheduleIcon } from "../../../assets/Icons/WebsiteIcons/Schedule.svg";
import { ReactComponent as TemplatesIcon } from "../../../assets/Icons/WebsiteIcons/Templates.svg";
import { ReactComponent as AutomaticIcon } from "../../../assets/Icons/WebsiteIcons/Automatic.svg";
import { ReactComponent as BusinessIcon } from "../../../assets/Icons/WebsiteIcons/BusinessInfo.svg";
import { ReactComponent as AudienceIcon } from "../../../assets/Icons/WebsiteIcons/Audience.svg";
import { ReactComponent as Leads } from "../../../assets/Icons/WebsiteIcons/Leads.svg";
import { ReactComponent as AddIcon } from "../../../assets/Icons/PlusBorder.svg";
import ConstructionIcon from "@mui/icons-material/Construction";
import HistoryIcon from "@mui/icons-material/HistoryOutlined";
import { UserRole } from "../../../core/types/userRole";
import Templates from "../../../pages/websitePages/Templates";

const WebsiteMenuItems: MenuItemProps[] = [
  // {
  //   displayName: "דאשבורד",
  //   route: "/dashboard",
  //   icon: <DashboardIcon />,
  // },

  {
    displayName: "כלים",
    route: "/dashboard",
    isTitle: true,
    icon: <DashboardIcon />,
  },

  {
    displayName: "ארגונית תוכן",
    route: "/calendar",
    icon: <ScheduleIcon />,
  },
  {
    displayName: "תבניות תוכן",
    route: "",
    icon: <ContentIcon />,
  },

  // {
  //   displayName: "טרנדים",
  //   route: "",
  //   icon: <TrendIcon  />,
  //   pro: true,
  // },

  {
    displayName: "המסמכים שלי",
    route: "/docs",
    icon: <ArticlesIcon />,
  },

  {
    displayName: "מדיה שלי",
    route: "/media",
    icon: <ArticlesIcon />,
  },

  // {
  //   displayName: "ניתוח מידע",
  //   route: "/analytics",
  //   icon: <InsightsIcon />,
  // },

  {
    displayName: " הגדרות כלליות",
    route: "",
    isTitle: true,
    isDivider: true,
  },

  {
    displayName: "לידים",
    route: "/leads",
    icon: <Leads />,
  },

  {
    displayName: "פרטי העסק",
    route: "/business-info",
    icon: <BusinessIcon />,
  },

  {
    displayName: "קהלי יעד",
    route: "/audience-settings",
    icon: <AudienceIcon />,
  },
  {
    displayName: "סגנון כתיבה",
    route: "/tone-settings",
    icon: <AudienceIcon />,
  },

  // {
  //   displayName: "הגדרות בלוג",
  //   route: "/autowriter/writer-settings",
  //   icon: <AutomaticIcon />,
  //   parentRoute: "/autowriter",
  // },

  {
    displayName: "אינטגרציות",
    route: "/integrations",
    icon: <IntegrationIcon />,
  },

  {
    displayName: "סופר אדמין",
    route: "/super-admin",
    icon: <AutomaticIcon />,
    role: UserRole.SUPER_ADMIN,
  },
];

export default WebsiteMenuItems;
