import { Dialog } from "primereact/dialog";
import React from "react";
import styled from "styled-components";
import Button from "../common/form/Button";
import ModalHeader from "./ModalHeader";

interface Props {
  onHide: () => void;
  onSubmit: () => void;
}

const DialogTitle = styled.h1`
  color: var(--main-title-color, #0a2540);
  font-size: 4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 4rem */
  letter-spacing: -0.18rem;
  text-align: center;
`;

const SubTitle = styled.h2`
  margin-top: 20px;
  color: #425466;
  text-align: right;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 1.25rem */
  letter-spacing: -0.0375rem;
  text-align: center;
`;

const Title = styled.h1`
  color: #0a2540;
  font-size: 2.5rem;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 2.5rem */
  letter-spacing: -0.075rem;
`;

const DialogStyled = styled(Dialog)`
  width: 60%;
`;

const Row = styled.div`
  display: flex;
`;

const IntegrationWarningModal: React.FC<Props> = ({ onHide, onSubmit }) => {
  return (
    <DialogStyled
      onHide={onHide}
      visible
      header={<ModalHeader OnClose={onHide} right />}
      draggable={false}
      closable={false}
      footer={
        <Row>
          <Button primary onClick={onHide}>
            התחבר לחשבונות סושיאל
          </Button>
          <Button onClick={() => onSubmit()}>המשך ללא חשבונות סושיאל</Button>
        </Row>
      }
    >
      <Title>
        שמנו לב שלא התחברתם לאף חשבון סושיאל. האם תרצו להמשך בכל זאת?
      </Title>

      <SubTitle>
        חיבור פלטפורמות סושיאל יאפשר למערכת{" "}
        <strong>לעבוד בצורה הטובה ביותר</strong>. <br />
        תוכלו ליצור, לפרסם ולנתח פוסטים בכל הרשתות שלכם. <br />
        <br /> * ניתן להתחבר גם בשלב מאוחר יותר בעמוד האינטגרציות.
      </SubTitle>
    </DialogStyled>
  );
};

export default IntegrationWarningModal;
