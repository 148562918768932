import { useFormContext, useWatch } from "react-hook-form";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import AudienceSelect from "../../common/form/AudienceSelect";
import { DocumentEntity, DocumentEntityType } from "neword-core";
import DocumentTone from "../components/DocumentTone";
import BlogPostOpinionDescription from "./formComponentes/BlogPostOpinionDescription";
import { AccordionProps } from "./types/AccordionProps";
import GenericDescriptionInput from "./formComponentes/GenericDescriptionInput";

export const productUpdateEmailAccordions: AccordionProps[] = [
  {
    title: "תיאור התוכן",
    content: (
      <div className="w-full step-2-wt">
        <GenericDescriptionInput<
          DocumentEntity & { type: DocumentEntityType.PRODUCT_UPDATE_EMAIL }
        >
          name="inputParams.description"
          label="מהם העדכונים העיקריים או התכונות החדשות של המוצר?"
          placeholder="תתארו את ההעדכונים העיקריים או התכונות החדשות של המוצר??"
          hideRephrase
        />
        <GenericDescriptionInput<
          DocumentEntity & { type: DocumentEntityType.PRODUCT_UPDATE_EMAIL }
        >
          name="inputParams.productUpdates"
          label="יתרונות העדכון"
          placeholder="כיצד עדכונים אלו מועילים ללקוחות?"
        />
        <GenericDescriptionInput<
          DocumentEntity & { type: DocumentEntityType.PRODUCT_UPDATE_EMAIL }
        >
          name="inputParams.cta"
          label="מהי הקריאה לפעולה העיקרית במייל שיתוף התוכן?"
          placeholder="לדוגמה ״קרא בהרחבה על העדכון כאן:[לינק]״, ״ מה אתה חושב  על העדכונים?״"
          inputStyle={{ height: 60 }}
        />
      </div>
    ),
    fieldNames: [
      "inputParams.cartItems",
      "inputParams.supportContact",
      "inputParams.incentives",
    ],
  },

  {
    title: "קהל יעד",
    content: (
      <AudienceSelect<
        DocumentEntity & { type: DocumentEntityType.CART_ABANDONMENT_EMAIL }
      > fieldName="inputParams.audienceId" />
    ),
    fieldNames: ["inputParams.audienceId"],
  },
  {
    title: "סגנון כתיבה",
    content: (
      <>
        <DocumentTone<
          DocumentEntity & { type: DocumentEntityType.CART_ABANDONMENT_EMAIL }
        >
          fieldName="inputParams.tone"
          threeColumn={true}
        />
      </>
    ),
    fieldNames: ["inputParams.tone"],
  },
];
