import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { useParams } from "react-router";
import { audienceState } from "../../state/audienceState";
import Avatar from "react-nice-avatar";
import styled from "styled-components";
import PageTitle from "../../components/common/PageTitle";
import Badge from "../../components/common/Badge";
import { ReactComponent as EditIcon } from "../../assets/Icons/Edit.svg";
import { ReactComponent as PlusIcon } from "../../assets/Icons/PlusBorder.svg";
import { Edit } from "@mui/icons-material";
import { GenderType } from "../../core/types/genderType";
import CreateAudienceModal from "../../components/common/form/createAudienceModal";
import UpdateAudienceModal from "../../components/common/form/UpdateAudienceModal";

interface Props {
  // Define your component's props here, if needed
}

// Styled components for layout
const AudienceContainer = styled.div``;

const IconWrapper = styled.div`
  position: absolute;
  bottom: 12px;
  left: 17px;
  opacity: 0;
  transition-duration: 0.1s;
`;

const AudienceCard = styled.div`
background-color: #fff;
  display: flex;
  gap: 20px;
  flex-direction: row;
  align-items: flex-start;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  position: relative;
  transition-duration: 0.1s;
  cursor: pointer;

  &.add-audience {
    border: 1px dashed #ddd;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      width: 20px;
      height: 20px;
    }
  }

  &:hover {
    border-color: var(--title-color);

    ${IconWrapper} {
      opacity: 1;
    }
  }
`;

const AudienceName = styled.span`
  display: flex;
  gap: 10px;
  flex-direction: column;
  margin-top: 10px;
  font-weight: bold;
  span {
    font-weight: 500;
  }
`;

const AvatarWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
`;

const Audience: React.FC<Props> = () => {
  const { websiteId } = useParams(); // Get websiteId from the URL
  const audiences = useRecoilValue(audienceState(websiteId as string)); // Fetch audience data

  const [createAudienceModal, showCreateAudienceModal] = useState(false);
  const [updateAudienceModal, showUpdateAudienceModal] = useState(false);

  // Update the state name to reflect its purpose clearly
  const [updateAudienceID, setUpdateAudienceID] = useState<string>("");

  const getGenderText = (gender: GenderType): string => {
    switch (gender) {
      case GenderType.MALE:
        return "גבר";
      case GenderType.FEMALE:
        return "אישה";
      case GenderType.BOTH:
        return "גברים ונשים";
      default:
        return "לא ידוע";
    }
  };

  const handleAudienceClick = (id: string) => {
    setUpdateAudienceID(id); // Set the audience ID in the state
    showUpdateAudienceModal(true); // Show the update modal
  };

  return (
    <>
      <PageTitle
        title="קהלי היעד שלי"
        subtitle="כאן תוכלו לראות את קהלי היעד שלכם, לערוך אותם ולהוסיף קהלים חדשים"
      />

      <AudienceContainer>
        <div className="grid">
          {audiences.length > 0 ? (
            audiences.map((audience) => (
              <div className="col-4" key={audience.id}>
                <AudienceCard onClick={() => handleAudienceClick(audience.id)}>
                  <AvatarWrapper>
                    <Avatar
                      style={{ width: "2.5rem", height: "2.5rem" }}
                      {...audience.imageProps} // Display audience avatar
                    />
                  </AvatarWrapper>

                  <AudienceName>
                    <div className="flex">
                      <div>
                        שם הקהל: <span>{audience.name}</span>
                      </div>
                      <div className="mr-2 ml-2 ">|</div>
                      <div>
                        מגדר: <span>{getGenderText(audience.gender)}</span>
                      </div>
                    </div>

                    <div className="flex align-items-center mt-3">
                      מילות מפתח:
                      <div className="flex">
                        {audience.painPoints.map((painPoint) => (
                          <div className="mr-2" key={painPoint}>
                            <Badge bgColor="bg" textColor="purple">
                              {painPoint}
                            </Badge>
                          </div>
                        ))}
                      </div>
                    </div>
                  </AudienceName>
                  <IconWrapper>
                    <EditIcon />
                  </IconWrapper>
                </AudienceCard>
              </div>
            ))
          ) : (
            <div>לא קיימים קהלים</div> // Show message if no audiences
          )}
          <div className="col-4">
            <AudienceCard
              className="add-audience"
              onClick={() => showCreateAudienceModal(true)}
            >
              <PlusIcon className="icon" />
              הוסף קהל יעד
            </AudienceCard>
          </div>
        </div>
      </AudienceContainer>

      {createAudienceModal && (
        <CreateAudienceModal onHide={() => showCreateAudienceModal(false)} />
      )}

      {updateAudienceModal && (
        <UpdateAudienceModal
          audienceID={updateAudienceID}
          onHide={() => showUpdateAudienceModal(false)} // Correct this onHide function
        />
      )}
    </>
  );
};

export default Audience;
