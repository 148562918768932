import { PostEntity } from "neword-core";
import { PostCardIcons } from "./postIconMappings";

export const convertPostToEvent = (post: PostEntity) => {
  const eventBase = {
    comments: post.comments,
    date: new Date(post.scheduleDate),
    id: post.id,
    status: post.status,
  };

  switch (post.data.type) {
    case "FACEBOOK_POST":
      return {
        ...eventBase,
        event: post.data.message,
        imageUrls: post.data.imageUrls?.map((item) => ({
          url: item.url,
          metadata: item.metadata,
        })) ?? [],
        platforms: [PostCardIcons[post.type].icon],
      };

    case "INSTAGRAM_POST":
      return {
        ...eventBase,
        event: post.data.message,
        imageUrls: post.data.imageUrls?.map((item) => ({
          url: item.url,
          metadata: item.metadata,
        })) ?? [],
        platforms: [PostCardIcons.INSTAGRAM_POST.icon],
      };

    // Uncomment and update the case below if you need Twitter support
    // case "TWITTER_TWEET":
    //   return {
    //     ...eventBase,
    //     event: "פרסום לטוויטר",
    //     imageUrls: null,
    //     platforms: [PostCardIcons.TWITTER_TWEET.icon],
    //   };

    case "TIKTOK_POST":
      return {
        ...eventBase,
        event: post.data.message,
        imageUrls: post.data.imageUrls?.map((item) => ({
          url: item.url,
          metadata: item.metadata,
        })) ?? [],
        platforms: [PostCardIcons.TIKTOK_POST.icon],
      };

    case "LINKEDIN_POST":
      return {
        ...eventBase,
        event: post.data.message,
        imageUrls: post.data.imageUrls?.map((item) => ({
          url: item.url,
          metadata: item.metadata,
        })) ?? [],
        platforms: [PostCardIcons.LINKEDIN_POST.icon],
      };

    default:
      return {
        ...eventBase,
        event: "פרסום לפייסבוק",
        imageUrls: post.data.imageUrls?.map((item) => ({
          url: item.url,
          metadata: item.metadata,
        })) ?? [],
        platforms: [PostCardIcons[post.type].icon],
      };
  }
};
