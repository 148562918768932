import { Input } from "@mui/base";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton, RadioButtonChangeEvent } from "primereact/radiobutton";
import { useMemo, useState } from "react";
import { UserRole } from "../../core/types/userRole";
import Button from "../common/form/Button";
import FormStyle from "../common/form/FormStyle";
import InputStyle from "../common/form/InputStyle";
import ModalHeader from "./ModalHeader";
import { useController, useForm } from "react-hook-form";
import { InviteUserRequestData } from "../../core/services/requests/signup/inviteUserRequestData";
import RegexValidations from "../../core/validation/regexValidations";
import ErrorMessage from "../common/ErrorMessage";
import userService from "../../core/services/user.service";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import { websitesStateSelector } from "../../state/websitesState";
import { useParams } from "react-router";
import PhoneSelector from "../common/form/phoneSelector/PhoneSelector";
import { InputMask, InputMaskChangeEvent } from "primereact/inputmask";


interface Propse {
  onHide: () => void;
}

const AddTeammateModal: React.FC<Propse> = ({ onHide }) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    getValues,
  } = useForm<InviteUserRequestData>();
  const { field } = useController({
    control,
    name: "role",
    defaultValue: UserRole.EDITOR,
  });

  const [value, setValue] = useState<string | undefined>();

  const [isLoading, setIsLoading] = useState(false);

  const websiteField = useController({
    control,
    name: "allowedWebsiteId",
  });

  const { websiteId } = useParams();

  const websitesSelector = useRecoilValue(websitesStateSelector);

  const websitesOptions = useMemo(() => {
    return websitesSelector.map((website) => ({
      label: website.name,
      value: website.id,
    }));
  }, [websitesSelector]);

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      await userService.inviteUser(getValues());
      onHide();
      toast("המשתמש הוזמן", {
        type: "success",
      });
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      // header="Publish Configurations"
      header={<ModalHeader OnClose={onHide} title={"הזמן אנשים"} right />}
      closable={false}
      modal={false}
      visible
      position={"bottom-right"}
      style={{
        width: "28vw",
        height: "calc(100vh - 85px)",
        margin: "0",
        boxShadow: "none",
        borderLeft: "solid 1px var(--border-color)",
        borderRadius: "0px",
        maxHeight: "100%",
      }}
      onHide={onHide}
      draggable={false}
      resizable={false}
    >
      <FormStyle>
        <div className="grid">
          <div className="col-7">
            <label>אימייל</label>
            <InputText
              {...register("email", {
                maxLength: 40,
                pattern: {
                  value: RegexValidations.email,
                  message: "כתובת אימייל לא חוקית",
                },
              })}
              className={errors.email ? "p-invalid" : ""}
              placeholder="name@company.com"
            />
            {errors.email?.message && (
              <ErrorMessage>{errors.email.message}</ErrorMessage>
            )}
          </div>
          <div className="col-5">
            <label htmlFor="">תפקיד</label>
            <Dropdown
              value={field.value}
              onChange={field.onChange}
              options={[
                {
                  label: "מנהל",
                  value: UserRole.ADMIN,
                },
                {
                  label: "עורך",
                  value: UserRole.EDITOR,
                },
                {
                  label: "לקוח",
                  value: UserRole.CLIENT,
                },
              ]}
            />
          </div>
        </div>

        {getValues("role") === UserRole.ADMIN && (
          <small>
            מנהל יוכל להוסיף משתמשים, ליצור, לערוך ולמחוק תכנים במערכת.
          </small>
        )}

        {getValues("role") === UserRole.EDITOR && (
          <small>עורך יוכל ליצור, לערוך ולמחוק תכנים במערכת.</small>
        )}

        {getValues("role") === UserRole.CLIENT && (
          <div className="col-12">
            <label htmlFor="">מספר טלפון של הלקוח</label>
            <small>
              מספר הטלפון של הלקוח לקבלת התראות בוואטסאפ
            </small>
            <InputMask style={{direction:"ltr"}} value={value} onChange={(e: InputMaskChangeEvent) => setValue("fuck")} mask="05*-999999" placeholder="052-999999"/>

          
            <label htmlFor="">בחר עסק</label>
            <small>
              הלקוח יוכל לצפות, לאשר ולדחות תכנים של העסק הנבחר בלבד.
            </small>
            <Dropdown
              value={websiteField.field.value}
              onChange={websiteField.field.onChange}
              options={websitesOptions}
              defaultValue={websiteId}
            />
          </div>
        )}

        <div className="flex justify-content-between align-items-center mt-3">
          <div>{/* <Button icon={}>Add another user</Button> */}</div>
          <div className="flex justify-content-end">
            <Button
              loading={isLoading}
              onClick={handleSubmit(onSubmit)}
              primary
            >
              הזמן
            </Button>
          </div>
        </div>
      </FormStyle>
    </Dialog>
  );
};

export default AddTeammateModal;
