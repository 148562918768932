import { AttachmentMedia } from "neword-core";

export default (value: AttachmentMedia[]) => {
  const videos = value.filter((item) => item.metadata.type === "VIDEO");
  const images = value.filter((item) => item.metadata.type === "IMAGE");
  let errorText = "";

  // Basic validation for video and images
  if (videos.length > 1) {
    errorText = "מותר לבחור וידאו אחד בלבד";
  }
  if (images.length > 0) {
    errorText = "מותר לבחור רק וידיאו";
  }

  // Validation for Instagram Reels metadata
  if (videos.length === 1) {
    const video = videos[0].metadata;

    // Narrow type to the video-specific type
    if (video.type === "VIDEO") {
      // if (video.aspectRatio !== "9:16") {
      //   errorText = "הווידאו חייב להיות ביחס רוחב-גובה של 9:16";
      // } else {
      //   const [width, height] = video.resolution
      //     .split("x")
      //     .map((dim) => parseInt(dim.trim(), 10));
      //   if (
      //     isNaN(width) ||
      //     isNaN(height) ||
      //     width < 540 ||
      //     height < 960 ||
      //     width > 1080 ||
      //     height > 1920
      //   ) {
      //     errorText =
      //       "הרזולוציה של הווידאו חייבת להיות לפחות 540x960 פיקסלים (מומלץ 1080x1920)";
      //   }
      // }
      // if (video.frameRate < 24 || video.frameRate > 60) {
      //   errorText = "קצב הפריימים חייב להיות בין 24 ל-60 פריימים לשנייה";
      // } else if (video.duration < 3 || video.duration > 90) {
      //   errorText = "אורך הווידאו חייב להיות בין 3 ל-90 שניות";
      // }
    }
  }

  return errorText;
};
