import { useRecoilValue } from "recoil";
import { CreateWebsiteRequestData } from "../../../core/services/requests/createWebsite/createWebsiteRequestData";
import FormStepType from "../../common/form/types/formStepType";
import BusinessDetails from "./steps/BusinessDetails";
import BusinessExtraDetails from "./steps/BusinessExtraDetails";
import ChooseSetup from "./steps/ChooseSetup";
import CreateAudience from "./steps/CreateAudience";
import Integrations from "./steps/Integrations";
import { userState } from "../../../state/userState";

export const useCreateWebsiteSteps = () => {
  const user = useRecoilValue(userState);
  console.log(user.company.type);
  const createWebsiteSteps: FormStepType<CreateWebsiteRequestData>[] = [
    {
      component: <BusinessDetails />,
      fields: ["website.name", "website.url"],
      title: "בואו נוסיף את הלקוח הראשון שלכם",
      subtitle: "מלאו את הפרטים להוספת הלקוח הראשון שלכם.",
      nextButtonText: "הוסף פרטים נוספים על העסק",
    },
    {
      component: <BusinessExtraDetails />,
      fields: ["website.description", "website.category"],
      title: "פרטים נוספים על העסק",
      subtitle: "מלאו את הפרטים להוספת הלקוח הראשון שלכם.",
      nextButtonText: "הוסף פרטים נוספים על העסק",
    },
    {
      title: "אז למי העסק פונה?",
      subtitle: "בואו ניצור את קהל היעד הראשון שלכם",
      component: <CreateAudience />,
      fields: ["audience.name", "audience.gender"],
      nextButtonText: "צור עסק",
    },
    {
      component: <Integrations />,
      fields: ["website.description"],
      title: "חברו את הפלטפורמות שלכם",
      subtitle: "חברו את חשבונות הסושיאל שלכם כדי לנהל הכל במקום אחד",
      nextButtonText: "צור לקוח/עסק",
    },
  ];

  if (!user.company.type) {
    createWebsiteSteps.unshift({
      component: <ChooseSetup />,
      fields: ["website.description"],
      nextButtonText: "בואו נוסיף את הלקוח הראשון",
    });
  }

  return createWebsiteSteps;
};
